import {
  Component,
  OnInit,
} from '@angular/core';
import {
  MobileDetectService,
  RouterService,
  UserModel,
  UserService,
  utilsFactory,
} from '@lib/gc-common';
import { environment } from '@libs/gc-common/environments/environment';

@Component({
  selector: 'mip-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss'],
})
export class WelcomePageComponent implements OnInit {
  referralUserModel: UserModel = null;
  loggedInUser: UserModel = null;

  isIphone = false;
  isAndroid = false;
  isMobile = false;
  isDesktop = false;
  loginBackgroundImage = null;

  assetsPath = environment.assetsPath;
  networkUrl = environment.networkUrl;

  constructor(
    private userService: UserService,
    private routerService: RouterService,
    private mobileDetect: MobileDetectService,
  ) {
    this.isIphone = this.mobileDetect.isIphone();
    this.isAndroid = this.mobileDetect.isAndroid();
    this.isMobile = this.mobileDetect.isMobile();
    this.isDesktop = !(this.isIphone || this.isAndroid || this.isMobile);
  }

  async ngOnInit() {
    try {
      const loginBackgroundImage = await this.routerService.getRouteDataParam('loginBackgroundImage');

      if (loginBackgroundImage) {
        this.loginBackgroundImage = loginBackgroundImage.replace('{{assetsPath}}', this.assetsPath);
      }

      this.loggedInUser = await this.userService.getLoginUser(); // this.loginService.storageRegisterData;
      // console.log('welcome-page.component->ngOnInit(): this.loggedInUser', this.loggedInUser);

      if (!this.loggedInUser) {
        await this.routerService.navigateTo('/');
        return;
      }

      this.referralUserModel = this.loggedInUser.referralUser;
      // console.log('welcome-page.component->ngOnInit(): this.referralUserModel', this.referralUserModel);

      this.userService.removeReferralUser();
    } catch (e) {
      if (e instanceof Error) {
        utilsFactory.noticeError(e);
      }
    }
  }
}

import {
  Component,
  OnInit,
} from '@angular/core';
import { environment } from '@libs/gc-common/environments/environment';

@Component({
  selector: 'mip-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss'],
})
export class CopyrightComponent implements OnInit {
  networkUrl = environment.networkUrl;

  constructor() {}

  ngOnInit(): void {}
}

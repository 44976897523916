import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mip-profile-footer-actions',
  templateUrl: './mip-profile-footer-actions.component.html',
  styleUrls: ['./mip-profile-footer-actions.component.scss']
})
export class MipProfileFooterActionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RouterLinkDirective } from './router-link.directive';

@NgModule({
  declarations: [RouterLinkDirective],
  imports: [
    CommonModule
  ],
  exports: [RouterLinkDirective]
})
export class RouterLinkModule {
}

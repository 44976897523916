import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  Input
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mip-copy',
  templateUrl: './copy.component.html',
  styleUrls: ['./copy.component.scss']
})
export class CopyComponent {

  @Input() value = null;
  @Input() size: '1x' | '2x' | '3x' | '4x' | '5x' | '6x' | '7x' | '8x' | '9x' | '10x' = '3x';

  constructor(
    private matSnackBar: MatSnackBar,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) {

  }

  copyValue(value) {

    const copyValue = this.document.createElement('input');

    copyValue.setAttribute('value', value);
    this.document.body.appendChild(copyValue);

    copyValue.select();
    this.document.execCommand('copy');
    this.document.body.removeChild(copyValue);

    this.translate.get('share-modal.coped-successfully', {value}).subscribe((response) => {
      this.matSnackBar.open(`${value} - ${response}`.toUpperCase(), null, {
        duration: 1500,
        panelClass: 'mip-color--text-success'
      });
    });

  }

}

import {
  Component,
  OnInit,
} from '@angular/core';
import { LoginService } from '@apps/gc-login/login.service';
import {
  AppleService,
  GoogleService,
  RouterService,
  UserService,
  utilsFactory,
} from '@lib/gc-common';
import { environment } from '@libs/gc-common/environments/environment';

@Component({
  selector: 'mip-login-social',
  templateUrl: './login-social.component.html',
  styleUrls: ['./login-social.component.scss'],
})
export class LoginSocialComponent implements OnInit {
  // Google
  hideGoogleBtn = null;
  loadingGoogleLogin = false;
  errorGoogleLogin = null;

  // Apple
  hideAppleBtn = null;
  loadingAppleLogin = false;
  errorAppleLogin = null;

  errorReservedWord = false;
  goBackReferrer = null;
  deviceUUID = null;

  constructor(
    private routerService: RouterService,
    private loginService: LoginService,
    private googleService: GoogleService,
    private appleService: AppleService,
    private userService: UserService,
  ) {}

  async ngOnInit() {
    try {
      const { data, queryParams } = await this.routerService.getRouteObject();
      // console.log('login-social.component->ngOnInit(): {data, queryParams}', {data, queryParams});

      this.hideGoogleBtn = queryParams.hideGoogleBtn === 'true';
      this.hideAppleBtn = queryParams.hideAppleBtn === 'true';

      this.goBackReferrer = this.routerService.getGoBackReferer(environment.loginUrl);
      // console.log('login-social.component->ngOnInit(): this.goBackReferrer', this.goBackReferrer);

      this.deviceUUID = this.userService.getDeviceUUID();
      // console.log('login-social.component->ngOnInit(): this.deviceUUID', this.deviceUUID);

      if (utilsFactory.isBrowser && queryParams.scope && queryParams.scope.indexOf('googleapis') > -1) {
        this.loadingGoogleLogin = true;
        this.errorGoogleLogin = false;
        this.errorReservedWord = false;

        await this.routerService.navigateTo('/', {
          queryParams: {
            code: null,
            scope: null,
            authuser: null,
            prompt: null,
          },
        });

        const { access_token } = await this.googleService.exchangeCodeToAccessToken(
          queryParams.code,
          environment.loginUrl,
        );
        // console.log('login-social.component->ngOnInit(): access_token', access_token);

        if (access_token) {
          await this.login({
            graphDomain: 'google',
            credentials: {
              accessToken: access_token,
            },
          });
        }
      }
    } catch (e) {
      console.error('login-social.component->ngOnInit(): ERROR', e);

      this.loadingGoogleLogin = false;

      if (e && e['code'] && e['code'] === 'RESERVED_WORD') {
        this.errorReservedWord = true;
      } else {
        this.errorGoogleLogin = true;
      }

      /*setTimeout(() => {
			 this.errorGoogleLogin = false;
			 }, 20000);*/
    }
  }

  /**
   * Method to login by social
   */
  async login(payload) {
    try {
      // console.log('login-social.component->login(): payload', payload);

      const userModel = await this.loginService.login(payload);
      // console.log('login-social.component->login(): userModel', userModel);

      if (this.goBackReferrer) {
        // console.log('login-social.component->login(): this.goBackReferrer', this.goBackReferrer);
        await this.routerService.navigateTo(this.goBackReferrer.path);
      } else {
        // console.log('login-social.component->login(): environment.networkUrl', environment.networkUrl);
        document.location = environment.networkUrl;
      }
    } catch (e) {
      console.error('login-social.component->login(): ERROR', e);
      throw e;
    }
  }

  /**
   * Method to login with Google
   */
  async loginWithGoogle() {
    try {
      // console.log('login-social.component->loginWithGoogle()');

      this.loadingGoogleLogin = true;
      this.errorGoogleLogin = false;

      if (utilsFactory.isBrowser) {
        if (window['gcApp_onLoginSocial']) {
          window['gcApp_onLoginSocial'].postMessage(
            JSON.stringify({
              graphDomain: 'google',
            }),
          );
          return;
        } else if (
          /**
           * if within webview but gcApp_onLoginSocial is not defined,
           * it means that the APP is not up to date, so we force fo to login because
           * the APP will catch this route change and close the webview
           */
          (await utilsFactory.isWebView()) ||
          this.deviceUUID
        ) {
          await this.routerService.navigateTo('/', {
            queryParams: {
              deviceUUID: null,
              hideAppleBtn: null,
            },
          });
          return;
        }
      }

      // function to redirect the user to google authorization page
      await this.googleService.authWithGoogle(environment.loginUrl);
    } catch (e) {
      this.loadingGoogleLogin = false;
      this.errorGoogleLogin = true;

      console.error('login-social.component->loginWithGoogle(): ERROR', e);
    }
  }

  /**
   * Method to login with Apple
   */
  async loginWithApple() {
    try {
      // console.log('login-social.component->loginWithApple()');

      this.loadingAppleLogin = true;
      this.errorAppleLogin = false;

      if (utilsFactory.isBrowser) {
        if (window['gcApp_onLoginSocial']) {
          window['gcApp_onLoginSocial'].postMessage(
            JSON.stringify({
              graphDomain: 'apple',
            }),
          );
          return;
        } else if (
          /**
           * if within webview but gcApp_onLoginSocial is not defined,
           * it means that the APP is not up to date, so we force to login because
           * the APP will catch this route change and close the webview
           */
          (await utilsFactory.isWebView()) ||
          this.deviceUUID
        ) {
          await this.routerService.navigateTo('/', {
            queryParams: {
              deviceUUID: null,
              hideAppleBtn: null,
            },
          });
          return;
        }
      }

      const credentials = await this.appleService.login(environment.loginUrl);
      // console.log('login-social.component->loginWithApple(): credentials', credentials);

      if (credentials) {
        await this.login({
          graphDomain: 'apple',
          credentials,
        });
      }

      this.loadingAppleLogin = false;
    } catch (e) {
      this.loadingAppleLogin = false;
      this.errorAppleLogin = true;

      console.error('login-social.component->loginWithApple(): ERROR', e);

      setTimeout(() => {
        this.errorAppleLogin = false;
      }, 20000);
    }
  }
}

import {
  Pipe,
  PipeTransform
} from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(list: object[], filters: object): any[] {

    console.log('filter.pipe->transform(): ', list, filters);

    let allowAll = true;

    // tslint:disable-next-line:forin
    for (const i in filters) {
      console.log('filter.pipe->transform() i: ', i, filters[i]);

      if (filters[i] !== '' && filters[i] !== null && filters[i] !== undefined) {
        allowAll = false;
        break;
      }
    }

    if (allowAll) {
      return list;
    }
    console.log('filter.pipe->transform() ------------------------------- ');

    return list.filter(item => {

      let allowed = false;

      // tslint:disable-next-line:forin
      for (const i in filters) {
        console.log('filter.pipe->transform() i: ', i, item[i], filters[i]);

        if (
          (
            typeof item[i] === 'boolean' ||
            typeof item[i] === 'number'
          ) &&
          item[i].toLowerCase() === filters[i].toLowerCase()
        ) {
          allowed = true;
          break;
        }
        else if (
          typeof item[i] === 'string' &&
          item[i].toLowerCase().indexOf(filters[i].toLowerCase()) > -1
        ) {
          allowed = true;
          break;
        }
      }

      return allowed;

    });
  }

}

<!-- section LOGIN WITH GOOGLE -->
<button
  mat-stroked-button
  class="__login-button --google --icon-aside mip-button--size-md --first-child"
  (click)="loginWithGoogle()"
  [disabled]="loadingGoogleLogin"
  *ngIf="hideGoogleBtn === false"
  mipBgColor="google"
  mipBdColor="google"
>
  <mip-loading *ngIf="loadingGoogleLogin" size="5x"></mip-loading>
  <mip-icon *ngIf="!loadingGoogleLogin" svgIcon="google"></mip-icon>
  <span mipTranslate="login-form.actions.login-with-google">Login with Google</span>
</button>

<!-- GOOGLE ERROR -->
<mip-alert class="__alert-message" theme="danger" *ngIf="errorGoogleLogin">
  <mip-icon icon="warning" [solid]="true"></mip-icon>
  <span mipTranslate="login.error-message.login-with-google"> Something went wrong while logging in with Google </span>
</mip-alert>

<!-- RESERVED_WORD ERROR -->
<mip-alert class="__alert-message" theme="danger" *ngIf="errorReservedWord">
  <mip-icon icon="warning" [solid]="true"></mip-icon>
  <span mipTranslate="login.error-message.reserved-word"> "gotchosen" is a reserved word, therefore cannot be used! </span>
</mip-alert>

<!-- section LOGIN WITH APPLE -->
<button
  mat-stroked-button
  class="__login-button --apple --icon-aside mip-button--size-md"
  (click)="loginWithApple()"
  [disabled]="loadingAppleLogin"
  *ngIf="hideAppleBtn === false"
  mipBgColor="black"
>
  <mip-loading *ngIf="loadingAppleLogin" size="5x"></mip-loading>
  <mip-icon *ngIf="!loadingAppleLogin" icon="apple" mipTextColor="black"></mip-icon>
  <span mipTranslate="login-form.actions.login-with-apple">Login with Apple</span>
</button>

<!-- APPLE ERROR -->
<mip-alert class="__alert-message" theme="danger" *ngIf="errorAppleLogin">
  <mip-icon icon="warning" [solid]="true"></mip-icon>
  <span mipTranslate="login.error-message.login-with-apple"> Something went wrong while logging in with Apple </span>
</mip-alert>

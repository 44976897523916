import { BaseModel } from '@libs/gc-common/lib/api/models/base.model';

import { MediaSongInterface } from './media-song.interface';

export class MediaSongModel extends BaseModel implements MediaSongInterface {

  id = null; // api
  artist = null; // api
  cover = null; // api
  name = null; // api

  constructor(model?: MediaSongInterface) {
    super(model);
    this.fill(model);
  }

}

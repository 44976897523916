import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';
import { I18nModule } from '@libs/gc-common/lib/services/i18n/i18n.module';
import { TranslateModule } from '@ngx-translate/core';

import { TreeMenuComponent } from './tree-menu.component';

@NgModule({
  declarations: [TreeMenuComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MipIconModule,
    RouterLink,
    I18nModule,
    TranslateModule
  ],
  exports: [TreeMenuComponent],
})
export class TreeMenuModule {
}

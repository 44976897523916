import {
  Component,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { LoginService } from '@apps/gc-login/login.service';
import {
  FormHelper,
  RouterService,
  utilsFactory,
} from '@lib/gc-common';
import { environment } from '@libs/gc-common/environments/environment';

@Component({
  selector: 'mip-code-verifier-form',
  templateUrl: './code-verifier-form.component.html',
  styleUrls: ['./code-verifier-form.component.scss'],
})
export class CodeVerifierFormComponent implements OnInit {
  codeVerifierForm = new FormHelper({
    email: new FormControl('', []),
  });

  assetsPath = environment.assetsPath;

  goBackReferrer = null;
  loginBackgroundImage = null;
  isThereUrlCode = null;
  isUrlCodeValid = null;
  codeLoading = false;
  codeSuccess = false;
  codeError = false;
  codeErrorCode = null;
  unprocessableEntityError = '';
  newCodeLoading = false;
  requestNewCode = false;
  alreadyValidated = false;
  hasEmail = false;
  urlCode = null;

  postPayload = {
    code: null,
    email: null,
  };

  constructor(private loginService: LoginService, private routerService: RouterService) {}

  async ngOnInit() {
    if (utilsFactory.isBrowser) {
      try {
        const routerObject = await this.routerService.getRouteObject();
        // console.log('code-verifier-form.component->ngOnInit(): routerObject', routerObject);

        if (routerObject.data.loginBackgroundImage) {
          this.loginBackgroundImage = routerObject.data.loginBackgroundImage.replace('{{assetsPath}}', this.assetsPath);
        }

        if (routerObject.queryParams.code) {
          this.isThereUrlCode = true;
          this.urlCode = routerObject.queryParams.code;

          try {
            const parsedCode = JSON.parse(utilsFactory.atob(routerObject.queryParams.code));
            // console.log('code-verifier-form.component->ngOnInit(): parsedCode', parsedCode);

            // check email and if is valid
            if (!parsedCode.email) {
              throw new Error(`'email' must be provided within the 'code' parameter`);
            } else if (!utilsFactory.isEmailValid(parsedCode.email)) {
              throw new Error(`'email' provided within the 'code' parameter is not valid`);
            }

            if (!parsedCode.code) {
              throw new Error(`Validation 'email' must be provided within the 'code' parameter`);
            }

            this.postPayload = parsedCode;
            // console.log('code-verifier-form.component->ngOnInit(): this.postPayload', this.postPayload);

            this.isUrlCodeValid = true;
            this.codeLoading = true;

            this.hasEmail = true;
            this.codeVerifierForm.controls['email'].setValue(this.postPayload.email);

            await this.codeVerify();
          } catch (e) {
            console.error('code-verifier-form.component->ngOnInit(): ERROR', e);

            if (e instanceof Error) {
              utilsFactory.noticeError(e);
            }

            this.isUrlCodeValid = false;
          }
        } else {
          this.isThereUrlCode = false;
          this.postPayload.email = routerObject.queryParams.email;

          this.goBackReferrer = this.routerService.getGoBackReferer(environment.loginUrl);
          // console.log('code-verifier-form.component->ngOnInit(): this.goBackReferrer', this.goBackReferrer);

          const storageRegisterUserData = this.loginService.storageRegisterUserData;
          // console.log('code-verifier-form.component->ngOnInit(): storageRegisterUserData', storageRegisterUserData);

          const storageRegisterData = this.loginService.storageRegisterData;
          // console.log('code-verifier-form.component->ngOnInit(): storageRegisterData', storageRegisterData);

          if (storageRegisterUserData && storageRegisterUserData.email) {
            this.postPayload.email = storageRegisterUserData.email;
            // console.log('code-verifier-form.component->ngOnInit(): 1 this.postPayload.email', this.postPayload.email);
          } else if (storageRegisterData && storageRegisterData.email) {
            this.postPayload.email = storageRegisterData.email;
            // console.log('code-verifier-form.component->ngOnInit(): 2 this.postPayload.email', this.postPayload.email);
          } else if (
            storageRegisterData &&
            storageRegisterData.username &&
            storageRegisterData.username.indexOf('@') > -1
          ) {
            this.postPayload.email = storageRegisterData.username;
            // console.log('code-verifier-form.component->ngOnInit(): 3 this.postPayload.email', this.postPayload.email);
          }

          if (this.postPayload.email) {
            this.hasEmail = true;
            this.codeVerifierForm.controls['email'].setValue(this.postPayload.email);
          }

          if (routerObject.queryParams.resend) {
            await this.getNewCode();
          }
        }
      } catch (e) {
        console.error('code-verifier-form.component->ngOnInit(): ERROR', e);

        if (e instanceof Error) {
          utilsFactory.noticeError(e);
        }
      }
    }
  }

  async codeVerify() {
    // console.log('code-verifier-form.component->onSubmit(): this.codeVerifierForm', this.codeVerifierForm);

    try {
      this.codeError = false;
      this.codeLoading = true;
      this.codeSuccess = false;
      this.codeVerifierForm.disable();
      this.codeErrorCode = null;
      this.unprocessableEntityError = null;

      const loggedInUser = await this.loginService.codeVerify(this.postPayload);
      // console.log('code-verifier-form.component->codeVerify(): loggedInUser', loggedInUser);

      this.codeLoading = false;
      this.codeSuccess = true;

      await utilsFactory.delayTime(3000);

      await this.loginService.login({
        token: loggedInUser.apiToken,
      });

      if (this.goBackReferrer) {
        // console.log('code-verifier-form.component->onSubmit(): redirecting to', this.goBackReferrer.path);
        await this.routerService.navigateTo(this.goBackReferrer.path);
      } else {
        // console.log('code-verifier-form.component->onSubmit(): redirecting to', `/welcome`);
        await this.routerService.navigateTo(`/welcome`, {
          queryParams: { code: null },
        });
      }
    } catch (e) {
      console.error('code-verifier-form.component->onSubmit(): ERROR', e);
      // console.log('code-verifier-form.component->onSubmit(): ERROR->code', e['code']);

      this.codeVerifierForm.enable();
      this.codeLoading = true;

      if (e['message']) {
        utilsFactory.noticeError(e);

        if (
          e['message'] === 'code.required' ||
          e['message'] === 'code.invalid' ||
          e['message'] === 'email.required' ||
          e['message'] === 'email.invalid'
        ) {
          this.isThereUrlCode = true;
          this.isUrlCodeValid = false;
        } else {
          this.codeError = true;

          if (e['code']) {
            this.codeErrorCode = e['code'];
          } else if (e['message'] === 'Unprocessable Entity') {
            this.unprocessableEntityError = e['message'];
          }
        }
      }
    }
  }

  async getNewCode() {
    try {
      // console.log('code-verifier-form.component->getNewCode()');

      const payload = this.codeVerifierForm.value;
      // console.log('code-verifier-form.component->getNewCode(): payload', payload);

      if (!payload.email) {
        throw new Error(`The email must be provided`);
      }

      this.newCodeLoading = true;
      // console.log('code-verifier-form.component->getNewCode(): this.newCodeLoading', this.newCodeLoading);

      await this.loginService.getNewValidationToken(payload.email);

      this.newCodeLoading = false;
      this.requestNewCode = true;

      setTimeout(() => {
        this.requestNewCode = false;
      }, 60000);
    } catch (e) {
      this.newCodeLoading = false;
      this.requestNewCode = false;

      if (e['message'] === 'User already validated') {
        // this.codeVerifierForm.validate('email.already-verified');
        this.codeVerifierForm.disable();
        this.alreadyValidated = true;
      }

      if (e['message'] === 'User not found') {
        this.codeVerifierForm.validate('email.not-found');
      }

      console.error('code-verifier-form.component->getNewCode(): ERROR', e);

      throw e;
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mip-breadcrumb',
  templateUrl: './mip-breadcrumb.component.html',
  styleUrls: ['./mip-breadcrumb.component.scss']
})
export class MipBreadcrumbComponent implements OnInit {

  @Input() linkColor = 'primary';

  constructor() {
  }

  ngOnInit(): void {
  }

}

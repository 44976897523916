<button
  mat-button
  class="expansive-button"
  mipBgColor="black"
  [style.min-width]="width + 'px !important'"
  [style.min-height]="width + 'px'"
  [style.height]="width + 'px'"
  (click)="onClick.emit()"
>
  <span class="expansive-button__icon" [style.padding-right]="(width / 5) + 'px'" [style.padding-left]="(width / 5) + 'px'">
    <ng-content select="mip-icon"></ng-content>
  </span>
  <span class="expansive-button__label" [style.padding-right]="(width / 5) + 'px'">
    <ng-content select="span"></ng-content>
  </span>
</button>

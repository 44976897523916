import {
  Component,
  Input,
  OnChanges,
  OnInit
} from '@angular/core';

@Component({
  selector: 'mip-progress-bar',
  templateUrl: './mip-progress-bar.component.html',
  styleUrls: ['./mip-progress-bar.component.scss']
})
export class MipProgressBarComponent implements OnInit, OnChanges {

  @Input() max: 100;
  @Input() value: 0;

  currentValue = 0;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (this.max) {
      this.currentValue = Math.ceil((this.value * 100) / this.max);
      console.log('progress-bar.component->ngOnChanges() this.currentValue', this.currentValue);
    }
  }

}

<div class="__posts-container">

  <!-- TOP 10 POSTS CAROUSEL -->
  <swiper
    class="__swiper-container"
    [config]="config"
    [class.--is-desktop]="isDesktop"
    [class.--is-mobile]="isMobile"
    *ngIf="!isLoading"
  >
    <ng-template
      class="__slide-container"
      *ngFor="let post of hashtagPosts; let $index = index;"
      swiperSlide
    >

      <a
        routerLink="/hashtag/{{hashtagName}}"
        class="__first-flag"
        *ngIf="$index === 0 && hashtagName"
      >
        <span>1° #<span>{{hashtagName}}</span></span>
        <div class="__flag-border"></div>
      </a>

      <!-- POST MAIN COMPONENT -->
      <mip-post-view
        class="__post-view-container"
        [postModel]="post"
        [isIntoViewport]="true"
        [postInteraction]="false"
        postBasePath="/hashtag/{{hashtagName}}"
        forceTheme="static"
      ></mip-post-view>

    </ng-template>

    <ng-template class="__slide-container __see-more" swiperSlide *ngIf="hashtagPosts.length && showSeeMore">
      <a
        mat-button
        class="__post-view-container"
        mipTranslate="slide-posts-preview-details.action.see-more"
        routerLink="/hashtag/{{hashtagName}}"
      >
        See more
      </a>
    </ng-template>

    <ng-template class="__slide-container __create-post" swiperSlide *ngIf="hashtagPosts.length && showCreatePost">

      <div
        class="__post-view-container"
        [style.background-image]="'url(' + createPostSlideBgImage + ')'"
      >
        <div class="__slide-header">
          <h3 class="--text-align--center">{{createPostSlideTitle | translate}}</h3>
          <p class="--text-align--center">{{createPostSlideDescription | translate}}</p>
        </div>

        <button
          mat-button
          (click)="openCreatePost()"
          class="__bottom-action mip-color--bg-primary mip-button--size-md --adjust-text"
        >
          {{createPostSlideActionLabel | translate}}
        </button>
      </div>

    </ng-template>

  </swiper>

  <div class="__swiper-navigation" [class.--hide]="isLoading">
    <button
      mat-icon-button
      class="__slide-posts-preview __swiper-button-prev"
      mipBgColor="primary"
      [class.--opacity]="index === 0"
      [disabled]="index === 0"
      *ngIf="!isMobile"
    >
      <mip-icon icon="chevron-left" [bold]="true"></mip-icon>
    </button>
    <button
      mat-icon-button
      class="__slide-posts-preview __swiper-button-next"
      mipBgColor="primary"
      *ngIf="!isMobile"
    >
      <mip-icon icon="chevron-right" [bold]="true"></mip-icon>
    </button>
  </div>

  <div *ngIf="isLoading" class="__loading --flex-center">
    <mip-loading></mip-loading>
  </div>

</div>

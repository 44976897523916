<div [class.--dark-theme]="darkTheme" class="login-form">
  <!-- <meta name="google-signin-client_id" content="579174145361-b4sjsoandrltnhlgmd1nd0398s7rvg0j.apps.googleusercontent.com"> -->
  <!-- <script src="https://apis.google.com/js/platform.js"></script> -->
  <div *ngIf="loginBackgroundImage" [style.background-image]="'url(' + loginBackgroundImage + ')'" class="__login-background-image"></div>

  <header *ngIf="disableHeader !== true" class="header">
    <div class="__inner">
      <div class="--flex-column --flex-column--center">
        <strong class="__text" mipTranslate="login-form.header.title">Login</strong>
        <img class="__logo" src="{{ assetsPath }}/images/gc-logo-white.png" />
      </div>
    </div>
  </header>

  <div [class.--has-bg-image]="loginBackgroundImage" class="content">
    <div class="__inner">
      <!-- LOGIN-CONTAINER -->
      <div class="__form-container">
        <!-- LOGIN FORM -->
        <form (ngSubmit)="onSubmit()" [class.mip-form--theme-light]="darkTheme" [formGroup]="loginForm" class="__form mip-form">
          <!-- USERNAME/EMAIL -->
          <mat-form-field appearance="fill" color="accent">
            <mat-label mipTranslate="login-form.fields.username.label">Username/Email</mat-label>
            <span class="__prefix-icon" matPrefix><mip-icon icon="mail" size="3x"></mip-icon></span>
            <input
              (blur)="onEmailChange()"
              (change)="onEmailChange()"
              (paste)="onEmailChange()"
              [required]="true"
              autocomplete="username"
              formControlName="username"
              matInput
              type="text"
            />

            <!-- FIELD ERRORS -->
            <mat-error>
              <span *ngIf="loginForm.get('username').hasError('required')" mipTranslate="login-form.fields.username.error-message.required">
                You must provide your email or your username
              </span>
              <span *ngIf="loginForm.get('username').hasError('invalid')" mipTranslate="login-form.fields.username.error-message.invalid"> The email provided is not valid </span>
              <span *ngIf="loginForm.get('username').hasError('account-not-found')" mipTranslate="login-form.fields.username.error-message.account-not-found">
                Account provided not found
              </span>
              <span *ngIf="loginForm.get('username').hasError('account-suspended')" mipTranslate="login-form.fields.username.error-message.account-suspended">
                Account provided has been suspended
              </span>
              <span *ngIf="loginForm.get('username').hasError('account-banned')" mipTranslate="login-form.fields.username.error-message.account-banned">
                Account provided has been banned
              </span>
              <span *ngIf="loginForm.get('username').hasError('account-deleted')" mipTranslate="login-form.fields.username.error-message.account-deleted">
                Account provided has been deleted
              </span>
            </mat-error>
          </mat-form-field>

          <!-- PASSWORD -->
          <mat-form-field appearance="fill" color="accent">
            <mat-label mipTranslate="login-form.fields.password.label"> Password </mat-label>
            <span class="__prefix-icon" matPrefix><mip-icon icon="lock" size="3x"></mip-icon></span>
            <input [required]="true" [type]="showPassword ? 'text' : 'password'" autocomplete="current-password" formControlName="password" matInput />
            <a (click)="togglePasswordVisibility()" [class.mip-color--text-primary]="showPassword" mat-button matSuffix>
              <mip-icon [solid]="true" icon="eye" size="1x"></mip-icon>
            </a>
            <a
              [mipGoBackReferrer]="true"
              [queryParams]="{ email: validEmail }"
              routerLink="/forgot-password"
              queryParamsHandling="merge"
              mat-button
              matSuffix
              mipTranslate="login-form.fields.password.add-on.label"
            >
              Forgot?
            </a>

            <!-- FIELD ERRORS -->
            <mat-error>
              <span *ngIf="loginForm.get('password').hasError('required')" mipTranslate="login-form.fields.password.error-message.required"> You must provide your password. </span>
            </mat-error>
          </mat-form-field>

          <!-- REMEMBER ME -->
          <div class="__checkbox">
            <mat-checkbox color="accent" formControlName="rememberMe">
              <span mipTranslate="login-form.fields.remember-me.label"> Remember me </span>
            </mat-checkbox>
          </div>

          <!-- SUBMIT -->
          <button [disabled]="loadingLogin" [mipBgColor]="loginBackgroundImage ? 'white' : 'primary'" class="__submit-button mip-button--size-md" mat-raised-button type="submit">
            <mip-icon *ngIf="loadingLogin" [spin]="true" icon="refresh"></mip-icon>
            <span *ngIf="loadingLogin" mipTranslate="login-form.message.logging-in">Logging in...</span>
            <span *ngIf="!loadingLogin" mipTranslate="login-form.actions.login">Login</span>
          </button>

          <!-- FORM ERRORS -->
          <mip-alert *ngIf="errorLogin" class="__alert-message" theme="danger">
            <mip-icon [solid]="true" icon="warning"></mip-icon>
            <span mipTranslate="login-form.error-message.login-error"> There was an error while trying to login into the application. </span>
          </mip-alert>

          <mip-alert *ngIf="errorAuthNotFoundUser" class="__alert-message" theme="warning">
            <mip-icon icon="warning"></mip-icon>
            <span mipTranslate="login-form.error-message.login-account-not-found-error"> The account you entered does not exist! </span>
          </mip-alert>

          <mip-alert *ngIf="errorInvalidCredentials" class="__alert-message" theme="warning">
            <mip-icon icon="warning"></mip-icon>
            <span mipTranslate="login-form.error-message.login-credentials-error"> The username/email or the password provided is not valid. </span>
          </mip-alert>

          <mip-alert *ngIf="errorSuspendedUser" class="__alert-message" theme="danger">
            <mip-icon [solid]="true" icon="warning"></mip-icon>
            <span mipTranslate="login-form.error-message.account-suspended">
              Your account has been temporarily suspended due to violation of ours <strong>Terms:</strong>
              <a href="{{ networkUrl }}/monetization-terms" target="_blank">Monetization Terms</a>, <a href="{{ networkUrl }}/privacy" target="_blank">Privacy Policy</a> or
              <a href="{{ networkUrl }}/terms-of-service" target="_blank">Terms of Service</a>
            </span>
          </mip-alert>

          <mip-alert *ngIf="errorBannedUser" class="__alert-message" theme="danger">
            <mip-icon [solid]="true" icon="warning"></mip-icon>
            <span mipTranslate="login-form.error-message.account-banned">
              Your account has been banned due to violation of ours <strong>Terms:</strong> <a href="{{ networkUrl }}/monetization-terms" target="_blank">Monetization Terms</a>,
              <a href="{{ networkUrl }}/privacy" target="_blank">Privacy Policy</a> or <a href="{{ networkUrl }}/terms-of-service" target="_blank">Terms of Service</a>
            </span>
          </mip-alert>

          <mip-alert *ngIf="errorDeletedUser" class="__alert-message" theme="warning">
            <mip-icon [solid]="true" icon="warning"></mip-icon>
            <span mipTranslate="login-form.error-message.account-deleted"> Your account has been deleted </span>
          </mip-alert>
        </form>

        <mip-divider *ngIf="!darkTheme">
          <span mipTranslate="divider.label-or">OR</span>
        </mip-divider>

        <mip-divider *ngIf="darkTheme" labelColor="rgba(255,255,255, 0.9)" lineColor="rgba(255,255,255, 0.2)" textColor="#000">
          <span mipTranslate="divider.label-or">OR</span>
        </mip-divider>

        <!-- LOGIN SOCIAL -->
        <mip-login-social *ngIf="enableSocialLogin"></mip-login-social>
      </div>

      <!-- CREATE-ACCOUNT-ACTION -->
      <div *ngIf="disableLoginBtn !== true" class="__bottom-actions">
        <h3 mipTranslate="login-form.actions.label.create-account">Don't have an account yet?</h3>

        <a
          routerLink="/register"
          queryParamsHandling="merge"
          class="__action mip-button--size-md"
          mat-button
          mipTextColor="primary"
          mipTranslate="login-form.actions.create-account"
        >
          Create your account here!
        </a>
      </div>
    </div>

    <!-- COPYRIGHT -->
    <mip-copyright *ngIf="disableCopyright !== true"></mip-copyright>
  </div>

  <router-outlet></router-outlet>
</div>

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mip-alert-footer',
  templateUrl: './mip-alert-footer.component.html',
  styleUrls: ['./mip-alert-footer.component.scss']
})
export class MipAlertFooterComponent implements OnInit {

  @Input() bgColor: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}

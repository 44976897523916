<div
  class="__section-yt-video"
>

  <ng-content select="iframe"></ng-content>

  <img
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAABCAQAAABeK7cBAAAAC0lEQVR42mNkAAIAAAoAAv/lxKUAAAAASUVORK5CYII="
    width="100%"
  >
</div>


import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  FormHelper,
  MipDialogComponent,
  MobileDetectService,
  RouterService,
  UserDeviceUUIDModel,
  UserService,
  utilsFactory,
} from '@lib/gc-common';
import { environment } from '@libs/gc-common/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mip-confirm-device-deletion',
  templateUrl: './confirm-device-deletion.component.html',
  styleUrls: ['./confirm-device-deletion.component.scss'],
})
export class ConfirmDeviceDeletionComponent implements OnInit, AfterViewInit, OnDestroy {
  isDesktop = false;
  isMobile = false;
  isLoadingUsers = true;
  removingLogin = false;
  errorInvalidCredentials = false;
  uuid = null;
  user: UserDeviceUUIDModel = null; // THIS IS NOT AN "UserModel" object!!!;

  loginForm = new FormHelper({
    username: new FormControl('', []),
    password: new FormControl('', []),
  });

  showPassword = false;
  recaptchaHasLoaded = false;
  onRouteChangeSubscription = null;
  recaptchaBadScoreError = false;
  recaptchaErrorMessage = null;

  constructor(
    private routerService: RouterService,
    private mobileDetect: MobileDetectService,
    private userService: UserService,
    private translateService: TranslateService,
    public dialog: MatDialog,
  ) {
    this.isDesktop = !this.mobileDetect.isMobile();
    this.isMobile = this.mobileDetect.isMobile();

    this.loginForm.disable();
  }

  async ngOnInit() {
    this.isLoadingUsers = true;

    this.onRouteChangeSubscription = this.routerService.onRouteChange().subscribe(async ({ params, data }) => {
      // console.log('confirm-device-deletion.component->ngOnInit(): params', params);

      try {
        this.isLoadingUsers = true;

        this.uuid = params.uuid;
        // console.log('confirm-device-deletion.component->ngOnInit(): this.uuid', this.uuid);

        this.user = await this.userService.getUserByDeviceAndUserId(this.uuid, params.userId);
        // console.log('confirm-device-deletion.component->ngOnInit(): this.user', this.user);

        if (!this.user) {
          document.location = `${environment.networkUrl}/404?e=${utilsFactory.btoa(
            'This user does not exist or is not bond to this device',
          )}`;
        }

        this.loginForm.controls['username'].setValue(this.user.username);

        this.isLoadingUsers = false;
      } catch (e) {
        console.error('confirm-device-deletion.component->ngOnInit(): ERROR', e);
        this.isLoadingUsers = false;
        throw e;
      }
    });
  }

  async ngAfterViewInit() {
    if (utilsFactory.isBrowser) {
      this.loginForm.disable();

      const currentLang = this.translateService.currentLang;
      const lang = currentLang === 'pt' ? 'pt-BR' : currentLang;
      const googleRecaptchaV3Key = environment.googleRecaptchaV3Key;

      await utilsFactory.waitToBeTrue('confirm-device-deletion.component', () => !!this.user);
      // console.log('confirm-device-deletion.component->ngAfterViewInit(): waitToBeTrue->this.user', this.user);

      if (this.user.pendingDelete) {
        return true;
      }

      await utilsFactory.loadScript(
        `https://www.google.com/recaptcha/api.js?render=${googleRecaptchaV3Key}&lang=${lang}`,
        () => {
          return window['grecaptcha'] && window['grecaptcha'].ready;
        },
      );

      this.loginForm.enable();
      this.recaptchaHasLoaded = true;
    }
  }

  ngOnDestroy() {
    if (this.onRouteChangeSubscription) {
      this.onRouteChangeSubscription.unsubscribe();
    }
  }

  async onSubmit() {
    try {
      if (!this.loginForm.valid) {
        return false;
      }

      const payload = this.loginForm.value;
      // console.log('confirm-device-deletion.component->onSubmit(): payload', payload);

      this.loginForm.disable();
      this.errorInvalidCredentials = false;

      await new Promise((resolve, reject) => {
        this.translateService
          .get(
            [
              'login.confirm-device-deletion.modal.title',
              'login.confirm-device-deletion.modal.body',
              'login.confirm-device-deletion.modal.action.remove-device',
              'login.confirm-device-deletion.modal.action.processing',
            ],
            {
              username: this.user.username,
            },
          )
          .subscribe((translate) => {
            // console.log('confirm-device-deletion.component->onSubmit(): translate', translate);

            const title = translate['login.confirm-device-deletion.modal.title'];
            const body = translate['login.confirm-device-deletion.modal.body'];

            const deleteLabel = translate['login.confirm-device-deletion.modal.action.remove-device'];
            const deletingLabel = translate['login.confirm-device-deletion.modal.action.processing'];

            const dialog = this.dialog.open(MipDialogComponent, {
              data: {
                title,
                body,
                actions: [
                  {
                    label: deleteLabel,
                    bgColor: 'danger',
                    click: async (action) => {
                      try {
                        action.loading = true;
                        action.label = deletingLabel;

                        this.removingLogin = true;

                        payload.recaptcha = await window['grecaptcha'].execute(environment.googleRecaptchaV3Key, {
                          action: 'device_deletion',
                        });
                        // console.log('confirm-device-deletion.component->onSubmit(): payload.recaptcha', payload.recaptcha);

                        const response = await this.userService.deleteUserDevice(this.uuid, payload);
                        // console.log('confirm-device-deletion.component->onSubmit(): response', response);

                        this.removingLogin = false;
                        this.loginForm.enable();

                        // this.user.devicePendingDelete = true;
                        // console.log('confirm-device-deletion.component->onSubmit(): this.user', this.user);

                        resolve(this.user);
                      } catch (e) {
                        console.error('confirm-device-deletion.component->onSubmit(): MipDialogComponent ERROR', e);
                        reject(e);
                      }

                      dialog.close();
                    },
                  },
                ],
              },
            });

            dialog.beforeClosed().subscribe(() => {
              this.loginForm.enable();
            });
          });
      });
    } catch (error) {
      console.error('confirm-device-deletion.component->onSubmit(): ERROR', error);
      // console.log('confirm-device-deletion.component->onSubmit(): this.loginForm', this.loginForm);

      this.removingLogin = false;
      this.loginForm.enable();

      if (error['message'].indexOf('bad-score') > -1) {
        this.recaptchaBadScoreError = true;
      } else if (
        environment.environmentName !== 'production' &&
        (error['message'].indexOf('missing-input-secret') > -1 ||
          error['message'].indexOf('invalid-input-secret') > -1 ||
          error['message'].indexOf('missing-input-response') > -1 ||
          error['message'].indexOf('invalid-input-response') > -1 ||
          error['message'].indexOf('bad-request') > -1 ||
          error['message'].indexOf('timeout-or-duplicate') > -1)
      ) {
        this.recaptchaErrorMessage = error['message'];
      } else if (error['form'] && error['form'].errors) {
        this.loginForm.validate(error['form'].errors.children);
      } else {
        if (error['code'] === 'AUTH_INVALID_CREDENTIALS') {
          // console.log('confirm-device-deletion.component->onSubmit(): AUTH_INVALID_CREDENTIALS');

          this.errorInvalidCredentials = true;
          this.loginForm.validate('password.credentials-not-valid');
        } else {
          // console.log('confirm-device-deletion.component->onSubmit(): TRY');
          this.loginForm.validate(error['message']);
        }
      }
    }
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  closeModal() {
    const currentPathName = `/device-users/${this.uuid}`;
    // console.log('confirm-device-deletion.component->closeModal(): currentPathName', currentPathName);

    const goBackReferrer = this.routerService.getGoBackReferer(currentPathName);
    // console.log('confirm-device-deletion.component->closeModal(): goBackReferrer', goBackReferrer);

    if (goBackReferrer) {
      this.routerService.goBackReferer(currentPathName);
    } else {
      this.routerService.navigateTo(environment.loginUrl);
    }
  }
}

import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'mip-dropdown',
  templateUrl: './mip-dropdown.component.html',
  styleUrls: ['./mip-dropdown.component.scss']
})
export class MipDropdownComponent implements OnInit, AfterViewInit {

  @ViewChild('dropdownContainer') dropdownContainerEl: ElementRef;
  @ViewChild('dropdown') dropdownEl: ElementRef;

  @Input() position = 'bottom';
  @Input() align = 'right';
  @Input() fullWidth = false;
  @Input() fixed = true;
  @Input() initialOpen = false;

  dropdownContainer = null;
  dropdown = null;

  isOpen = false;
  hasAnimation = false;

  dropdownStyles = {top: null, bottom: null};

  constructor() {
  }

  ngOnInit(): void {
    if (this.initialOpen) {
      this.toggleOpen();
    }
  }

  ngAfterViewInit(): void {
    this.dropdownContainer = this.dropdownContainerEl.nativeElement;
    this.dropdown = this.dropdownEl.nativeElement;

    /*setTimeout(() => {
     if (this.initialOpen) {
     this.toggleOpen();
     }
     else {
     this.closeModal();
     }
     }, 1000);*/
  }

  /*ngDoCheck() {

   if (this.dropdownContainer && this.dropdown) {
   // const containerRect = this.dropdownContainer.getBoundingClientRect();
   console.log('dropdown.component->ngDoCheck(): containerRect', containerRect);
   console.log('dropdown.component->ngDoCheck(): dropdownRect', dropdownRect);
   console.log('dropdown.component->ngDoCheck(): -------------------');
   }

   /!*if (this.dropdownContainer) {

   const boundingClientRect = this.dropdownContainer.getBoundingClientRect();
   const dropdownContainerHeight = this.dropdownContainer.offsetHeight;
   const top = parseInt(boundingClientRect.top, 10);

   if (this.position === 'bottom') {
   if (this.fixed) {
   if (this.isOpen) {
   console.log('dropdown.component->ngDoCheck() IS OPEN');
   this.dropdown.style.marginBottom = `0px`;
   }
   else {
   console.log('dropdown.component->ngDoCheck() NOT OPEN');
   const dropdownHeight = this.dropdown.offsetHeight;
   this.dropdown.style.marginBottom = `-${dropdownHeight}px`;
   }
   }
   else if (this.fullWidth) {
   if (this.isOpen) {
   this.dropdownStyle.top = `${top + dropdownContainerHeight}px`;
   }
   else {
   this.dropdownStyle.top = `${top}px`;
   }
   }
   }
   console.log('dropdown.component->ngDoCheck() IS OPEN', this.dropdown.style.marginBottom);
   }*!/
   }*/

  toggleOpen() {

    // if is open, close it!!!
    if (this.isOpen) {

      this.isOpen = false;

      setTimeout(() => {
        this.hasAnimation = false;
      }, 200);
    }

    // if is close, open it!!!
    else {

      this.hasAnimation = true;

      setTimeout(() => {
        this.isOpen = true;
      }, 10);
    }

  }

  /*openModal() {

   if (this.isAnimating) {
   return;
   }

   if (this.fixed) {
   if (this.position === 'bottom') {

   }
   }
   }

   closeModal() {
   console.log('dropdown.component->closeModal()', this.dropdown.offsetHeight);

   if (this.isAnimating) {
   return;
   }

   if (this.fixed === true) {

   if (!this.dropdownStyles) {
   this.dropdownStyles = {bottom: null, top: null};
   }

   const dropdownRect = this.dropdown.getBoundingClientRect();

   if (this.position === 'bottom') {
   this.dropdownStyles.bottom = `-${dropdownRect.height}px`;
   }

   console.log('dropdown.component->closeModal(): dropdownRect', dropdownRect);
   console.log('dropdown.component->closeModal(): -------------------');
   }

   }

   fixedOnBottom() {

   if (this.isAnimating) {
   return;
   }

   const dropdownRect = this.dropdown.getBoundingClientRect();

   if (this.isOpen) {
   this.dropdownStyles.bottom = `0px`;
   }
   else {
   this.dropdownStyles.bottom = `-${dropdownRect.right}px`;
   }

   console.log('dropdown.component->fixedOnBottom(): dropdownRect', this.dropdownStyles.bottom, dropdownRect);
   console.log('dropdown.component->fixedOnBottom(): -------------------');
   }*/
}

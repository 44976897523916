import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mip-breadcrumb-item,[mip-breadcrumb-item]',
  templateUrl: './mip-breadcrumb-item.component.html',
  styleUrls: ['./mip-breadcrumb-item.component.scss']
})
export class MipBreadcrumbItemComponent implements OnInit {

  @Input() separator = '/';

  constructor() {
  }

  ngOnInit(): void {
  }

}

import {
  Component,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
} from '@angular/forms';
import { LoginService } from '@apps/gc-login/login.service';
import {
  RouterService,
  utilsFactory,
} from '@lib/gc-common';
import { environment } from '@libs/gc-common/environments/environment';

@Component({
  selector: 'mip-email-form',
  templateUrl: './email-form.component.html',
  styleUrls: ['./email-form.component.scss'],
})
export class EmailFormComponent implements OnInit {
  registerForm = new FormGroup({
    email: new FormControl('', []),
  });

  loadingRegistration = false;
  errorRegistration = false;
  loginBackgroundImage = null;

  assetsPath = environment.assetsPath;

  constructor(private loginService: LoginService, private routerService: RouterService) {}

  async ngOnInit() {
    // console.log('email-form.component->ngOnInit(): this.loginService.storageRegisterData', this.loginService.storageRegisterData);

    try {
      if (!this.loginService.storageRegisterData) {
        const loginBackgroundImage = await this.routerService.getRouteDataParam('loginBackgroundImage');

        if (loginBackgroundImage) {
          this.loginBackgroundImage = loginBackgroundImage.replace('{{assetsPath}}', this.assetsPath);
        }

        const backPathReferrer = this.routerService.goPreviouslyRoute();
        // console.log('email-form.component->ngOnInit(): backPathReferrer', backPathReferrer);

        this.routerService.navigateTo(backPathReferrer.path);
      }
    } catch (e) {
      if (e instanceof Error) {
        utilsFactory.noticeError(e);
      }
    }
  }

  async onSubmit() {
    try {
      // console.log('email-form.component->onSubmit(): this.registerForm.valid', this.registerForm.valid);

      if (!this.registerForm.valid) {
        return false;
      }

      if (!utilsFactory.isEmailValid(this.registerForm.controls.email.value)) {
        this.registerForm.controls.email.setErrors({ invalid: true });
      }

      const payload = {
        ...this.loginService.storageRegisterData,
        ...this.registerForm.value,
      };
      // console.log('email-form.component->onSubmit(): payload', payload);

      this.registerForm.disable();
      this.loadingRegistration = true;
      this.errorRegistration = false;

      await this.loginService.registerUser(payload);

      this.loadingRegistration = false;
    } catch (e) {
      console.error('email-form.component->onSubmit(): ERROR', e);

      this.registerForm.enable();
      this.loadingRegistration = false;

      if (e['message'] === 'email-invalid') {
        this.registerForm.controls.email.setErrors({ invalid: true });
      } else {
        if (e['message']) {
          utilsFactory.noticeError(e);
        }

        this.errorRegistration = true;
      }
    }
  }
}

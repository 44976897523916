import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';
import { ColorModule } from '@libs/gc-common/lib/directives/color/color.module';
import { MipTranslateModule } from '@libs/gc-common/lib/directives/translate/mip-translate.module';

import { MipExpansiveButtonComponent } from './mip-expansive-button.component';

@NgModule({
  declarations: [
    MipExpansiveButtonComponent
  ],
  imports: [
    CommonModule,
    ColorModule,
    MatButtonModule,
    MipIconModule,
    MipTranslateModule
  ],
  exports: [
    MipExpansiveButtonComponent
  ]
})
export class MipExpansiveButtonModule {
}

<div
  #dropdownContainer
  class="dropdown-container"
  (click)="toggleOpen()"
  [class]="['--align-' + align, '--position-' + position]"
  [class.--full-width]="fullWidth"
  [class.--fixed]="fixed"
  [class.--has-animation]="hasAnimation"
  [class.--is-open]="isOpen"
>

  <div>
    <ng-content select="[mip-dropdown-action]"></ng-content>
  </div>

  <div class="__dropdown">
    <div
      #dropdown
      class="__dropdown-inner"
    >
      <ng-content select="[mip-dropdown-drop]"></ng-content>
    </div>
  </div>

</div>

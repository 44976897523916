import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'mip-label-switcher',
  templateUrl: './mip-label-switcher.component.html',
  styleUrls: ['./mip-label-switcher.component.scss']
})
export class MipLabelSwitcherComponent implements OnInit, AfterViewInit {

  @ViewChild('containerEl') containerEl: ElementRef;
  @ViewChild('inkEl') inkEl: ElementRef;

  @Input() labels: Array<string>;
  @Input() activeLabel: string;

  @Output() onchange = new EventEmitter();

  containerElement = null;
  inkElement = null;
  labelsElements = null;

  constructor() {
  }

  ngOnInit() {
    console.log('label-switcher.component->ngOnInit(): this.activeLabel', this.activeLabel, this.labels);

    if (!this.activeLabel) {
      this.activeLabel = this.labels[0].toLowerCase();
    }

    console.log('label-switcher.component->ngOnInit(): this.activeLabel', this.activeLabel, this.labels);

  }

  ngAfterViewInit() {

    this.containerElement = this.containerEl.nativeElement;
    console.log('label-switcher.component->ngAfterViewInit(): this.containerElement', this.containerElement);

    this.inkElement = this.inkEl.nativeElement;
    console.log('label-switcher.component->ngAfterViewInit(): this.inkElement', this.inkElement);

    this.labelsElements = this.containerElement.querySelectorAll('.__label');
    console.log('label-switcher.component->ngAfterViewInit(): this.labelsElements', this.labelsElements);

    let indexLabel = 0;

    for (const i in this.labels) {
      if (this.labels[i].toLowerCase() === this.activeLabel.toLowerCase()) {
        indexLabel = parseInt(i, 10);
        break;
      }
    }

    console.log('label-switcher.component->ngAfterViewInit(): indexLabel', indexLabel);

    const activeLabelEl = this.labelsElements[indexLabel];
    console.log('label-switcher.component->ngAfterViewInit(): activeLabelEl', activeLabelEl);

    this.onActive(this.activeLabel, {
      _elementRef: {
        nativeElement: activeLabelEl
      }
    }, false);
  }

  onActive(label, {_elementRef: nativeElement}, callCallback = true) {

    console.log('label-switcher.component->onActive(): nativeElement', nativeElement);

    this.activeLabel = label.toLowerCase();
    console.log('label-switcher.component->onActive(): this.activeLabel', this.activeLabel);

    setTimeout(() => {
      nativeElement = nativeElement.nativeElement;
      console.log('label-switcher.component->onActive(): nativeElement', nativeElement);

      let isBeforeCurrentEl = true;
      let marginLeft = 0;

      this.labelsElements.forEach(item => {

        if (item === nativeElement) {
          isBeforeCurrentEl = false;
        }

        if (isBeforeCurrentEl) {
          console.log('label-switcher.component->onActive(): item', item.offsetWidth, item);
          marginLeft += item.offsetWidth;
        }

      });

      marginLeft += (nativeElement.offsetWidth / 2);
      console.log('label-switcher.component->onActive(): marginLeft', marginLeft);

      const {left, width} = nativeElement.getBoundingClientRect();
      console.log('label-switcher.component->onActive(): {left, width}', {left, width});

      this.inkElement.style.left = marginLeft + 'px';

      if (callCallback) {
        this.onchange.emit(label);
      }

    }, 100);
  }


}

<div
  class="tree-menu"
  [class.tree-menu--hide-labels]="hideLabels"
>
  <ng-container
    *ngTemplateOutlet="menuItem; context: {menuTree: menuTree, root: true}"
  ></ng-container>
</div>

<ng-template #menuItem let-menuTree="menuTree" let-root="root" let-child="child" let-firstChild="firstChild">

  <!-- ITEM -->
  <div
    class="tree-menu__item"
    [class.tree-menu__item--root]="root"
    [class.tree-menu__item--first-child]="firstChild"
    [class.tree-menu__item--child]="child"
    [class.tree-menu__item--force-expanded]="forceExpandAll"
    [class.tree-menu__item--is-active]="menu.isActive"
    [class.tree-menu__item--has-children]="menu.children && menu.children.length"
    [class.tree-menu__item--is-active-parent]="menu.isActiveParent"
    *ngFor="let menu of menuTree"
  >


    <!-- ACTION -->
    <a
      class="tree-menu__action --align-left"
      [href]="menu.href"
      target="_blank"
      mat-button
      *ngIf="menu.hide !== true && menu.href"
    >

      <ng-container
        *ngTemplateOutlet="menuItemLabel; context: {menu: menu}"
      ></ng-container>

    </a>

    <!-- ACTION -->
    <a
      class="tree-menu__action --align-left"
      [routerLink]="menu.routerLink || (menu.children ? menu.children?.[0].routerLink : '')"
      mat-button
      *ngIf="menu.hide !== true && !menu.href"
    >

      <ng-container
        *ngTemplateOutlet="menuItemLabel; context: {menu: menu}"
      ></ng-container>

    </a>

    <!-- CHILDREN -->
    <div
      class="tree-menu tree-menu__children"
      *ngIf="menu.children && menu.children.length && menu.listChildren !== false"
    >
      <ng-container *ngTemplateOutlet="menuItem; context: {menuTree: menu.children, child: true, firstChild: root}"></ng-container>
    </div>

  </div>

</ng-template>

<ng-template #menuItemLabel let-menu="menu">

  <!-- ICON -->
  <mip-icon class="tree-menu__icon" *ngIf="menu.pngIcon" [size]="menu.iconSize" [pngIcon]="menu.pngIcon"></mip-icon>
  <mip-icon class="tree-menu__icon" *ngIf="menu.mipIcon" [size]="menu.iconSize" [icon]="menu.mipIcon"></mip-icon>
  <mip-icon class="tree-menu__icon" *ngIf="menu.matIcon" [size]="menu.iconSize" [matIcon]="menu.matIcon" [matIconOutlined]="menu.outlinedIcon"></mip-icon>

  <!-- LABEL -->
  <span class="tree-menu__label">{{ menu.label | translate }}</span>

  <!-- SUFFIX-ICON -->
  <mip-icon class="tree-menu__suffix-icon" *ngIf="menu.suffixPngIcon" [size]="menu.suffixIconSize || '3x'" [pngIcon]="menu.suffixPngIcon"></mip-icon>
  <mip-icon class="tree-menu__suffix-icon" *ngIf="menu.suffixMipIcon" [size]="menu.suffixIconSize || '3x'" [icon]="menu.suffixMipIcon"></mip-icon>
  <mip-icon class="tree-menu__suffix-icon" *ngIf="menu.suffixMatIcon" [size]="menu.suffixIconSize || '3x'" [matIcon]="menu.suffixMatIcon" [matIconOutlined]="menu.suffixOutlinedIcon === true || menu.suffixOutlinedIcon === false ? menu.suffixOutlinedIcon : menu.outlinedIcon"></mip-icon>

  <mip-icon class="tree-menu__expand-icon" *ngIf="!forceExpandAll && menu.children && menu.children.length && menu.listChildren !== false" [matIcon]="menu.isActive ? 'keyboard_arrow_down' : 'keyboard_arrow_left'"></mip-icon>

</ng-template>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';
import { MipLoadingModule } from '@libs/gc-common/lib/components/mip-loading/mip-loading.module';
import { ColorModule } from '@libs/gc-common/lib/directives/color/color.module';
import { MipTranslateModule } from '@libs/gc-common/lib/directives/translate/mip-translate.module';

import { MipDialogComponent } from './mip-dialog.component';

@NgModule({
  declarations: [MipDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MipTranslateModule,
    MipLoadingModule,
    ColorModule,
    MipIconModule
  ],
  exports: [MipDialogComponent]
})
export class MipDialogModule {
}

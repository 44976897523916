<div
  class="event-bunner"
  mipBgColor="primary"
  *ngIf="isPtLanguage"
  [style.background-image]="'url(' + assetsPath + '/images/event/experience/bg-small.jpg)'"
>

  <a
    class="__link-logo"
    routerLink="/experience"
  >
    <img class="__logo" src="{{assetsPath}}/images/event/experience/logo-event.svg">
  </a>

  <!-- section HAS-SIGNED-IN *ngIf="!loggedInUser || (loggedInUser && !loggedInUser.hasSignedInEvent(eventRuleName))" -->
  <a routerLink="/experience" class="__info">

    <div class="__text">
      <span>Conheça o <strong>GotChosen Experience</strong>, o evento de lançamento da <strong>GotChosen</strong> no Brasil</span>
    </div>

    <!--<a
      class="__btn"
      mat-button
      mipBgColor="white"
    >
      <span style="white-space: nowrap">Saiba mais</span>
    </a>-->

  </a>

  <!--&lt;!&ndash; section NOT-SIGNED-IN &ndash;&gt;
  <div class="__info" *ngIf="loggedInUser && loggedInUser.hasSignedInEvent(eventRuleName)">

    &lt;!&ndash;<a routerLink="/experience" class="__text" mipTextColor="white">
      <strong style="margin-right: 5px">
        <span *ngIf="loggedInUser.getName()">{{loggedInUser.getName()}}</span>
        <mip-profile-slug-name *ngIf="!loggedInUser.getName()" [user]="loggedInUser"></mip-profile-slug-name>
      </strong>
      <span>obrigado por participar do <strong>{{eventName}}</strong></span>
    </a>&ndash;&gt;

    <button
      mat-button
      mipBgColor="white"
      (click)="shareEvent()"
    >
      <span>Convide seus amigos para a GotChosen</span>
      <mip-icon icon="share"></mip-icon>
    </button>

  </div>-->

</div>

import {
  Component,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { environment } from '@libs/gc-common/environments/environment';
import { UserModel } from '@libs/gc-common/lib/api/models/user';
import { UserService } from '@libs/gc-common/lib/api/services/user/user.service';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { DownloadAppModalComponent } from '@libs/gc-common/lib/features/download-app/download-app-modal.component';
import { SideMenuService } from '@libs/gc-common/lib/features/side-menu/side-menu.service';
import { ObserverHelper } from '@libs/gc-common/lib/helpers/observer.helper';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';
import { REQUEST } from '@nguniversal/express-engine/tokens';

@Component({
  selector: 'mip-main-menu-bar',
  templateUrl: './main-menu-bar.component.html',
  styleUrls: ['./main-menu-bar.component.scss']
})
export class MainMenuBarComponent implements OnChanges, OnInit, OnDestroy {

  @Input() settings: { theme?} = null;

  theme = 'light';
  loggedInUser: UserModel = null;
  currentUrl = null;

  loginUrl = environment.loginUrl;

  observerHelper = new ObserverHelper();

  constructor(
    private userService: UserService,
    private bottomSheet: MatBottomSheet,
    private sideMenuService: SideMenuService,
    private routerService: RouterService,
    @Optional() @Inject(REQUEST) private request: any
  ) {
    if (utilsFactory.isBrowser) {
      this.currentUrl = window.location.pathname;
    }
    else if (this.request) {
      this.currentUrl = this.request.originalUrl;
      console.log('main-menu-bar.component->constructor(): this.currentUrl', this.currentUrl);
    }
  }

  async ngOnInit() {
    try {
      this.observerHelper.addSubscription([
        this.userService.onUserAuthentication().subscribe(user => this.loggedInUser = user),
        this.routerService.onRouteChange().subscribe(route => this.currentUrl = route.url)
      ]);
    }
    catch (e) {
      throw e;
    }
  }

  ngOnChanges(): void {

    console.log('main-menu-bar.component->ngOnChanges(): this.settings', this.settings);

    // MENU-BAR theme
    if (this.settings && this.settings.theme) {
      this.theme = this.settings.theme;
    }
    else {
      this.theme = 'light';
    }

  }

  ngOnDestroy() {
    this.observerHelper.unsubscribeAll();
  }

  isRouteEnable(route): boolean {
    console.log('main-menu-bar.component->isRouteEnable(): this.currentUrl', this.currentUrl);
    if (this.currentUrl) {
      return this.currentUrl.indexOf(`/${route}`) === 0;
    }
  }

  openDownloadAppDialog() {
    // if (this.loggedInUser) {
    this.bottomSheet.open(DownloadAppModalComponent);
    // }
    // else {
    //   this.routerService.navigateTo('/login');
    // }
  }

  openSideMenu() {
    this.sideMenuService.open();
  }

}

import { DecimalPipe } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { environment } from '@libs/gc-common/environments/environment';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';

@Directive({
  selector: '[mipInputThousanfy]'
})
export class InputThousanfyDirective implements OnInit, OnChanges, AfterViewInit {

  @Input() formControl: FormControl = null;
  @Input() min: number | string = null;
  @Input() max: number | string = null;
  @Input() step: number | string = null;
  @Input() showButtons = true;

  @Output() onChange = new EventEmitter();

  _min: number = 0;
  _max: number = 0;
  _step: number = 0;

  prevValue = 0;
  mapInputEl = null;
  hasJustChanged = false;

  onChangeTimeout = null;

  constructor(
    private decimalPipe: DecimalPipe,
    private matInput: MatInput
  ) {
    console.log('input-thousanfy.directive->ngAfterViewInit(): this.inputEl', this.mapInputEl);

  }

  ngOnInit() {

    console.log('input-thousanfy.directive->ngOnInit(): this.formControl', this.formControl);

    console.log('input-thousanfy.directive->ngOnInit(): this.min', this.min);
    console.log('input-thousanfy.directive->ngOnInit(): this.max', this.max);
    console.log('input-thousanfy.directive->ngOnInit(): this.step', this.step);

  }

  ngOnChanges(changes: SimpleChanges) {
    if ('min' in changes && this.min) {
      if (typeof this.min === 'string') {
        this._min = parseInt(this.min.replace(/\,|\./g, ''));
      }
      else {
        this._min = this.min;
      }
    }
    if ('max' in changes && this.max) {
      if (typeof this.max === 'string') {
        this._max = parseInt(this.max.replace(/\,|\./g, ''));
      }
      else {
        this._max = this.max;
      }
    }
    if ('step' in changes && this.step) {
      if (typeof this.step === 'string') {
        this._step = parseInt(this.step.replace(/\,|\./g, ''));
      }
      else {
        this._step = this.step;
      }
    }
  }

  ngAfterViewInit() {

    this.mapInputEl = this.matInput['_elementRef'].nativeElement;
    console.log('input-thousanfy.directive->ngAfterViewInit(): this.inputEl', this.mapInputEl);

    const parentNode = this.mapInputEl['parentNode'];
    console.log('input-thousanfy.directive->ngAfterViewInit(): parentNode', parentNode);

    if (this.step && this.showButtons) {

      const btnsContainer = document.createElement('div');
      const removeBtn = document.createElement('button');
      const addBtn = document.createElement('button');

      removeBtn.type = 'button';
      addBtn.type = 'button';

      console.log('input-thousanfy.directive->constructor(): this.matInput', this.matInput);

      btnsContainer.appendChild(removeBtn);
      btnsContainer.appendChild(addBtn);

      const btnsContainerStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        right: '-10px',
        top: '3px',
        bottom: '3px',
        // background: '#FFF',
        zIndex: 10
      };

      for (let i in btnsContainerStyles) {
        btnsContainer.style[i] = btnsContainerStyles[i];
      }

      utilsFactory.addClass(addBtn, ['mip-color--text-black', 'theme-color-hover']);
      utilsFactory.addClass(removeBtn, ['mip-color--text-black', 'theme-color-hover']);

      removeBtn.innerHTML = `<img class="__icon" width="28px" src="${environment.assetsPath}/images/icons/do_not_disturb_on.svg">`;
      addBtn.innerHTML = `<img class="__icon" width="28px" src="${environment.assetsPath}/images/icons/add_circle.svg">`;

      const btnStyles = {
        borderRadius: '50px',
        width: '35px',
        height: '35px',
        border: 0,
        background: 'none',
        padding: 0,
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
      };

      for (let i in btnStyles) {
        removeBtn.style[i] = btnStyles[i];
        addBtn.style[i] = btnStyles[i];
      }

      removeBtn.addEventListener('click', (event) => {
        event.stopPropagation();
        this.removeNumber();
      });

      addBtn.addEventListener('click', (event) => {
        event.stopPropagation();
        this.addNumber();
      });

      parentNode.appendChild(btnsContainer);
      parentNode.style.position = 'relative';

    }

    this.formControl.valueChanges.subscribe(value => {
      if (this.hasJustChanged === false) {
        console.log('input-thousanfy.directive->ngAfterViewInit(): change', value);
        this._formatNumber(this.mapInputEl);
      }
    });

    this.mapInputEl.addEventListener('keydown', (event) => {
      console.log('input-thousanfy.directive->ngAfterViewInit(): keydown', event);
      this._onChange(event, this.mapInputEl);
    });

    if (this.mapInputEl.value) {
      console.log('input-thousanfy.directive->ngAfterViewInit(): this.mapInputEl.value', this.mapInputEl.value);
      this._formatNumber(this.mapInputEl);
    }

  }

  _onChange(event, field) {
    try {
      console.log('input-thousanfy.directive->_onChange(): event', event);
      console.log('input-thousanfy.directive->_onChange(): field', field);

      const key = event.key;
      console.log('input-thousanfy.directive->_onChange(): key', key);

      const keyCode = event.keyCode || event.which;
      console.log('input-thousanfy.directive->_onChange(): keyCode', keyCode);

      console.log('input-thousanfy.directive->_onChange(): field.value', field.value);

      switch (keyCode) {
        case 8:  // Backspace
        case 9:  // Tab
        case 13: // Enter
        case 37: // Left
        case 38: // Up
        case 39: // Right
        case 40: // Down
          break;
        default:
          console.log('input-thousanfy.directive->_onChange(): DEFAULT', key.match(/[A-z~˜Dead`'":;?><!@#$%^ˆ&*()´\`¨ç\s_+=/]|-]/g));

          if (key.match(/[A-z~˜Dead`'":;?><!@#$%^ˆ&*()´\`¨ç\s_+=/]|-]/g)) {
            console.log('input-thousanfy.directive->_onChange(): NOT ALLOWED');
            event.preventDefault();

            setTimeout(() => {
              console.log('input-thousanfy.directive->_onChange(): field.value', field.value);

              if (field.value) {
                this.formControl.setValue(field.value.replaceAll(/[A-z~˜Dead`'":;?><!@#$%^ˆ&*()´\`¨ç\s_+=/]|-]/g, ''));
                // this.formControl.markAsTouched();
                // this.formControl.markAsDirty();
              }

            }, 20);

            return;
          }
      }

      console.log('input-thousanfy.directive->_onChange(): field.value', field.value);
      this._formatNumber(field);

    }
    catch (e) {
      console.error('input-thousanfy.directive->_onChange(): ERROR', e);
    }
  }

  _formatNumber(field, userTrueAction = false) {
    console.log('input-thousanfy.directive->_formatNumber(): field', field);
    console.log('input-thousanfy.directive->_formatNumber(): this.prevValue', this.prevValue);

    clearTimeout(this.onChangeTimeout);

    this.onChangeTimeout = setTimeout(() => {

      const value = field.value ? parseInt(field.value.replace(/\,|\./g, '')) : 0;
      console.log('input-thousanfy.directive->_formatNumber(): value', value, this.prevValue);

      let formattedValue = this.decimalPipe.transform(value, '1.0-0');
      console.log('input-thousanfy.directive->_formatNumber(): formattedValue', formattedValue);

      console.log('input-thousanfy.directive->_formatNumber(): this._min', value, this._min);
      console.log('input-thousanfy.directive->_formatNumber(): this._max', value, this._max);

      if (
        this._min && value < this._min ||
        this._max && value > this._max
      ) {
        formattedValue = this.decimalPipe.transform(this.prevValue, '1.0-0');
      }

      this.formControl.setValue(formattedValue);

      if (userTrueAction) {
        this.formControl.markAsTouched();
        this.formControl.markAsDirty();
      }

      this.hasJustChanged = true;

      this.onChange.emit({
        value,
        formattedValue
      });

      this.prevValue = value;

      setTimeout(() => {
        this.hasJustChanged = false;
      }, 5);

    }, 5);
  }

  removeNumber() {
    console.log('input-thousanfy.directive->removeNumber()');

    const value = parseInt((this.mapInputEl.value || '0').replace(/\,|\./g, ''));
    console.log('input-thousanfy.directive->removeNumber(): value', value);

    const newValue = value - (this._step || this._min);
    console.log('input-thousanfy.directive->removeNumber(): newValue', newValue, this._min);

    if (this._min && newValue < this._min) {
      return;
    }

    this.mapInputEl.value = newValue;
    console.log('input-thousanfy.directive->removeNumber(): this.mapInputEl.value', this.mapInputEl.value);
    this._formatNumber(this.mapInputEl, true);

  }

  addNumber() {
    console.log('input-thousanfy.directive->addNumber()');

    const value = parseInt((this.mapInputEl.value || '0').replace(/\,|\./g, ''));
    console.log('input-thousanfy.directive->addNumber(): value', value);

    const newValue = value + (this._step || this._min);
    console.log('input-thousanfy.directive->removeNumber(): newValue', newValue, this._max);

    console.log('input-thousanfy.directive->removeNumber(): this._max', this._max, newValue);

    if (this._max && newValue > this._max) {
      return;
    }

    this.mapInputEl.value = newValue;
    console.log('input-thousanfy.directive->addNumber(): this.mapInputEl.value', this.mapInputEl.value);
    this._formatNumber(this.mapInputEl, true);

  }

}

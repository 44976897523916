import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { environment } from '@libs/gc-common/environments/environment';

@Component({
  selector: 'mip-shape',
  templateUrl: './mip-shape.component.html',
  styleUrls: ['./mip-shape.component.scss']
})
export class MipShapeComponent implements OnInit {

  @Input() shape = 'landscape';

  assetsPath = environment.assetsPath;

  constructor() {
  }

  ngOnInit(): void {
  }

}

<div
  #containerEl
  class="switcher-container"
>
  <!--<div
    class="__switcher"
    [class.&#45;&#45;active-right]="activeLabel.toLowerCase() === labels[1].toLowerCase()"
  >

    <div class="__left-option">
      <span>{{labels[0]}}</span>
    </div>

    <span class="__center-boll"></span>

    <div class="__right-option">
      <span>{{labels[1]}}</span>
    </div>

  </div>-->

  <span #inkEl class="__ink"></span>

  <button
    #labelEl
    mat-button
    class="__label"
    [class.--is-active]="this.activeLabel === label.toLowerCase()"
    *ngFor="let label of labels"
    (click)="onActive(label, labelEl)"
  >
    {{label}}
  </button>

</div>

<div class="__header">
  <h3 *ngIf="hashtagName">

    <span *ngIf="hashtagPrefix">
      {{hashtagPrefix}}
    </span>

    <a [routerLink]="['/hashtag', hashtagName]">
      <span>#{{hashtagName | uppercase}}</span>
    </a>

  </h3>
  <p *ngIf="description" [innerHTML]="description"></p>
</div>

<div class="__posts-container">

  <!-- TOP 10 POSTS CAROUSEL -->
  <swiper
    class="__swiper-container"
    #swiper
    [config]="config"
    [class.--is-desktop]="isDesktop"
    [class.--is-mobile]="isMobile"
  >
    <ng-template
      class="__slide-container"
      *ngFor="let post of posts; let $index = index;"
      swiperSlide
    >

      <a
        class="__first-flag"
        [routerLink]="['/hashtag', hashtagName]"
        *ngIf="showFirstFlag && $index === 0"
      >
        <span>1° #{{hashtagName}}</span>
        <div class="__flag-border"></div>
      </a>

      <!-- POST MAIN COMPONENT -->
      <mip-post-view
        class="__post-view-container"
        [postModel]="post"
        [isIntoViewport]="true"
        [postInteraction]="false"
        [postBasePath]="postBasePath"
        forceTheme="static"
      ></mip-post-view>

    </ng-template>
    <div class="__slide-container __create-post" *ngIf="showCreatePostSlide">

      <div class="__post-view-container">

        <div class="__slide-header">
          <h3 class="--text-align--center" mipTranslate="top-ten-carousel.create-post-slide.title">RAIN OF VIEWS</h3>
          <p class="--text-align--center" mipTranslate="top-ten-carousel.create-post-slide.description">stand out in the trend and maximize your earnings in ads!</p>
        </div>

        <button
          mat-button
          (click)="openCreatePost()"
          mipTranslate="top-ten-carousel.create-post-slide.actions.create-post"
          class="__bottom-action mip-button--size-md --adjust-text"
          mipBgColor="primary"
        >
          Create video
        </button>

      </div>

    </div>

    <div
      class="__slide-container __see-more"
      *ngIf="showSeeMorePostsSlide"
    >

      <a
        mat-button
        class="__post-view-container"
        mipBgColor="primary"
        [routerLink]="['/hashtag', hashtagName]"
      >

        <span class="__see-more-label" mipTranslate="top-ten-carousel.see-more-posts-slide.actions.see-more">See More</span>
        <mip-icon icon="arrow-right" size="5x"></mip-icon>

      </a>

    </div>

  </swiper>

  <div class="__swiper-navigation">
    <button
      mat-icon-button
      class="__swiper-button-prev"
      mipBgColor="primary"
      [class.--opacity]="index === 0"
      [disabled]="index === 0"
      *ngIf="!isMobile"
    >
      <mip-icon icon="chevron-left" [bold]="true"></mip-icon>
    </button>
    <button
      mat-icon-button
      class="__swiper-button-next"
      mipBgColor="primary"
      *ngIf="!isMobile"
    >
      <mip-icon icon="chevron-right" [bold]="true"></mip-icon>
    </button>
  </div>

  <div *ngIf="isLoading" class="__loading --flex-center">
    <mip-loading></mip-loading>
  </div>

</div>

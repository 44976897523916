<header class="header mip-color--bg-dark" [style.background-image]="'url(' + loginBackgroundImage + ')'">
  <a
    class="__go-back"
    mat-button
    [routerLink]="goBackReferrer ? goBackReferrer.path : '/'"
    [queryParams]="goBackReferrer ? goBackReferrer.search : null"
    queryParamsHandling="merge"
  >
    <mip-icon icon="arrow-left" size="2x"></mip-icon>
  </a>

  <div class="__inner">
    <div class="--flex-column --flex-column--center">
      <strong class="__text" mipTranslate="forgot-password-form.header.title">Forgot password</strong>
      <img class="__logo" src="{{ assetsPath }}/images/gc-logo-white.png" />
    </div>
  </div>
</header>

<div class="content">
  <div class="__inner">
    <form class="__form" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
      <h1 class="__title --text-align--center" mipTranslate="login.forgot-password.message.find-your-account">Find your account</h1>

      <h4 class="__subtitle --text-align--center" mipTranslate="login.forgot-password.message.enter-your-email">
        Please enter your email to search for your account to reset your password.
      </h4>

      <!-- EMAIL -->
      <mat-form-field appearance="fill" color="accent">
        <mat-label mipTranslate="login.forgot-password.form.fields.email.label">Email</mat-label>
        <input matInput type="text" formControlName="email" [required]="true" />
        <mat-hint mipTranslate="login.forgot-password.message.provide-email">Please enter your email.</mat-hint>

        <!-- FIELD ERRORS -->
        <mat-error>
          <span *ngIf="forgotPasswordForm.get('email').hasError('required')" mipTranslate="login.forgot-password.form.fields.email.error-message.required">
            You must provide your email.
          </span>
          <span *ngIf="forgotPasswordForm.get('email').hasError('invalid')" mipTranslate="login.forgot-password.form.fields.email.error-message.invalid">
            The email provided is not valid.
          </span>
          <span *ngIf="forgotPasswordForm.get('email').hasError('not-found')" mipTranslate="login.forgot-password.form.fields.email.error-message.not-found">
            The email provided was not found.
          </span>
        </mat-error>
      </mat-form-field>

      <!-- SUBMIT -->
      <button class="__submit-button mip-button--size-md" mat-flat-button type="submit" mipBgColor="primary" [disabled]="loadingForgotPassword">
        <mip-icon icon="refresh" [spin]="true" *ngIf="loadingForgotPassword"></mip-icon>
        <span mipTranslate="login.forgot-password.form.action.submitting" *ngIf="loadingForgotPassword">Working...</span>
        <span mipTranslate="login.forgot-password.form.action.submit" *ngIf="!loadingForgotPassword">Continue</span>
      </button>

      <!-- FORM ERRORS -->
      <mip-alert class="__alert-message" theme="danger" *ngIf="errorNotFound">
        <mip-icon icon="warning" [solid]="true"></mip-icon>
        <span mipTranslate="login.forgot-password.message.error-not-found"> Your search did not return any results. Please try again with a different email. </span>
      </mip-alert>

      <!-- FORM ERRORS -->
      <mip-alert class="__alert-message" theme="danger" *ngIf="errorForgotPassword">
        <mip-icon icon="warning" [solid]="true"></mip-icon>
        <span mipTranslate="login.forgot-password.form.message.error"> There was an error while trying to start the forgot password process. </span>
      </mip-alert>
    </form>
  </div>
</div>

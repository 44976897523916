import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot
} from '@angular/router';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';

@Injectable({
	providedIn: 'root'
})
export class RedirectWith404SnackBarGuard implements CanActivate {
	
	constructor(
		private router: Router,
		private routerService: RouterService,
		private snackBar: MatSnackBar
	) {
	}
	
	async canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	) {
		console.log('redirect-with-404-snackbar-guard.service->canActivate() state.url', state.url);
		
		console.log('redirect-with-404-snackbar-guard.service->canActivate() route', route);
		console.log('redirect-with-404-snackbar-guard.service->canActivate() state', state);
		console.log('redirect-with-404-snackbar-guard.service->canActivate() route.data', route.data);
		
		this.snackBar.open(`Page "${state.url}" not found!`, 'Ok', {
			panelClass: 'mip-color--bg-danger',
			horizontalPosition: 'end',
			duration: 8000
		});
		
		return this.router.parseUrl(route.data['redirectTo']);
		
	}
}

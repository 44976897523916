import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';

export interface TreeMenuInterface {
  
  id?: string;
  name?: string;
  label?: string;
  shortLabel?: string;
  expandable?: boolean;
  isActive?: boolean;
  isExpanded?: boolean;
  isActiveParent?: boolean;
  routerLink?: string;
  href?: string;
  listChildren?: boolean;
  hide?: boolean;
  firstChild?: boolean;
  lastChild?: boolean;

  // ICON
  pngIcon?: string;
  mipIcon?: string;
  matIcon?: string;
  iconSize?: string;
  outlinedIcon?: boolean;

  // SUFFIX ICON
  suffixPngIcon?: string;
  suffixMipIcon?: string;
  suffixMatIcon?: string;
  suffixIconSize?: string;
  suffixOutlinedIcon?: boolean;

  parent?: TreeMenuInterface;
  children?: TreeMenuInterface[];
  
  authorizedRoles?: Array<string>;
  isAuthorized?: (UserModel) => boolean;

}

@Component({
  selector: 'mip-tree-menu',
  templateUrl: './tree-menu.component.html',
  styleUrls: ['./tree-menu.component.scss']
})
export class TreeMenuComponent implements OnChanges, OnDestroy {

  @Input() menuTree: TreeMenuInterface[] = [];
  @Input() hideLabels = false;
  @Input() urlFixedPrefix = '';
  @Input() forceExpandAll = false;

  menuTreeById: {
    [key: string]: TreeMenuInterface
  } = {};

  onRouteChangeSubscription = null;
  currentUrl = null;

  constructor(
    private routerService: RouterService
  ) {

 // console.allowNamespace('tree-menu.component');

    this.onRouteChangeSubscription = this.routerService.onRouteChange().subscribe(({ url }) => {
      console.log('tree-menu.component->constructor(): url', url);
      this.currentUrl = url;
      this.expandTreeByUrl(this.currentUrl);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if ('menuTree' in changes && this.menuTree.length) {
        console.log('tree-menu.component->ngOnChanges(): this.menuTree', this.menuTree);
        console.log('tree-menu.component->ngOnChanges(): this.currentUrl', this.currentUrl);

        this.normalizeTree(this.menuTree);
        console.log('tree-menu.component->ngOnChanges(): this.menuTreeById', this.menuTreeById);

        console.log('tree-menu.component->ngOnChanges(): this.currentUrl', this.currentUrl);
        console.log('tree-menu.component->ngOnChanges(): this.menuTreeById[this.currentUrl]', this.menuTreeById[this.currentUrl]);

        if (this.currentUrl) {
          this.expandTreeByUrl(this.currentUrl);
        }

      }
    }
    catch (e) {
      console.error('tree-menu.component->ngOnChanges(): ERROR', e);
    }
  }

  ngOnDestroy() {
    if (this.onRouteChangeSubscription) {
      this.onRouteChangeSubscription.unsubscribe();
    }
  }

  normalizeTree(menuTree, parent?) {
    try {
      console.log('tree-menu.component->normalizeTree(): menuTree', menuTree);

      for (let menu of menuTree) {

        if (menu.routerLink) {
          menu.id = menu.routerLink; // parent ? `${parent.id}/${menu.routerLink}` : `${('/' + this.urlFixedPrefix).replace('//', '/')}/${menu.routerLink}`;
          this.menuTreeById[menu.id] = menu;
        }

        if (parent) {
          menu.parent = parent;
        }

        if (menu.children) {
          this.normalizeTree(menu.children, menu);
        }

      }

    }
    catch (e) {
      console.error('tree-menu.component->normalizeTree(): ERROR', e);
    }
  }

  expandTreeByUrl(url) {
    console.log('tree-menu.component->expandTreeByUrl(): url', url);

    let hasFoundMenuItem = false;

    if (this.menuTreeById[url]) {
      this.collapseTree(this.menuTree);
      this.expandTree(this.menuTreeById[this.currentUrl]);
    }
    else {
      let parsedUrl = url.split('/');
      console.log('tree-menu.component->expandTreeByUrl(): parsedUrl', parsedUrl);

      while (parsedUrl.length) {
        parsedUrl.splice(-1, 1);
        console.log('tree-menu.component->expandTreeByUrl(): parsedUrl', parsedUrl);

        const newUrl = parsedUrl.join('/');
        console.log('tree-menu.component->expandTreeByUrl(): newUrl', newUrl);

        if (this.menuTreeById[newUrl]) {
          this.collapseTree(this.menuTree);
          this.expandTree(this.menuTreeById[newUrl]);

          hasFoundMenuItem = true;

          break;
        }

      }

      if (hasFoundMenuItem === false) {
        this.collapseAllTree();
      }

    }
  }

  collapseAllTree() {
    console.log('tree-menu.component->collapseAllTree()');

    for (let i in this.menuTreeById) {
      const menu = this.menuTreeById[i];
      menu.isActive = false;
      menu.isActiveParent = false;
    }
  }

  collapseTree(menuTree) {
    console.log('tree-menu.component->collapseTree(): menuTree', menuTree);

    for (let menu of menuTree) {
      menu.isActive = false;
      menu.isActiveParent = false;

      if (menu.children) {
        this.collapseTree(menu.children);
      }
    }
  }

  expandTree(menuTree, isParent = false) {
    console.log('tree-menu.component->expandTree(): menuTree', menuTree);

    menuTree.isActive = true;
    menuTree.isActiveParent = isParent;

    if (menuTree.parent) {
      this.expandTree(menuTree.parent, true);
    }
  }

}

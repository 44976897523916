<mat-toolbar
  [class]="'--theme-' + theme"
  class="main-menu"
>

  <!-- HOME: trending -->
  <div class="__actions">
    <a
      [routerLinkActive]="['mip-color-text-primary']"
      [routerLink]="['/trending']"
      class="__action"
      mat-button
    >
      <mip-icon [bold]="!isRouteEnable('trending')" [solid]="isRouteEnable('trending')" icon="home"></mip-icon>
    </a>
  </div>


  <!-- SEARCH -->
  <div class="__actions">
    <a
      [routerLinkActive]="['mip-color-text-primary']"
      [routerLink]="['/search']"
      class="__action"
      mat-button
    >
      <mip-icon [bold]="isRouteEnable('search')" icon="search"></mip-icon>
    </a>
  </div>

  <!-- COMPOSER -->
  <div class="__actions">
    <button
      (click)="openDownloadAppDialog()"
      class="__action __composer"
      mat-button
      mipBgColor="primary"
    >
      <mip-icon [bold]="true" icon="plus"></mip-icon>
    </button>
  </div>

  <!-- ANONYMOUS ACTION: login -->
  <div *ngIf="!loggedInUser" class="__actions">
    <a
      [mipGoBackReferrer]="true"
      [routerLinkActive]="['mip-color-text-primary']"
      class="__action"
      mat-button
      [href]="loginUrl"
    >
      <mip-icon [bold]="true" icon="profile"></mip-icon>
    </a>
  </div>

  <!-- AUTHENTICATED ACTION: profile -->
  <div *ngIf="loggedInUser" class="__actions">
    <a
      [routerLinkActive]="['mip-color-text-primary']"
      [title]="loggedInUser.username"
      class="__action"
      mat-button
      routerLink="/@{{loggedInUser.username}}"
    >
      <mip-icon [bold]="!isRouteEnable('@' + loggedInUser.username)" [solid]="isRouteEnable('@' + loggedInUser.username)"
                icon="profile"></mip-icon>
    </a>
  </div>

  <!-- SIDE MENU -->
  <div class="__actions">
    <button
      (click)="openSideMenu()"
      class="__action"
      mat-button
    >
      <mip-icon [bold]="true" icon="menu"></mip-icon>
    </button>
  </div>

</mat-toolbar>



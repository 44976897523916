export interface PostInteractionActionInterface {
  order?: number;
  name?: string;
  icon?: string;
  lottieIcon?: string;
  lottieStopFrame?: number;
  svgIcon?: string;
  pngIcon?: string;
  gifIcon?: string;
  activeGifIcon?: string;
  solidIcon?: boolean;
  boldIcon?: boolean;
  count?: string | number;
  activated?: boolean;
  loading?: boolean;
  authenticated?: boolean;
}

export interface PostInteractionOptionInterface {
  icon?: string;
  lottieIcon?: string;
  lottieStopFrame?: number;
  svgIcon?: string;
  pngIcon?: string;
  gifIcon?: string;
  solidIcon?: boolean;
  boldIcon?: boolean;
  text?: string;
  onSelect?: (any?) => void;
  class?: string;
  authenticated?: boolean;
}

export interface PostInteractionInterface {
  activated?: boolean;
  circle?: boolean;
  icon?: string;
  lottieIcon?: string;
  lottieStopFrame?: number;
  svgIcon?: string;
  pngIcon?: string;
  gifIcon?: string;
  solidIcon?: boolean;
  boldIcon?: boolean;
  count?: string | number;
  activeAction?: PostInteractionActionInterface;
  actions?: Array<PostInteractionActionInterface>;
  options?: Array<PostInteractionOptionInterface>;
  onClick?: (any?) => void;
  authenticated?: boolean;
  visible?: boolean;
}

export class PostInteractionModel implements PostInteractionInterface {

  activated = null;
  circle = null;
  icon = null;
  lottieIcon = null;
  lottieStopFrame = 0;
  svgIcon = null;
  pngIcon = null;
  solidIcon = false;
  boldIcon = false;
  count = null;
  activeAction: PostInteractionActionInterface = null;
  actions: Array<PostInteractionActionInterface> = [];
  options: Array<PostInteractionActionInterface> = [];
  onClick = null;
  visible = true;

  constructor(model: PostInteractionInterface) {
    try {
      for (const i in model) {
        if (i in this) {
          if (i === 'actions' && model[i]) {
            for (const action of model[i]) {
              this.addAction(action);
            }
          }
          else if (i === 'options' && model[i]) {
            for (const option of model[i]) {
              this.addOption(option);
            }
          }
          else {
            this[i] = model[i];
          }
        }
      }
    }
    catch (e) {
      console.error('post-interation.model->constructor(): ERROR', e);
    }
  }

  addAction(action: PostInteractionActionInterface) {
    if (action) {
      this.actions.push(action);
    }
  }

  addOption(option: PostInteractionOptionInterface) {
    if (option) {
      this.options.push(option);
    }
  }

}

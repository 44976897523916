import {
	Directive,
	ElementRef,
	HostListener,
	Input,
	OnChanges,
	SimpleChanges
} from '@angular/core';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';
import { TranslateService } from '@ngx-translate/core';

@Directive({
	selector: '[mipRouterLink]'
})
export class RouterLinkDirective implements OnChanges {
	
	@Input() mipRouterLink: string | Array<string> = null;
	@Input() queryParams: object = {};
	
	hostEl = null;
	routerLink = '';
	currentLang = '';
	currentQueryParams: {
		lang: string
	} = null;
	
	constructor(
		private el: ElementRef,
		private translate: TranslateService,
		private routerService: RouterService
	) {
		
		this.hostEl = this.el.nativeElement;
		console.log('router-link.directive->constructor(): this.hostEl', this.hostEl);
		
	}
	
	ngOnChanges(changes: SimpleChanges) {
		
		console.log('router-link.directive->ngOnInit(): changes', changes);
		console.log('router-link.directive->ngOnInit(): this.mipRouterLink', this.mipRouterLink);
		
		if (this.mipRouterLink) {
			
			this.currentQueryParams = {
				lang: this.translate.getDefaultLang()
			};
			
			console.log('router-link.directive->ngOnInit(): this.currentQueryParams', this.currentQueryParams);
			
			if (Array.isArray(this.mipRouterLink)) {
				this.routerLink = this.mipRouterLink.join('/');
			}
			else if (typeof this.mipRouterLink === 'string') {
				this.routerLink = this.mipRouterLink;
			}
			
			console.log('router-link.directive->ngOnInit(): this.routerLink', this.routerLink);
			
			this.currentQueryParams = { ...this.currentQueryParams, ...this.queryParams };
			console.log('router-link.directive->ngOnInit(): this.currentQueryParams', this.currentQueryParams);
			
			if (this.routerLink) {
				
				console.log('router-link.directive->ngOnInit(): serializeObject()', utilsFactory.serializeObject(this.currentQueryParams));
				
				this.hostEl.href = this.routerLink + (this.routerLink.indexOf('?') > -1 ? '&' : '?') + utilsFactory.serializeObject(this.currentQueryParams);
				console.log('router-link.directive->ngOnInit(): this.hostEl.href', this.hostEl.href);
				
			}
			
		}
		
	}
	
	@HostListener('click', ['$event'])
	onClick(event) {
		
		console.log('router-link.directive->onClick(): event', event);
		event.preventDefault();
		
		this.routerService.navigateTo(this.routerLink, { queryParams: this.currentQueryParams });
		
	}
	
}

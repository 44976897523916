import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { RouterLink } from '@angular/router';
import {
  MipAlertModule,
  MipDividerModule,
  MipIconModule,
  MipTranslateModule,
  RecaptchaModule
} from '@lib/gc-common';

import { LoginCopyrightModule } from '../copyright/login-copyright.module';
import { LoginSocialModule } from '../login-social/login-social.module';
import { RegisterFormComponent } from './register-form.component';

@NgModule({
  declarations: [
    RegisterFormComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    RouterLink,
    LoginSocialModule,
    MipTranslateModule,
    ReactiveFormsModule,
    MatInputModule,
    MipIconModule,
    MatCheckboxModule,
    RecaptchaModule,
    MipAlertModule,
    MipDividerModule,
    LoginCopyrightModule
  ],
  exports: [
    RegisterFormComponent
  ]
})
export class LoginRegisterFormModule {
}

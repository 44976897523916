import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ParseIntPipe } from './parse-int.pipe';

@NgModule({
  declarations: [
    ParseIntPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ParseIntPipe
  ]
})
export class ParseIntModule {
}

import { Injectable } from '@angular/core';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { ResourceService } from '@libs/gc-common/lib/services/resource/resource.service';

export interface UserWalletInterface {
  gotchosen_user_id: string;
  gotchosen_username: string;
  instagram_user_id: string;
  instagram_username: string;
  user_name?: string;
  validation_status?: string; // pending|approved|rejected
  instagram_followers?: number;
}

export interface FbLongLiveTokenInterface {
  access_token?: string;
  content_type?: string;
  expires_in?: string;
  id?: string;
  instagram_business_account?: string;
  network_name?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SocialHubService {

  static instance: SocialHubService;

  constructor(
    private resourceService: ResourceService
  ) {
    SocialHubService.instance = this;

    const apiToken = this.resourceService.getAuthorizationToken('api');
    console.log('social-hub.service->constructor(): apiToken', apiToken);

    if (apiToken) {
      this.resourceService.setAuthorizationToken(['api', 'scraperApi', 'mipWebApi', 'collabApi'], apiToken);
    }

  }

  /**
   * Method to get a list of users based on status
   * @param status all|pending|approved|rejected
   */
  async getListUsers(status?: string): Promise<UserWalletInterface[]> {
    try {

      if (utilsFactory.isBrowser) {
        const lsIsFirstLoad = window.localStorage.getItem('mip-sh-is-not-fist-load');

        if (!lsIsFirstLoad) {

          try {
            await this.deleteUser('17841453870378068', '338821');

            await this.saveUser({
              gotchosen_user_id: '338821',
              gotchosen_username: 'marco2021',
              instagram_user_id: '17841453870378068',
              instagram_username: 'marcosales41'
            });
          }
          catch (e) {
            console.error('social-hub.service->getListUsers(): ERROR', e);
          }

          window.localStorage.setItem('mip-sh-is-not-fist-load', 'true');

        }
      }

      const params = { status: '' };

      if (['pending', 'approved', 'rejected'].indexOf(status) > -1) {
        params.status = status;
      }

      const response = await this.resourceService.get('scraperApi', {
        resource: 'sh-wallet',
        path: `/private/mip-wallet/user/list`,
        params,
        fromCache: false,
        removeHeaders: ['user-country', 'device-uuid']
      });

      console.log('social-hub.service->getListUsers(): response', response);

      return response;

    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to upload the user data, especially the number of followers
   */
  async saveUser(data: UserWalletInterface): Promise<UserWalletInterface> {
    try {

      console.log('social-hub.service->saveUser(): data', data.validation_status, data);

      const response = await this.resourceService.post('scraperApi', {
        resource: 'sh-wallet',
        path: `/private/mip-wallet/user`,
        data,
        fromCache: false,
        removeHeaders: ['user-country', 'device-uuid']
      });

      console.log('social-hub.service->saveUser(): response', response);

      return response;

    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to delete the user request (not the user it self, just his/her request for approval
   */
  async deleteUser(instagram_user_id, gotchosen_user_id): Promise<UserWalletInterface> {
    try {

      console.log('social-hub.service->deleteUser(): {instagram_user_id, gotchosen_user_id}', {instagram_user_id, gotchosen_user_id});

      const response = await this.resourceService.delete('scraperApi', {
        resource: 'sh-wallet',
        path: `/private/mip-wallet/user`,
        params: { instagram_user_id, gotchosen_user_id },
        fromCache: false,
        removeHeaders: ['user-country', 'device-uuid']
      });

      console.log('social-hub.service->deleteUser(): response', response);

      return response;

    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to get the Facebook Page long live token + instagram business account
   * @param page_id The Facebook page ID
   * @param access_token The Facebook user access_token
   */
  async getFbPageLongLiveToken(page_id, access_token): Promise<FbLongLiveTokenInterface> {
    console.log('social-hub.service->getFbLongLiveToken(): page_id, access_token', page_id, access_token);

    try {

      if (!page_id) {
        throw new Error(`The 'page_id' must be provided`);
      }

      if (!access_token) {
        throw new Error(`The page 'access_token' must be provided`);
      }

      const response = await this.resourceService.get('scraperApi', {
        resource: 'graph-facebook',
        path: `/private/graph-facebook/page/long-live-token/${page_id}`,
        params: { access_token, get_instagram_account: true },
        fromCache: false,
        removeHeaders: ['user-country', 'device-uuid']
      });

      console.log('social-hub.service->getFbLongLiveToken(): response', response);

      return response;

    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to get the instagram basic profile
   */
  async getInstagramProfileByUsername(instagram_business_id, username, access_token) {
    console.log('social-hub.service->getInstagramProfileByUsername(): {username, instagram_business_id, access_token}', {username, instagram_business_id, access_token});

    try {

      if (!instagram_business_id) {
        throw new Error(`The 'instagram_business_id' must be provided`);
      }

      if (!username) {
        throw new Error(`The 'username' must be provided`);
      }

      if (!access_token) {
        throw new Error(`The page 'access_token' must be provided`);
      }

      const response = await this.resourceService.get('scraperApi', {
        resource: 'graph-facebook',
        path: `/private/graph-instagram/business-discovery/${instagram_business_id}`,
        params: { username, access_token },
        fromCache: false,
        removeHeaders: ['user-country', 'device-uuid']
      });

      console.log('social-hub.service->getInstagramProfileByUsername(): response', response);

      return response;

    }
    catch (e) {
      throw e;
    }
  }

}

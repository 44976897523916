import {
  Component,
  Inject,
  OnInit
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';

interface MipDialogActionInterface {
  bgColor?: string;
  bdColor?: string;
  textColor?: string;
  icon?: string;
  iconBold?: boolean;
  iconSolid?: boolean;
  iconSize?: string;
  label: string;
  loading?: boolean;
  click: () => any;
}

interface MipDialogInterface {
  title?: string;
  body: string;
  actions?: MipDialogActionInterface[];
}

@Component({
  selector: 'mip-mip-dialog',
  templateUrl: './mip-dialog.component.html',
  styleUrls: ['./mip-dialog.component.scss']
})
export class MipDialogComponent implements OnInit {

  title = null;
  body = '';
  actions: MipDialogActionInterface[] = [{
    label: 'Ok',
    click: this.close.bind(this)
  }];

  constructor(
    public dialogRef: MatDialogRef<MipDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MipDialogInterface
  ) {
    console.log('mip-dialog.component->constructor(): data', data);

    if (!data.title) {
      throw new Error(`'title' must be provided`);
    }

    this.title = data.title;

    if (data.body) {
      this.body = data.body;
    }

    if (data.actions) {
      this.actions = data.actions;
    }

  }

  ngOnInit(): void {
  }

  onClick(action) {
    action.click(action, this.actions, this);
  }

  close() {
    console.log('mip-dialog.component->close()');
    this.dialogRef.close();
  }

}

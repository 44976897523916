<div class="mip-dialog">
  <h3 class="__title" *ngIf="title">{{title}}</h3>

  <div class="__body" *ngIf="body">
    <p>{{body}}</p>
  </div>

  <div class="__actions">
    <button
      mat-button
      class="__action"
      mat-dialog-close
    >
      <span mipTranslate="mip-dialog.actions.cancel">Cancel</span>
    </button>
    <button
      mat-button
      class="__action"
      *ngFor="let action of actions"
      [mipBgColor]="action.bgColor"
      [mipBdColor]="action.bdColor"
      [mipTextColor]="action.textColor"
      [disabled]="action.loading"
      (click)="onClick(action)"
    >
      <mip-icon
        *ngIf="action.loading || action.icon"
        [icon]="action.loading ? 'refresh' : action.icon"
        [spin]="action.loading"
        [bold]="action.iconBold"
        [solid]="action.iconSolid"
        [size]="action.iconSize || '2x'"
      ></mip-icon>
      <span *ngIf="action.label">{{action.label}}</span>
    </button>
  </div>

</div>

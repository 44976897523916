import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { MipTooltipComponent } from './mip-tooltip.component';
import { MipTooltipDirective } from './mip-tooltip.directive';

@NgModule({
  declarations: [
    MipTooltipComponent,
    MipTooltipDirective
  ],
  imports: [
    CommonModule,
    MatButtonModule
  ],
  exports: [
    MipTooltipComponent,
    MipTooltipDirective
  ]
})
export class MipTooltipModule {
}

<header class="header mip-color--bg-dark" [style.background-image]="'url(' + loginBackgroundImage + ')'">
  <a class="__go-back" mat-button [routerLink]="['/', 'forgot-password']" queryParamsHandling="merge">
    <mip-icon icon="arrow-left" size="2x"></mip-icon>
  </a>

  <div class="__inner">
    <div class="--flex-column --flex-column--center">
      <strong class="__text" mipTranslate="change-password-form.header.title">Change password</strong>
      <img class="__logo" src="{{ assetsPath }}/images/gc-logo-white.png" />
    </div>
  </div>
</header>

<div class="content">
  <div class="__inner">
    <!-- EMAIL SENT -->
    <div class="__content" *ngIf="urlCode === false">
      <mip-icon icon="direct" size="12x" style="margin-bottom: 40px" mipTextColor="primary"></mip-icon>

      <h3 class="--text-align--center" style="margin-bottom: 20px" mipTranslate="login.change-password.message.title">
        <strong>We found your account!</strong>
      </h3>

      <p class="--text-align--center" mipTranslate="login.change-password.message.check-your-inbox">
        We just sent an email to you. Please check your inbox and follow the steps to reset your password.
      </p>
    </div>

    <!-- CHANGE PASSWORD -->
    <div class="__content" *ngIf="urlCode && isUrlCodeValid && errorChangeNewPassword === false">
      <form class="__form" *ngIf="isUrlCodeValid && !savePasswordSuccess" [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
        <!-- PASSWORD -->
        <mat-form-field class="--has-hint" appearance="fill" color="accent">
          <mat-label mipTranslate="login.change-password.fields.password.label">New Password</mat-label>
          <span matPrefix><mip-icon icon="lock" size="1x"></mip-icon></span>
          <input matInput [type]="showPassword ? 'text' : 'password'" formControlName="newPassword" />
          <a mat-button matSuffix [class.mip-color--text-primary]="showPassword" (click)="showPassword = !showPassword">
            <mip-icon icon="eye" [solid]="true" size="1x"></mip-icon>
          </a>

          <mat-hint mipTranslate="login.change-password.fields.password.hint-message"> Your password must have letters and numbers and be at least 8 characters length </mat-hint>

          <mat-error>
            <span *ngIf="changePasswordForm.get('newPassword').hasError('required')" mipTranslate="login.change-password.fields.password.error-message.required">
              You must provide your password
            </span>
            <span *ngIf="changePasswordForm.get('newPassword').hasError('length')" mipTranslate="login.change-password.fields.password.error-message.length">
              Your password must have at least 8 characters length
            </span>
            <span *ngIf="changePasswordForm.get('newPassword').hasError('invalid-pattern')" mipTranslate="login.change-password.fields.password.error-message.invalid-pattern">
              Your password must have letters and numbers
            </span>
          </mat-error>
        </mat-form-field>

        <button class="__submit-button mip-button--size-md" mat-flat-button type="submit" mipBgColor="primary" [disabled]="savingNewPassword">
          <mip-loading *ngIf="savingNewPassword" color="white" size="3x"></mip-loading>
          <span *ngIf="savingNewPassword" mipTranslate="login.change-password.form.action.changing-password">Changing password...</span>

          <span *ngIf="!savingNewPassword" mipTranslate="login.change-password.form.action.change-password">Change password</span>
        </button>
      </form>

      <mip-alert class="__alert-message" mipBgColor="success" *ngIf="savePasswordSuccess">
        <mip-icon icon="check" size="9x"></mip-icon>

        <div class="__alert-inner">
          <span mipTranslate="login.change-password.message.change-password-successfully"> Your password was changed successfully. </span>

          <round-progress
            [radius]="22"
            [stroke]="5"
            [current]="100"
            [max]="100"
            [duration]="3000"
            animation="linearEase"
            background="rgba(0, 0, 0, 0.5)"
            color="#FFF"
          ></round-progress>
        </div>
      </mip-alert>
    </div>

    <!-- NEW CODE -->
    <!--<div class="__new-code" mipBgColor="gray" *ngIf="urlCode === false || codeError">

      <p mipTranslate="login.change-password.form.message.new-code">
        Didn't receive the verification email?
      </p>

      <button
        class="__resend-email-btn &#45;&#45;text-align&#45;&#45;center mip-button&#45;&#45;size-lg"
        mat-flat-button
        mipBgColor="primary"
        [disabled]="newCodeLoading || requestNewCode"
        (click)="getNewCode()"
      >

        <mip-icon [icon]="newCodeLoading ? 'refresh' : 'direct'" size="2x" [spin]="newCodeLoading"></mip-icon>

        <span mipTranslate="login.change-password.form.action.new-code">Resend email</span>

        <div class="__round-progress" *ngIf="requestNewCode">
          <round-progress
            [radius]="15"
            [stroke]="3"
            [current]="100"
            [max]="100"
            [duration]="60000"
            animation="linearEase"
            background="rgba(0, 0, 0, 0.4)"
            color="#FFF"
          ></round-progress>
        </div>

      </button>

    </div>-->

    <!-- URL CODE INVALID -->
    <mip-alert class="__alert-message" theme="warning" *ngIf="(urlCode && !isUrlCodeValid) || errorChangeNewPassword" style="margin-bottom: 20px">
      <mip-icon icon="warning"></mip-icon>
      <div mipTranslate="login.code-verifier.message.code-url-invalid">The <strong>code</strong> parameter provided within the URL is not valid.</div>
    </mip-alert>

    <!--<mip-alert class="__alert-message" theme="danger" *ngIf="errorChangeNewPassword" style="margin-top: 20px">
      <mip-icon icon="warning"></mip-icon>
      <div mipTranslate="login.code-verifier.message.code-no-longer-invalid" [translateValues]="{urlCode: urlCode}">
        The URL <strong>code</strong> provided is not valid.
      </div>
    </mip-alert>-->

    <!--<mip-alert class="__alert-message" theme="warning" *ngIf="errorInvalidCredentials">
      <mip-icon icon="warning"></mip-icon>
      <span mipTranslate="login-form.error-message.login-credentials-error">
          The username/email or the password provided is not valid or does not exist.
        </span>
    </mip-alert>-->

    <mip-alert class="__alert-message" theme="danger" *ngIf="errorSuspendedUser">
      <mip-icon icon="warning" [solid]="true"></mip-icon>
      <span mipTranslate="login-form.error-message.account-suspended">
        Your account has been temporarily suspended due to violation of ours <strong>Terms:</strong>
        <a href="{{ networkUrl }}/monetization-terms" target="_blank">Monetization Terms</a>, <a href="{{ networkUrl }}/privacy" target="_blank">Privacy Policy</a> or
        <a href="{{ networkUrl }}/terms-of-service" target="_blank">Terms of Service</a>
      </span>
    </mip-alert>

    <mip-alert class="__alert-message" theme="danger" *ngIf="errorBannedUser">
      <mip-icon icon="warning" [solid]="true"></mip-icon>
      <span mipTranslate="login-form.error-message.account-banned">
        Your account has been banned due to violation of ours <strong>Terms:</strong> <a href="{{ networkUrl }}/monetization-terms" target="_blank">Monetization Terms</a>,
        <a href="{{ networkUrl }}/privacy" target="_blank">Privacy Policy</a> or <a href="{{ networkUrl }}/terms-of-service" target="_blank">Terms of Service</a>
      </span>
    </mip-alert>

    <mip-alert class="__alert-message" theme="danger" *ngIf="errorDeletedUser">
      <mip-icon icon="warning" [solid]="true"></mip-icon>
      <span mipTranslate="login-form.error-message.account-deleted"> Your account has been deleted </span>
    </mip-alert>
  </div>
</div>

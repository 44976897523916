<header class="header mip-color--bg-dark" [style.background-image]="'url(' + loginBackgroundImage + ')'">
  <a class="__go-back" mat-button [routerLink]="['/']" queryParamsHandling="merge">
    <mip-icon icon="arrow-left" size="2x"></mip-icon>
  </a>

  <div class="__inner">
    <div class="--flex-column --flex-column--center">
      <strong class="__text" mipTranslate="gender-form.header.title">Gender</strong>
      <img class="__logo" src="{{ assetsPath }}/images/gc-logo-white.png" />
    </div>
  </div>
</header>

<div class="content">
  <div class="__inner">
    <div class="__user-card">
      <mip-avatar [userModel]="userModel" [size]="40"></mip-avatar>
      <div class="__info">
        <div *ngIf="userModel?.name">{{ userModel.name }}</div>
        <mip-profile-slug-name [user]="userModel"></mip-profile-slug-name>
      </div>
    </div>

    <mip-alert class="__alert-message" textAlign="center">
      <div>
        <span mipTranslate="register-form.fields.gender.alert"> You must provide your gender to continue! </span>
      </div>
    </mip-alert>

    <form class="__form" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
      <!-- GENDER -->
      <mat-form-field>
        <mat-label mipTranslate="register-form.fields.gender.label">Gender</mat-label>
        <span matPrefix><mip-icon pngIcon="gender" size="5x"></mip-icon></span>
        <mat-select formControlName="gender">
          <mat-option *ngFor="let option of genders" [value]="option.value">
            {{ option.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- SUBMIT -->
      <button class="__submit-button mip-button--size-md mip-color--bg-primary" mat-flat-button type="submit" [disabled]="loadingSettings || !registerForm.get('gender').value">
        <mip-icon icon="refresh" [spin]="true" *ngIf="loadingSettings"></mip-icon>
        <span mipTranslate="register-form.actions.please-wait" *ngIf="loadingSettings">Please wait...</span>
        <span mipTranslate="register-form.actions.continue" *ngIf="!loadingSettings">Continue</span>
      </button>

      <mip-alert class="__alert-message" theme="danger" *ngIf="errorSettings">
        <mip-icon icon="warning" [solid]="true"></mip-icon>
        <p mipTranslate="register-form.error-message.gender-settings-error">There was an error while trying to save your gender.</p>
      </mip-alert>
    </form>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import {
  RouterLink,
  RouterOutlet
} from '@angular/router';
import {
  ColorModule,
  GoBackModule,
  MipAlertModule,
  MipDividerModule,
  MipIconModule,
  MipTranslateModule
} from '@lib/gc-common';

import { LoginCopyrightModule } from '../copyright/login-copyright.module';
import { LoginSocialModule } from '../login-social/login-social.module';
import { LoginFormComponent } from './login-form.component';

@NgModule({
  declarations: [LoginFormComponent],
  exports: [LoginFormComponent],
  imports: [
    CommonModule,
    MipTranslateModule,
    ReactiveFormsModule,
    MatInputModule,
    MipIconModule,
    GoBackModule,
    RouterLink,
    MatButtonModule,
    ColorModule,
    MipAlertModule,
    MipDividerModule,
    LoginSocialModule,
    LoginCopyrightModule,
    RouterOutlet,
    MatCheckboxModule
  ]
})
export class LoginFormModule {
}

<header class="header mip-color--bg-dark" [style.background-image]="'url(' + loginBackgroundImage + ')'">
  <a class="__go-back" mat-button [routerLink]="['/']" queryParamsHandling="merge"> Login </a>

  <div class="__inner">
    <div class="--flex-column --flex-column--center">
      <strong class="__text" mipTranslate="login.code-verifier.header.title">Sign up for</strong>
      <img class="__logo" src="{{ assetsPath }}/images/gc-logo-white.png" />
    </div>
  </div>
</header>

<div class="content">
  <div class="__inner">
    <!-- EMAIL NOT VALIDATED YEcodeErrorT -->
    <div *ngIf="isThereUrlCode === false">
      <mip-icon icon="direct" size="12x" style="margin-bottom: 40px" mipTextColor="primary"></mip-icon>

      <div *ngIf="hasEmail">
        <h3 class="--text-align--center" style="margin-bottom: 20px" mipTranslate="login.code-verifier.message.not-verify-yet">Your email is not verify yet</h3>

        <p class="--text-align--center" mipTranslate="login.code-verifier.message.sent-email">We just sent an email to you!</p>

        <p class="--text-align--center" mipTranslate="login.code-verifier.message.check-your-inbox">Please check your inbox and follow the steps to verify your email.</p>
      </div>

      <div *ngIf="!hasEmail">
        <h3 class="--text-align--center" style="margin-bottom: 20px" mipTranslate="login.code-verifier.message.provide-your-email">
          Please, provide your email below then check your inbox and follow the steps to verify your email.
        </h3>
      </div>
    </div>

    <!-- EMAIL VALIDATING -->
    <div *ngIf="isThereUrlCode === true">
      <!-- CHECKING CODE/SUCCESS -->
      <mip-alert class="__alert-message" [mipBgColor]="codeSuccess ? 'success' : 'gray'" *ngIf="isUrlCodeValid && !codeError">
        <round-progress
          *ngIf="!codeLoading"
          [radius]="22"
          [stroke]="5"
          [current]="100"
          [max]="100"
          [duration]="3000"
          animation="linearEase"
          background="rgba(0, 0, 0, 0.5)"
          color="#FFF"
        ></round-progress>

        <mip-loading *ngIf="codeLoading" size="9x"></mip-loading>

        <span mipTranslate="login.code-verifier.message.validating" *ngIf="codeLoading"> Please wait while we verify your email. </span>

        <span mipTranslate="login.code-verifier.message.validate-successfully" *ngIf="codeSuccess"> We have verified your email successfully. </span>
      </mip-alert>

      <!-- URL CODE INVALID -->
      <mip-alert class="__alert-message" theme="warning" *ngIf="!isUrlCodeValid" style="margin-bottom: 20px">
        <mip-icon icon="warning"></mip-icon>
        <div mipTranslate="login.code-verifier.message.code-url-invalid">The <strong>code</strong> parameter provided within the URL is not valid.</div>
      </mip-alert>
    </div>

    <!-- VERIFICATION ERROR -->
    <mip-alert class="__alert-message" theme="warning" *ngIf="codeError && unprocessableEntityError">
      <mip-icon icon="warning" [solid]="true"></mip-icon>
      <div>
        <span mipTranslate="login.code-verifier.form.error-message.verify-code-error">
          An error occurred while trying to verify the email. This email might have been already validated
        </span>
        <i
          >Response error: <small>{{ unprocessableEntityError }}</small></i
        >
      </div>
    </mip-alert>

    <!-- AUTH_UNVALIDATED_EMAIL ERROR -->
    <mip-alert class="__alert-message" theme="warning" *ngIf="codeError && codeErrorCode === 'AUTH_UNVALIDATED_EMAIL'">
      <mip-icon icon="warning" [solid]="true"></mip-icon>
      <div>
        <span mipTranslate="login.code-verifier.form.error-message.auth-unvalidated-email"> You hasn't validated your email. </span>
      </div>
    </mip-alert>

    <!-- USER_ALREADY_VALIDATED ERROR -->
    <mip-alert class="__alert-message" theme="warning" *ngIf="codeError && codeErrorCode === 'USER_ALREADY_VALIDATED'">
      <mip-icon icon="warning" [solid]="true"></mip-icon>
      <div>
        <span mipTranslate="login.code-verifier.form.error-message.user-already-validated">
          Your email is already validated. Try to <a [routerLink]="['/']" queryParamsHandling="merge">login</a> instead.
        </span>
      </div>
    </mip-alert>

    <!-- NEW CODE -->
    <div class="__new-code" mipBgColor="gray" *ngIf="isThereUrlCode === false || (codeError && codeErrorCode !== 'USER_ALREADY_VALIDATED')">
      <p mipTranslate="login.code-verifier.form.message.new-code" *ngIf="hasEmail">Didn't receive the verification email?</p>

      <form class="__form" *ngIf="!codeSuccess" [class.--is-disabled]="newCodeLoading || requestNewCode" [formGroup]="codeVerifierForm" (ngSubmit)="getNewCode()">
        <!-- EMAIL -->
        <mat-form-field appearance="fill" color="accent" *ngIf="!hasEmail">
          <mat-label mipTranslate="login.code-verifier.fields.email.label">Email</mat-label>
          <input matInput type="text" formControlName="email" [required]="true" [disabled]="alreadyValidated" />

          <mat-hint mipTranslate="login.code-verifier.fields.email.provide-email"> Please, provide your email so we can send the verification email. </mat-hint>

          <mat-error>
            <div *ngIf="codeVerifierForm.get('email').hasError('required')" mipTranslate="login.code-verifier.fields.email.error-message.required">You must provide the email.</div>
            <div *ngIf="codeVerifierForm.get('email').hasError('invalid')" mipTranslate="login.code-verifier.fields.email.error-message.invalid">Email is not valid.</div>
            <div *ngIf="codeVerifierForm.get('email').hasError('already-verified')" mipTranslate="login.code-verifier.fields.email.error-message.already-verified">
              This email is already validated.
            </div>
            <div *ngIf="codeVerifierForm.get('email').hasError('not-found')" mipTranslate="login.code-verifier.fields.email.error-message.already-verified">
              Email not registered.
            </div>
          </mat-error>
        </mat-form-field>

        <button
          class="__resend-email-btn mip-button--size-lg"
          mat-flat-button
          type="submit"
          mipBgColor="primary"
          [disabled]="newCodeLoading || requestNewCode"
          *ngIf="!alreadyValidated"
        >
          <mip-loading *ngIf="newCodeLoading" color="white" size="5x"></mip-loading>

          <span *ngIf="codeError" mipTranslate="login.code-verifier.form.action.resend-email">Resend email</span>
          <span *ngIf="!codeError" mipTranslate="login.code-verifier.form.action.send-email">Send email</span>

          <div class="__round-progress" *ngIf="requestNewCode">
            <round-progress
              [radius]="15"
              [stroke]="3"
              [current]="100"
              [max]="100"
              [duration]="60000"
              animation="linearEase"
              background="rgba(0, 0, 0, 0.4)"
              color="#FFF"
            ></round-progress>
          </div>
        </button>
      </form>

      <!-- ALREADY VALIDATED -->
      <mip-alert mipBdColor="primary" *ngIf="alreadyValidated">
        <div>
          <span mipTranslate="login.code-verifier.form.error-message.verify-code"> This email is already verified! </span>
          <a
            style="margin-left: 5px"
            routerLink="/"
            queryParamsHandling="merge"
            [queryParams]="{ email: codeVerifierForm.controls['email'].value }"
            mipTextColor="primary"
            mipTranslate="login.code-verifier.message.click-to-login"
            >Click here to login</a
          >.
        </div>
      </mip-alert>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { environment } from '@libs/gc-common/environments/environment';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import {
  Observable,
  Subject
} from 'rxjs';

export interface WebSocketInterface {
  command?: string;
  datetime?: string;
  in_maintenance?: boolean;
  command_type?: string;
}

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  _WS = null;
  _WSIsConnecting = false;
  _WSIsConnected = false;
  _WSIsSendingByCommand = {};

  _WSObserver = new Subject();
  _WSObservable = this._WSObserver.asObservable();

  constructor() {

  }

  connect() {
    try {

      if (utilsFactory.isBrowser) {

        this._WSIsConnecting = true;

        this._WS = new WebSocket(environment.apis.websocket);

        this._WS.onopen = () => {
          console.log(`websocket.service->connect(): WEBSOCKET IS CONNECTED`);
          this._WSIsConnecting = false;
          this._WSIsConnected = true;
        };

        this._WS.onmessage = ({data}) => {
          const response = JSON.parse(data);
          console.log(`websocket.service->connect(): onmessage() response`, response);
          delete this._WSIsSendingByCommand[response.command];
          this._WSObserver.next(response);
        };

        this._WS.onerror = (error) => {
          console.error(`websocket.service->connect(): ERROR`, error);
          this._WSObserver.error(error);
        };

        this._WS.onclose = () => {
          console.log(`websocket.service->connect(): WEBSOCKET CLOSED`);
          this._WSIsConnecting = false;
          this._WSIsConnected = false;
          this._WS = null;
          this.connect();
        };

      }

    }
    catch (e) {
      console.error(`websocket.service->constructor(): ERROR`, e);
    }
  }


  onCommand(command: string): Observable<WebSocketInterface> {

    console.log(`websocket.service->connect(): command`, command, this._WSIsSendingByCommand);

    if (utilsFactory.isBrowser) {

      if (!this._WSIsConnecting && !this._WSIsConnected) {
        this.connect();
      }

      if (!this._WSIsSendingByCommand[command]) {
        this._WSIsSendingByCommand[command] = true;

        utilsFactory.waitToBeTrue('', () => {
          return !!this._WSIsConnected;
        }).then(() => {
          console.log(`websocket.service->connect(): SEND`, command);
          this._WS.send(JSON.stringify({command}));
        });

      }
    }

    return this._WSObservable;

  }

}


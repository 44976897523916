import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { LoginService } from '@apps/gc-login/login.service';
import {
  FormHelper,
  MetaTagsService,
  RouterService,
  UserModel,
  UserService,
  utilsFactory,
} from '@lib/gc-common';
import { environment } from '@libs/gc-common/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mip-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss'],
})
export class RegisterFormComponent implements OnInit, AfterViewInit {
  @Input() disableHeader = null;
  @Input() disableLoginBtn = null;
  @Input() disableCopyright = null;

  @Output() onRegisteredSuccess = new EventEmitter();
  @Output() onRegisterError = new EventEmitter();

  registerForm = new FormHelper({
    email: new FormControl('', []),
    password: new FormControl('', []),
    rememberMe: new FormControl(true, []),
  });

  referralProfile: UserModel = null;

  showPassword = false;
  loadingRegistration = false;
  errorRegistration = false;
  errorMessage = null;
  errorAccountAlreadyExists = false;
  referralCode = null;
  noReferralCode = false;
  recaptchaBadScoreError = false;
  recaptchaErrorMessage = null;
  deviceUUID = null;
  collabToken = null;
  loginBackgroundImage = null;
  goBackReferrer = null;

  assetsPath = environment.assetsPath;
  enableSocialLogin = environment.enableSocialLogin;

  constructor(
    private userService: UserService,
    private loginService: LoginService,
    private routerService: RouterService,
    private metaTagsService: MetaTagsService,
    private translateService: TranslateService,
  ) {
    this.registerForm.disable();
    // console.allowNamespace('register-form.component');
  }

  async ngOnInit() {
    try {
      // console.log('register-form.component->ngOnInit()', this.onRegisteredSuccess);

      const { queryParams, data } = await this.routerService.getRouteObject();
      // console.log('register-form.component->ngOnInit(): { queryParams, data }', { queryParams, data });

      const { loginBackgroundImage } = data;
      // console.log('register-form.component->ngOnInit(): loginBackgroundImage', loginBackgroundImage);

      this.collabToken = queryParams.token;
      // console.log('register-form.component->ngOnInit(): this.collabToken', this.collabToken);

      if (loginBackgroundImage) {
        this.loginBackgroundImage = `url('${loginBackgroundImage.replace('{{assetsPath}}', this.assetsPath)}')`;
      }

      if (
        utilsFactory.isBrowser &&
        (['local', 'qa'].indexOf(environment.environmentName) > -1 ||
          window.localStorage.getItem('mip-force-enable-log') === 'true')
      ) {
        this.deviceUUID = this.userService.getDeviceUUID();
      }

      this.goBackReferrer = this.routerService.getGoBackReferer(environment.loginUrl);
      // console.log('register-form.component->ngOnInit():this.goBackReferrer', this.goBackReferrer);

      const currentUrl = this.routerService.getCurrentUrl();
      // console.log('register-form.component->ngOnInit(): currentUrl', currentUrl);

      if (currentUrl.indexOf('/register') > -1) {
        this.metaTagsService.setMetaTags({
          title: `Registration`,
        });
      }

      const routeParams = await this.routerService.getRouteParam();
      // console.log('register-form.component->ngOnInit(): this.disableHeader', this.disableHeader);

      const referralUser = this.userService.getReferralUser();
      // console.log('register-form.component->ngOnInit(): referralUser', referralUser);

      this.referralCode = routeParams.code || (referralUser ? referralUser.code : null);
      // console.log('register-form.component->ngOnInit(): this.referralCode', this.referralCode);

      if (!routeParams.userId && referralUser) {
        routeParams.userId = referralUser.username;
      }
      // console.log('register-form.component->ngOnInit(): routeParams.userId', routeParams.userId);

      if (!this.referralCode || !routeParams.userId) {
        this.noReferralCode = true;
        this.registerForm.disable();
        console.error('register-form.component->ngOnInit(): routeParams.userId', routeParams.userId);
        return;
      }

       // console.log('register-form.component->ngOnInit(): routeParams', routeParams);

      this.referralProfile = await this.userService.getUser(routeParams.userId);
       // console.log('register-form.component->ngOnInit(): this.referralProfile', this.referralProfile);

      if (this.referralCode !== this.referralProfile.referralCode) {
        this.noReferralCode = true;
        this.registerForm.disable();
      }
    } catch (e) {
      console.error('register-form.component->ngOnInit(): ERROR', e);

      if (e instanceof Error) {
        utilsFactory.noticeError(e);
      }

      throw e;
    }
  }

  async ngAfterViewInit() {
    try {
      if (utilsFactory.isBrowser) {
        const currentLang = this.translateService.currentLang;
        const lang = currentLang === 'pt' ? 'pt-BR' : currentLang;
        const googleRecaptchaV3Key = environment.googleRecaptchaV3Key;

        await utilsFactory.loadScript(
          `https://www.google.com/recaptcha/api.js?render=${googleRecaptchaV3Key}&lang=${lang}`,
          () => {
            return window['grecaptcha'] && window['grecaptcha'].ready;
          },
        );

        this.registerForm.enable();
      }
    } catch (e) {
      console.error('register-form.component->ngAfterViewInit(): ERROR', e);
    }
  }

  /**
   * Method to be triggered when the user submits the form
   */
  async onSubmit() {
    try {
       // console.log('register-form.component->onSubmit(): this.registerForm', this.registerForm);

      this.registerForm.markAsTouched();
      this.registerForm.markAllAsTouched();

      if (!this.registerForm.valid) {
         // console.log('register-form.component->onSubmit(): this.registerForm.valid', this.registerForm.valid);
        return false;
      }

      if (!this.referralCode) {
        this.noReferralCode = true;
      }

      const payload = this.registerForm.value;
      payload.graphDomain = 'gotchosen';
      payload.referralUser = this.referralCode;
      payload.referralUserUsername = this.referralProfile.username;

       // console.log('register-form.component->onSubmit(): payload', payload);
       // console.log('register-form.component->onSubmit(): !payload.email', !payload.email);
       // console.log('register-form.component->onSubmit(): !utilsFactory.isEmailValid(payload.email)', !utilsFactory.isEmailValid(payload.email));

      if (!payload.email || !utilsFactory.isEmailValid(payload.email)) {
        throw new Error(`email.invalid`);
      }

      if (payload.email.indexOf('@gotchosen.com') === -1) {
        for (const i in environment.registerReservedWords) {
          if (payload.email.toLowerCase().indexOf(environment.registerReservedWords[i]) > -1) {
            throw new Error(`email.reserved-name`);
          }
        }
      }

      if (!payload.password) {
        throw new Error('password.required');
      }

      if (payload.password.length < 8) {
        throw new Error('password.length');
      }

      if (!environment.passwordPattern.test(payload.password)) {
        throw new Error('password.invalid-pattern');
      }

      this.errorMessage = null;

      this.registerForm.disable();
      this.loadingRegistration = true;
      this.errorRegistration = false;
      this.errorAccountAlreadyExists = false;

      payload.recaptcha = await window['grecaptcha'].execute(environment.googleRecaptchaV3Key, { action: 'register' });
       // console.log('register-form.component->onSubmit(): payload.recaptcha', payload.recaptcha);

      if (this.collabToken) {
        payload.collabToken = this.collabToken;
      }

      const userModel = await this.loginService.registerUser(
        payload,
        true,
        this.onRegisteredSuccess.observers.length === 0,
      );

      if (this.onRegisteredSuccess.observers.length > 0) {
        this.onRegisteredSuccess.emit(userModel);
      }

      this.loadingRegistration = false;
    } catch (error) {
      console.error('register-form.component->onSubmit(): ERROR', error);

      this.onRegisterError.emit(error);

      this.registerForm.enable();
      this.loadingRegistration = false;

      if (error['message'].indexOf('bad-score') > -1) {
        this.recaptchaBadScoreError = true;
      } else if (
        environment.environmentName !== 'production' &&
        (error['message'].indexOf('missing-input-secret') > -1 ||
          error['message'].indexOf('invalid-input-secret') > -1 ||
          error['message'].indexOf('missing-input-response') > -1 ||
          error['message'].indexOf('invalid-input-response') > -1 ||
          error['message'].indexOf('bad-request') > -1 ||
          error['message'].indexOf('timeout-or-duplicate') > -1)
      ) {
        this.recaptchaErrorMessage = error['message'];
      } else if (error['form'] && error['form'].errors) {
        this.registerForm.validate(error['form'].errors.children);
      } else {
         // console.log('register-form.component->onSubmit(): ELSE');

        try {
           // console.log('register-form.component->onSubmit(): TRY');
          this.registerForm.validate(error['message']);
        } catch (e) {
           // console.log('register-form.component->onSubmit(): CATCH');

          if (error['error'] === 'user-already-registered') {
            this.registerForm.validate('email.account-already-exists');
            this.errorAccountAlreadyExists = true;
          } else if (error['error'] === 'recaptcha-failed') {
            this.registerForm.validate('recaptcha.required');
          } else if (error['message']) {
            utilsFactory.noticeError(error);
            this.translateService.get(error['message']).subscribe((message) => {
               // console.log('register-form.component->onSubmit(): translate: message', message);
              this.errorMessage = message;
            });
          } else {
            this.errorRegistration = true;
          }
        }
      }
    }
  }

  onEmailChange() {
    try {
      if (!utilsFactory.isEmailValid(this.registerForm.controls['email'].value)) {
        this.registerForm.validate('email.invalid');
      }
    } catch (e) {
      console.error('register-form.component->onEmailChange(): ERROR', e);
      throw e;
    }
  }
}

import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ContactFormComponent, FormConfigInterface } from '@libs/gc-common/lib/features/contact-form/contact-form.component';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  forms: {
    [key: string]: FormConfigInterface
  } = {
      learnMore: {
        formId: '97slMvOSwX8',
        formName: 'I would like to learn more',
        mipTranslate: 'form.name.learn-more',
        fields: [
          [
            'firstName',
            'lastName'
          ],
          'email',
          'country',
          [
            'dialCode',
            'phoneNumber',
          ],
          'companyName',
          'jobTitle',
          'website',
          'howCanWeHelp',
        ],
        requiredFields: {
          companyName: false,
          jobTitle: false
        }
      },
      help: {
        formId: '97slMvOSwX8',
        formName: 'I would like to learn more',
        mipTranslate: 'form.name.learn-more',
        fields: [
          [
            'firstName',
            'lastName'
          ],
          'email',
          'country',
          [
            'dialCode',
            'phoneNumber',
          ],
          'companyName',
          'jobTitle',
          'website',
          'howCanWeHelp',
        ],
        requiredFields: {
          companyName: false,
          jobTitle: false
        }
      }
    };

  constructor(
    private bottomSheet: MatBottomSheet
  ) {

  }

  openContactModal(formName) {

    const form = this.forms[formName];
    console.log('contact.service->openContactModal(): form', formName, form);

    this.bottomSheet.open(ContactFormComponent, { data: form });

  }

}

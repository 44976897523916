<mip-card class="device-account-card mat-elevation-z4" *ngIf="user && uuid" [class.--disabled]="user.pendingDelete">
  <mip-card-content class="--flex-row" [class.--has-index]="index !== null" [class.--has-actions]="showActions">
    <div class="__index" [mipBgColor]="user.pendingDelete ? 'gray--darker' : 'primary'" *ngIf="index !== null">{{ index }}.</div>

    <mip-avatar [source]="user.avatarUrl" [username]="user.username" [bgColor]="user.bgColor"></mip-avatar>

    <div class="__info">
      <h3 class="__username">
        <!-- since the user is not a UserModel, we cannot user the <mip-profile-slug-name> component -->
        {{ user.username }}
      </h3>
      <small class="__email">{{ user.email }}</small>
    </div>

    <!----------------- ACTIONS ------------------->
    <div class="__actions" *ngIf="showActions">
      <!--<button
        class="__action mip-button--size-lg"
        mat-button
        mipTextColor="danger"
        *ngIf="!user.userPendingDelete"
        (click)="goToConfirmationAccountPage(uuid, user.id)"
      >
        <mat-icon>delete_forever</mat-icon>
      </button>-->

      <!-- DROP-MENU: more options -->
      <button class="__action mip-button--size-lg" mat-icon-button *ngIf="!user.pendingDelete" [matMenuTriggerFor]="moreOptions">
        <mat-icon> arrow_drop_down </mat-icon>
      </button>
    </div>
  </mip-card-content>

  <mip-card-footer *ngIf="user.pendingDelete">
    <mip-alert theme="danger" *ngIf="user.userPendingDelete">
      <mip-icon icon="warning"></mip-icon>
      <div>
        <span mipTranslate="login.device-account-card.message.pending-delete-account"> <strong>Pending</strong> account deletion! </span>
      </div>
    </mip-alert>

    <mip-alert theme="warning" *ngIf="!user.userPendingDelete && user.devicePendingDelete">
      <mip-icon icon="warning"></mip-icon>
      <div>
        <span mipTranslate="login.device-account-card.message.pending-remove-device"> Account removal from this device <strong>pending</strong>! </span>
      </div>
    </mip-alert>
  </mip-card-footer>
</mip-card>

<mat-menu class="device-account-card-menu" #moreOptions="matMenu" xPosition="before" backdropClass="mip-color--bg-black--opacity-7x">
  <a
    mat-menu-item
    [disabled]="user.pendingDelete || user.devicePendingDelete"
    routerLink="/device-users/{{ uuid }}/device/{{ user.id }}"
    [skipLocationChange]="isProduction"
    queryParamsHandling="merge"
  >
    <mat-icon mipTextColor="danger"> phonelink_erase </mat-icon>
    <span class="__label" mipTranslate="login.device-users.action.unlink-account"> Unlink account from this device </span>
  </a>

  <a mat-menu-item [disabled]="user.userPendingDelete" routerLink="/device-users/{{ uuid }}/account/{{ user.id }}" [skipLocationChange]="isProduction" queryParamsHandling="merge">
    <mat-icon mipTextColor="danger"> delete_forever </mat-icon>
    <span class="__label" mipTranslate="login.device-users.action.remove-account"> Delete account permanently </span>
  </a>
</mat-menu>

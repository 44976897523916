import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mip-section-arrow,[mip-section-arrow]',
  templateUrl: './mip-section-arrow.component.html',
  styleUrls: ['./mip-section-arrow.component.scss']
})
export class MipSectionArrowComponent implements OnInit, AfterViewInit {

  @Input() bgColor = 'white';
  @Input() textColor = null;
  @Input() imagePosition = 'right';
  @Input() videoPosition = 'right';
  @Input() arrowPosition = 'left';
  @Input() hideArrow = false;
  @Input() arrowTop = false;
  @Input() arrowTopPosition = 'left';

  @Input() bgImage = null;
  @Input() bgImagePosition = 'center';
  @Input() bgImageSize = 'cover';

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

}

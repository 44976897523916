<label
  [mipBgColor]="bgMipColor"
  [mipTextColor]="textMipColor"
  [style.background-color]="bgColor"
  [style.border-color]="bdColor"
  [style.border-width]="bdSize"
  [style.padding]="padding"
  [style.color]="textColor"
  [style.font-size]="fontSize"
  [style.border-radius]="borderRadius"
  [class.--uppercase]="uppercase"
  [class.--right-square]="rightSquare"
  [class.--has-border]="bdColor"
>
  <mip-icon
    *ngIf="icon"
    [icon]="icon"
    [bold]="iconBold"
    [solid]="iconSolid"
    [size]="iconSize"
  ></mip-icon>
  <span class="__label" *ngIf="label">{{label}}</span>
  <ng-content></ng-content>
</label>

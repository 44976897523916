import { Directive } from '@angular/core';
import {
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core';

@Directive({
  selector: '[mipDatePickerFormat]',
  providers: [{
    provide: MAT_DATE_LOCALE,
    useValue: 'en-GB'
  },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'DD/MM/yy'
        },
        display: {
          dateInput: 'DD/MM/yy',
          monthYearLabel: 'MM/yy',
          dateA11yLabel: 'DD/MM/yy',
          monthYearA11yLabel: 'MM/yy'
        }
      }
    }]
})
export class DatePickerFormatDirective {

  constructor() {
  }

}

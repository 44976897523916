import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  ColorModule,
  MipAlertModule,
  MipIconModule,
  MipLoadingModule,
  MipTranslateModule
} from '@lib/gc-common';

import { LoginSocialComponent } from './login-social.component';

@NgModule({
  declarations: [LoginSocialComponent],
  exports: [LoginSocialComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    ColorModule,
    MipLoadingModule,
    MipIconModule,
    MipTranslateModule,
    MipAlertModule
  ]
})
export class LoginSocialModule {
}

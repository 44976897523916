import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';
import { ColorModule } from '@libs/gc-common/lib/directives/color/color.module';

import { MipBadgeComponent } from './mip-badge.component';

@NgModule({
  declarations: [MipBadgeComponent],
  exports: [
    MipBadgeComponent
  ],
  imports: [
    CommonModule,
    MipIconModule,
    ColorModule
  ]
})
export class MipBadgeModule {
}

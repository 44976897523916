import {
  Directive,
  ElementRef,
  Input,
  OnChanges
} from '@angular/core';

@Directive({
  selector: '[mipHideOffViewport]'
})
export class HideOffViewportDirective implements OnChanges {

  @Input() mipHideOffViewport = 0;

  listChildrenEl = [];
  nativeElement = null;
  intersectionObserver = null;

  constructor(private hostEl: ElementRef) {
    this.nativeElement = this.hostEl.nativeElement;
    console.log('hide-off-viewport.directive->constructor(): this.nativeElement', this.nativeElement);

    this.intersectionObserver = new IntersectionObserver(this.onChangeInteraction.bind(this));

  }

  ngOnChanges() {
    console.log('hide-off-viewport.directive->ngOnChanges(): this.mipHideOffViewport', this.mipHideOffViewport);

    setTimeout(() => {

      const items = this.nativeElement.querySelectorAll('[data-viewport-item]');
      console.log('hide-off-viewport.directive->ngOnChanges(): items', items);

      items.forEach(item => {
        if (this.listChildrenEl.indexOf(item) === -1) {
          this.listChildrenEl.push(item);
          this.intersectionObserver.observe(item);
        }
      });
    }, 10);

  }

  onChangeInteraction(entries) {
    console.log('hide-off-viewport.directive->onChangeInteraction(): entries', entries);
  }
}

import { Injectable } from '@angular/core';
import {
  CacheService,
  LoginRegisterUserDataInterface,
  ModelFactoryService,
  ResourceService,
  RouterService,
  UserModel,
  UserService,
  utilsFactory,
} from '@lib/gc-common';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  storageRegisterUserData: UserModel = null;
  storageRegisterData = null;
  
  constructor(
    private routerService: RouterService,
    private userService: UserService,
    private cacheService: CacheService,
    private resourceService: ResourceService,
    private modelFactory: ModelFactoryService,
  ) {
    // console.log('login.service->constructor()');
    
    if (utilsFactory.isBrowser) {
      const cacheLoginData = this.cacheService.getSession('user-login', 'login-user-data');
      // console.log('login.service->constructor(): cacheLoginData', cacheLoginData);
      
      const cacheUserData = this.cacheService.getCache('users', 'register-user-data');
      // console.log('login.service->constructor(): cacheUserData', cacheUserData);
      
      if (cacheLoginData) {
        let password = null;
        
        if (cacheLoginData.password) {
          password = utilsFactory.atob(cacheLoginData.password);
        }
        
        this.changeStorageRegisterData({
          ...cacheLoginData,
          password,
        });
        // console.log('login.service->constructor(): this.storageRegisterData', this.storageRegisterData);
      }
      
      if (cacheUserData) {
        this.changeStorageRegisterUserData(this.modelFactory.userFactory.build(cacheUserData));
        // console.log('login.service->constructor(): this.storageRegisterUserData', this.storageRegisterUserData);
      }
    }
  }
  
  /**
   * Method to deal with user registration.
   * Since the user may need to jump through multiple steps,
   * this method will persist the user information collecting
   * information on each step
   */
  async registerUser(
    data: LoginRegisterUserDataInterface,
    ignoreBirthday = false,
    goToCodeVerifier = true,
  ): Promise<UserModel> {
    // console.log('login.service->registerUser(): data', data, ignoreBirthday);
    
    // @ts-ignore
    try {
      this.changeStorageRegisterData(data, data.rememberMe ? 'save' : undefined);
      
      // check email and if is valid
      if (!data.email) {
        throw new Error('email.required');
      }
      else {
        if (!utilsFactory.isEmailValid(data.email)) {
          throw new Error('email.invalid');
        }
      }
      
      if (ignoreBirthday !== true) {
        // check if birthday was provided
        if (!data.birthday) {
          throw new Error('birthday.required');
        }
        else {
          const diffDates = moment().diff(moment(data.birthday), 'years');
          // console.log('login.service->registerUser(): diffDates', diffDates);
          
          if (diffDates < 13) {
            // console.log('login.service->registerUser(): Age under 13.');
            throw new Error('birthday.under-age');
          }
        }
      }
      
      if (!data.recaptcha) {
        throw new Error(`recaptcha.required`);
      }
      
      // console.log('login.service->registerUser(): data', data);
      
      await this.userService.logout(false, false);
      // console.log('login.service->registerUser(): logout()');
      
      const routeData = await this.routerService.getRouteDataParam();
      // console.log('login.service->registerUser(): routeData', routeData);
      
      this.changeStorageRegisterData(data, data.rememberMe ? 'save' : undefined);
      
      const userModel = await this.userService.registerUser(data);
      // console.log('login.service->registerUser(): registerUser()', userModel);
      
      if (userModel) {
        this.changeStorageRegisterUserData(userModel);
        
        if (goToCodeVerifier) {
          // console.log('login.service->registerUser(): Redirecting to ${loginBasePath}/code-verifier');
          await this.routerService.navigateTo(`/code-verifier`, {
            queryParams: {
              email: data.email,
            },
          });
        }
        
        return userModel;
      }
      else {
        throw new Error(`'userModel' is not defined`);
      }
    }
    catch (e) {
      console.error('login.service->registerUser(): ERROR', e);
      
      if (e && e['code']) {
        const deviceUUID = this.userService.getDeviceUUID();
        
        if (e['code'] === 'DEVICE_REACHED_USERS_THRESHOLD' && deviceUUID) {
          await this.routerService.navigateTo(`/device-users/${deviceUUID}`, {
            redirectPath: '/register',
          });
        }
      }
      
      this.changeStorageRegisterData(null, 'remove');
      
      throw e;
    }
  }
  
  /**
   * Method to deal with the user login
   */
  async login(data: LoginRegisterUserDataInterface, redirectUrl = true): Promise<UserModel> {
    try {
      // console.log('login.service->login(): data', data);
      
      if (!data.credentials) {
        // console.log('login.service->login(): data.username', data.username);
        // console.log('login.service->login(): data.email', data.email);
        
        if (!data.token) {
          if (!data.username && !data.email) {
            throw new Error('username.required');
          }
          
          if (data.username) {
            if (data.username.indexOf('@') > -1 && !utilsFactory.isEmailValid(data.username)) {
              throw new Error('username.invalid');
            }
          }
          else {
            if (!utilsFactory.isEmailValid(data.email)) {
              throw new Error('email.invalid');
            }
          }
          
          // console.log('login.service->login(): data.password', data.password);
          
          if (!data.password) {
            throw new Error('password.required');
          }
          
          await this.userService.logout(false);
          
          // console.log('login.service->login(): data', data);
          this.changeStorageRegisterData(data, data.rememberMe ? 'save' : 'remove');
        }
      }
      
      const userModel = await this.userService.login(data);
      // console.log('login.service->login(): userModel', userModel);
      
      // if (userModel.isActive) {
      this.changeStorageRegisterUserData(userModel, true);
      // }
      
      if (redirectUrl) {
        const birthday = userModel.getBirthday();
        // console.log('login.service->login(): birthday', birthday);
        
        if (!birthday || (birthday && birthday.split('-') < 3)) {
          await this.routerService.navigateTo(`/birthday`);
          throw new Error('birthday.invalid');
        }
        
        const gender = userModel.getGender();
        
        if (!gender) {
          await this.routerService.navigateTo(`/gender`);
          throw new Error('gender.invalid');
        }
      }
      
      if (userModel.isActive) {
        await this.userService.setAuthentication(userModel);
      }
      
      // console.log('login.service->login(): [response]', userModel);
      return userModel;
    }
    catch (e) {
      console.error('login.service->login(): ERROR', e);
      // console.log('login.service->login(): getDeviceUUID()', this.userService.getDeviceUUID());
      
      if (e && e['code']) {
        const deviceUUID = this.userService.getDeviceUUID();
        
        if (e['code'] === 'DEVICE_REACHED_USERS_THRESHOLD' && deviceUUID) {
          await this.routerService.navigateTo(`/device-users/${deviceUUID}`, {
            redirectPath: '/',
          });
        }
      }
      
      throw e;
    }
  }
  
  /**
   * Method to verify a token code sent to the user email
   */
  async codeVerify(data: { email; code }): Promise<UserModel> {
    try {
      // console.log('login.service->codeVerify(): data', data);
      
      const routeData = await this.routerService.getRouteDataParam();
      // console.log('login.service->codeVerify(): routeData', routeData);
      
      // is there a code value
      if (!data.code) {
        throw new Error('code.required');
      }
      
      // is the code value length greater then 6 and smaller then 6
      if (data.code.toString().length < 6 || data.code.toString().length > 6) {
        throw new Error('code.invalid');
      }
      
      // check email and if is valid
      if (!data.email) {
        throw new Error('email.required');
      }
      else if (!utilsFactory.isEmailValid(data.email)) {
        throw new Error('email.invalid');
      }
      
      // console.log('login.service->codeVerify(): data', data);
      
      const userModel = await this.userService.validateCode(data);
      // console.log('login.service->codeVerify(): userModel', userModel);
      
      return userModel;
    }
    catch (e) {
      throw e;
    }
  }
  
  /**
   * Method to steart the forgotPassword
   */
  async forgotPassword(email: string) {
    try {
      // console.log('login.service->forgotPassword(): email', email);
      
      if (!utilsFactory.isEmailValid(email)) {
        throw new Error('email.invalid');
      }
      
      await this.userService.forgotPassword(email);
      
      this.changeStorageRegisterData({ email }, 'save');
      
      await this.routerService.navigateTo(`/change-password`, {
        queryParams: { email: null },
      });
    }
    catch (e) {
      throw e;
    }
  }
  
  /**
   * Method to send a new validation token to the users email
   */
  async getNewValidationToken(email) {
    try {
      return await this.resourceService.post('api', {
        path: `/user/resend-validation-token`,
        data: { email },
      });
    }
    catch (e) {
      throw e;
    }
  }
  
  /**
   * Method to deal with the "storageRegisterData" in one place
   */
  changeStorageRegisterData(data, cache?: string) {
    // console.log('login.service->changeStorageRegisterData(): data', { cache, data }, this.storageRegisterData);
    try {
      if (!this.storageRegisterData) {
        this.storageRegisterData = {};
      }
      
      this.storageRegisterData = {
        ...this.storageRegisterData,
        ...data,
      };
      // console.log('login.service->changeStorageRegisterData(): this.storageRegisterData', this.storageRegisterData);
      
      if (cache === 'save') {
        this.cacheService.setSession(
          'user-login',
          'login-user-data',
          {
            ...this.storageRegisterData,
            ...(this.storageRegisterData.password
              ? {
                password: utilsFactory.btoa(this.storageRegisterData.password),
              }
              : {}), // converting the password to base64
          },
          {
            days: 365,
          },
        );
      }
      else if (cache === 'remove') {
        this.storageRegisterData = null;
        this.cacheService.removeSession('user-login', 'login-user-data');
      }
      
    }
    catch (e) {
      throw e;
    }
  }
  
  /**
   * Method to deal with the "changeStorageRegisterUserData" in one place
   */
  changeStorageRegisterUserData(data: UserModel, saveToCache = true) {
    // console.log('login.service->changeStorageRegisterUserData(): data', data);
    try {
      this.storageRegisterUserData = data;
      
      // console.log('login.service->changeStorageRegisterData(): this.storageRegisterUserData', this.storageRegisterUserData);
      
      if (saveToCache) {
        this.cacheService.setCache('users', 'register-user-data', this.storageRegisterUserData, { days: 365 });
      }
    }
    catch (e) {
      throw e;
    }
  }
}

import {
  Pipe,
  PipeTransform
} from '@angular/core';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';

@Pipe({
  name: 'linkify'
})
export class LinkifyPipe implements PipeTransform {

  constructor(
    private routerService: RouterService
  ) {
  }

  transform(link: string): string {
    return this.linkify(link);
  }

  private linkify(plainText): string {

    const rxg = /((@|#)[a-zA-Z0-9áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\-\_\.\+]+)/g;
    const replacedText: Array<string> = plainText.match(rxg);
    console.log('linkify.pipe->linkify(): replacedText', replacedText);

    // tslint:disable-next-line:forin
    for (const i in replacedText) {
      const link = document.createElement('a');
      link.href = replacedText[i];
      link.innerText = replacedText[i];
      link.onclick = () => {
        console.log('linkify.pipe->linkify(): link.href', link.href);
        this.routerService.navigateTo(link.href);
      };
      console.log('linkify.pipe->linkify(): link', link);
    }

    /*let replacedText;
     let replacePattern1;
     let replacePattern2;
     let replacePattern3;
     let

     // URLs starting with http://, https://, or ftp://
     replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
     replacedText = plainText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

     // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
     replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
     replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

     // Change email addresses to mailto:: links.
     replacePattern3 = /(@[a-zA-Z0-9\-\_\.\+]*)/gim;
     replacedText = replacedText.replace(replacePattern3, '<a href="$1">$1</a>');

     console.log('linkify.pipe->linkify(): replacedText', replacedText);*/
    return plainText;
  }

}

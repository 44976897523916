import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { environment } from '@libs/gc-common/environments/environment';
import { UserService } from '@libs/gc-common/lib/api/services/user/user.service';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';

@Injectable({
  providedIn: 'root'
})
export class IsModeratorGuard implements CanActivate {

  constructor(
    private userService: UserService,
    private router: Router,
    private routerService: RouterService
  ) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    console.log('is-moderator.guard->canActivate() state.url', state.url);

    this.routerService.setActiveRoute(route);

    const routerData = await this.routerService.getRouteDataParam();
    console.log('is-moderator.guard->canActivate() routerData', routerData);

    const loggedInUser = await this.userService.getLoginUser();
    console.log('is-moderator.guard->canActivate() loggedInUser', loggedInUser);

    if (loggedInUser.isModerator) {
      return true;
    }
    else {

      this.routerService.registerGoBackReferer({
        path: environment.loginUrl,
        redirectUrl: state.url
      });

      document.location = environment.loginUrl;
      // return this.router.parseUrl(routerData.baseUrl ? routerData.baseUrl : '/login');

      return false;

    }

  }
}

<header class="header mip-color--bg-dark" [style.background-image]="'url(' + loginBackgroundImage + ')'">
  <a class="__go-back" mat-button routerLink="/" queryParamsHandling="merge">
    <mip-icon icon="arrow-left" size="2x"></mip-icon>
  </a>

  <div class="__inner">
    <div class="--flex-column --flex-column--center">
      <strong class="__text" mipTranslate="birthday-form.header.title">Birthday</strong>
      <img class="__logo" src="{{ assetsPath }}/images/gc-logo-white.png" />
    </div>
  </div>
</header>

<div class="content">
  <div class="__inner">
    <div class="__user-card">
      <mip-avatar [userModel]="userModel" [size]="40"></mip-avatar>
      <div class="__info">
        <div *ngIf="userModel?.name">{{ userModel.name }}</div>
        <mip-profile-slug-name [user]="userModel"></mip-profile-slug-name>
      </div>
    </div>

    <mip-alert class="__alert-message" textAlign="center">
      <div>You must provide your date of birth to continue!</div>
    </mip-alert>

    <form class="__form" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
      <!-- BIRTHDAY -->
      <mat-form-field appearance="fill" color="accent">
        <mat-label mipTranslate="register-form.fields.birthday.label">Birthday</mat-label>
        <span matPrefix><mip-icon icon="calendar" size="1x"></mip-icon></span>
        <input matInput type="date" formControlName="birthday" [required]="true" />

        <mat-error>
          <span *ngIf="registerForm.get('birthday').hasError('required')" mipTranslate="register-form.fields.birthday.error-message.required">
            You must provide your birthday.
          </span>
          <span *ngIf="registerForm.get('birthday').hasError('under-age')" mipTranslate="register-form.fields.birthday.error-message.underAge">
            You must be 13 years old or older.
          </span>
        </mat-error>
      </mat-form-field>

      <!-- SUBMIT -->
      <button class="__submit-button mip-button--size-md mip-color--bg-primary" mat-flat-button type="submit" [disabled]="loadingSettings || !registerForm.get('birthday').value">
        <mip-icon icon="refresh" [spin]="true" *ngIf="loadingSettings"></mip-icon>
        <span mipTranslate="register-form.actions.please-wait" *ngIf="loadingSettings">Please wait...</span>
        <span mipTranslate="register-form.actions.continue" *ngIf="!loadingSettings">Continue</span>
      </button>

      <mip-alert class="__alert-message" theme="danger" *ngIf="errorSettings">
        <mip-icon icon="warning" [solid]="true"></mip-icon>
        <p mipTranslate="register-form.error-message.settings-error">There was an error while trying to save your birthday.</p>
      </mip-alert>
    </form>
  </div>
</div>

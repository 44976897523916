import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import tippy, { followCursor } from 'tippy.js';

import { MipTooltipComponent } from './mip-tooltip.component';

interface TippyInterceptor {
  content?: string;
  interactive?: boolean;
  followCursor?: boolean | 'horizontal' | 'vertical' | 'initial';
  trigger?: 'click' | 'focus' | 'mouseenter';
  arrow?: boolean | 'round' | 'large' | 'small' | 'wide' | 'narrow';
  placement?: 'top' | 'top-start' | 'top-end' | 'right' | 'right-start' | 'right-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'left' | 'left-start' | 'left-end';
  animation?: string;
  plugins?: Array<any>;
}

@Directive({
  selector: '[mipTooltip]'
})
export class MipTooltipDirective implements AfterViewInit, OnChanges {

  @Input() mipTooltip = null;
  @Input() disabledTooltip = false;

  @Input() content: string = null;
  @Input() interactive: boolean = null;
  @Input() followCursor: boolean | 'horizontal' | 'vertical' | 'initial' = null;
  @Input() trigger: 'click' | 'focus' | 'mouseenter' = null;
  @Input() arrow: boolean | 'round' | 'large' | 'small' | 'wide' | 'narrow' = null;
  @Input() placement: 'top' | 'top-start' | 'top-end' | 'right' | 'right-start' | 'right-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'left' | 'left-start' | 'left-end' = null;
  @Input() animation: string = null;
  @Input() plugins: Array<any> = null;

  _tippy = null;

  constructor(
    private host: ElementRef
  ) {

  }

  async ngOnChanges(changes: SimpleChanges) {
    console.log(`mip-tooltip.directive->ngOnChanges(): changes`, changes);

    if ('disabledTooltip' in changes) {

      await utilsFactory.waitToBeTrue('', () => !!this._tippy);

      console.log(`mip-tooltip.directive->ngOnChanges(): this._tippy`, this._tippy);
      console.log(`mip-tooltip.directive->ngOnChanges(): this.disabledTooltip`, this.disabledTooltip);

      if (this.disabledTooltip) {
        this._tippy.disable();
      }
      else {
        this._tippy.enable();
      }
    }
  }

  async ngAfterViewInit() {

    const trigger = this.host.nativeElement;
    console.log(`mip-tooltip.directive->ngAfterViewInit(): trigger`, trigger);

    const settings: TippyInterceptor = {};

    settings.content = this.mipTooltip;
    console.log(`mip-tooltip.directive->ngAfterViewInit(): settings.content`, settings.content);

    if (this.interactive) {
      settings.interactive = this.interactive || true;
    }

    if (this.followCursor) {
      settings.followCursor = this.followCursor;
      settings.plugins.push(followCursor);
    }

    if (this.trigger) {
      settings.trigger = this.trigger;
    }

    if (this.arrow) {
      settings.arrow = this.arrow;
    }

    if (this.placement) {
      settings.placement = this.placement;
    }

    if (this.animation) {
      settings.animation = this.animation || 'shift-away';
    }

    if (this.mipTooltip instanceof MipTooltipComponent) {
      console.log(`mip-tooltip.directive->ngAfterViewInit(): this.mipTooltip`, this.mipTooltip);
      this._tippy = await this.mipTooltip._startTippy(trigger, settings);
    }
    else {
      console.log(`mip-tooltip.directive->ngAfterViewInit(): settings`, settings);
      this._tippy = tippy(trigger, settings);
    }

  }

  show() {
    this._tippy.show();
  }

  hide() {
    this._tippy.hide();
  }

}

import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { HashtagModel } from '@libs/gc-common/lib/api/models/hashtag';
import { PostModel } from '@libs/gc-common/lib/api/models/post';
import { PostsService } from '@libs/gc-common/lib/api/services/posts/posts.service';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { DownloadAppModalComponent } from '@libs/gc-common/lib/features/download-app/download-app-modal.component';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';
import { TranslateService } from '@ngx-translate/core';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'mip-slide-posts-preview-details',
  templateUrl: './slide-posts-preview-details.component.html',
  styleUrls: ['./slide-posts-preview-details.component.scss']
})
export class SlidePostsPreviewDetailsComponent implements OnInit {

  @Input() isLoading = false;
  @Input() hashtagName: string = null;
  @Input() hashtagModel: HashtagModel = null;
  @Input() hashtagPosts: PostModel[] = [];

  @Input() showSeeMore = false;

  @Input() showCreatePost = false;
  @Input() createPostSlideBgImage = 'https://cdn.gotchosen.com/mip/ssr/assets/images/top10-video-card.jpg';
  @Input() createPostSlideTitle = 'slide-posts-preview-details.last-slide.title';
  @Input() createPostSlideDescription = 'slide-posts-preview-details.last-slide.description';
  @Input() createPostSlideActionLabel = 'slide-posts-preview-details.action.create-post';

  posts = [];

  config: SwiperOptions = {
    slidesPerView: 'auto',
    autoHeight: true,
    navigation: {
      prevEl: '.__slide-posts-preview.__swiper-button-prev',
      nextEl: '.__slide-posts-preview.__swiper-button-next',
      disabledClass: '--disabled'
    }
  };

  isMobile = true;
  isDesktop = false;

  index = 0;

  constructor(
    private postsService: PostsService,
    private translate: TranslateService,
    private routerService: RouterService,
    private bottomSheet: MatBottomSheet,
    private mobileDetect: MobileDetectService
  ) {
    this.isMobile = this.mobileDetect.isMobile();
    this.isDesktop = !this.mobileDetect.isMobile();
  }

  ngOnInit() {
    console.log('slide-posts-preview-details.component->ngOnInit(): this.hashtagDrawing', this.hashtagName);
  }

  async openCreatePost() {
    if (this.hashtagModel) {
      if (await utilsFactory.isWebView()) {
        console.log('slide-posts-preview-details.component->ngOnChanges(): isWebView()', `/hashtag/${this.hashtagName}`);
        this.routerService.navigateTo(`/hashtag/${this.hashtagModel.hashtag}`);
      }
      else {
        console.log('slide-posts-preview-details.component->ngOnChanges(): bottomSheet');
        this.bottomSheet.open(DownloadAppModalComponent);
      }
    }
  }

}

<mip-modal [size]="isMobile ? 'full' : modalSize" [fullHeight]="isMobile">
  <mip-card>
    <mip-card-content class="router-outlet --no-padding" [class.--is-desktop]="isDesktop">
      <a class="__close-button" mat-button [class.--dark]="darkCloseButton" [href]="closeRedirectPath">
        <mip-icon icon="times"></mip-icon>
      </a>

      <router-outlet></router-outlet>
    </mip-card-content>
  </mip-card>
</mip-modal>

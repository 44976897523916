<form (ngSubmit)="onSubmit()" [formGroup]="loginForm">
  <!-- MODAL -->
  <mip-modal [class.--is-desktop]="isDesktop" [fullHeight]="false" [size]="isDesktop ? 'md' : 'full'" class="content" maxWidth="400px">
    <!-- MODAL -->
    <mip-card>
      <mip-toolbar [noBackground]="true" [noDropShadow]="true" class="__header">
        <a mat-button mipTextColor="primary" routerLink="device-users/{{ uuid }}" queryParamsHandling="merge">
          <mip-icon icon="arrow-left"></mip-icon>
        </a>

        <button (click)="closeModal()" mat-icon-button type="button">
          <mip-icon icon="times"></mip-icon>
        </button>
      </mip-toolbar>

      <!-- section MODAL: header -->
      <mip-card-header *ngIf="user" class="__card-header">
        <header *ngIf="!user.userPendingDelete" mip-alert>
          <mat-icon class="gc-icon--size-10x" mipTextColor="danger"> delete_forever </mat-icon>
          <div>
            <h2 mipTranslate="login.confirm-account-deletion.header.title">Confirm account deletion</h2>
          </div>
          <mip-alert-footer mipTranslate="login.confirm-account-deletion.header.message">
            The data of this account will be deleted from the platform permanently and cannot be recovered!
          </mip-alert-footer>
        </header>

        <header *ngIf="user.userPendingDelete" mip-alert>
          <mat-icon class="gc-icon--size-10x" mipTextColor="danger"> delete_forever </mat-icon>
          <div>
            <h2 mipTranslate="login.confirm-account-deletion.header.title2">Account deletion</h2>
          </div>
          <mip-alert-footer mipTranslate="login.confirm-account-deletion.header.message2">
            This account has entered the process to be deleted within the next 24 hours.
          </mip-alert-footer>
        </header>

        <mip-divider mipColor="gray"></mip-divider>
      </mip-card-header>

      <!-- section MODAL: loading -->
      <mip-card-content *ngIf="isLoadingUsers" class="__card-content --loading">
        <mip-loading></mip-loading>
      </mip-card-content>

      <!-- section MODAL: content -->
      <mip-card-content *ngIf="user" class="__card-content">
        <div class="__content-header">
          <mip-alert textAlign="center">
            <mip-icon icon="lock"></mip-icon>
            <div mipTranslate="login.confirm-account-deletion.content.provide-password">Enter your password to confirm.</div>
          </mip-alert>
        </div>

        <div class="__form-content">
          <mip-device-account-card [user]="user"></mip-device-account-card>

          <mip-alert *ngIf="errorInvalidCredentials" class="__header-alert" theme="danger">
            <mip-icon icon="warning"></mip-icon>
            <div mipTranslate="login.confirm-account-deletion.error.password-invalid">The password provided is not valid!</div>
          </mip-alert>

          <!-- FIELDS-CONTAINER -->
          <div *ngIf="!user.userPendingDelete" class="__fields-container">
            <mip-alert class="__password-alert" theme="info">
              <!--<mip-icon icon="dash-faq" size="6x"></mip-icon>-->
              <span mipTranslate="login.confirm-account-deletion.fields.password.social-login-tip">
                If you have registered using a <strong>Social Network</strong> (like Google for example), click on <strong>"Forgot?"</strong> to create a password!
              </span>
            </mip-alert>

            <!-- PASSWORD -->
            <mat-form-field appearance="fill" color="accent">
              <mat-label mipTranslate="login-form.fields.password.label"> Password </mat-label>
              <span matPrefix><mip-icon icon="lock" size="1x"></mip-icon></span>
              <input [required]="true" [type]="showPassword ? 'text' : 'password'" formControlName="password" matInput />
              <a (click)="togglePasswordVisibility()" [class.mip-color--text-primary]="showPassword" mat-button matSuffix>
                <mip-icon [solid]="true" icon="eye" size="1x"></mip-icon>
              </a>
              <a
                [mipGoBackReferrer]="true"
                [routerLink]="['/', 'forgot-password']"
                queryParamsHandling="merge"
                mat-button
                matSuffix
                mipTranslate="login-form.fields.password.add-on.label"
              >
                Forgot?
              </a>

              <!-- FIELD ERRORS -->
              <mat-error>
                <span *ngIf="loginForm.get('password').hasError('required')" mipTranslate="login-form.fields.password.error-message.required">
                  You must provide your password.
                </span>
                <span *ngIf="loginForm.get('password').hasError('credentials-not-valid')" mipTranslate="login-form.fields.password.error-message.invalid">
                  The password provided is not valid.
                </span>
              </mat-error>
            </mat-form-field>

            <!-- RECAPTCHA -->
            <mip-recaptcha></mip-recaptcha>
          </div>
        </div>

        <mip-alert *ngIf="recaptchaBadScoreError" class="__alert-message" theme="danger">
          <mip-icon [solid]="true" icon="warning" size="7x"></mip-icon>
          <span mipTranslate="register-form.error-message.suspicious-behavior-error">
            <strong>Suspicious behavior detected!</strong> Please try again later or send us an email to
            <a href="mailto:contact@gotchosen.com" target="_blank">contact@gotchosen.com</a>
          </span>
        </mip-alert>

        <mip-alert *ngIf="recaptchaErrorMessage" class="__alert-message" theme="danger">
          <mip-icon [solid]="true" icon="warning" size="7x"></mip-icon>
          <span>{{ recaptchaErrorMessage }}</span>
        </mip-alert>
      </mip-card-content>

      <!-- section MODAL: footer -->
      <mip-card-footer *ngIf="user" class="__card-footer">
        <button
          *ngIf="!user.userPendingDelete"
          [disabled]="deletingLogin || loginForm.disabled"
          class="__submit-button mip-button--size-md"
          mat-raised-button
          mipBgColor="primary"
          type="submit"
        >
          <mip-icon *ngIf="deletingLogin" [spin]="true" icon="refresh"></mip-icon>
          <span *ngIf="deletingLogin" mipTranslate="login.confirm-account-deletion.action.processing"> Processing... </span>
          <span *ngIf="!deletingLogin" mipTranslate="login.confirm-account-deletion.action.delete-account"> Delete account </span>
        </button>

        <a
          *ngIf="user.userPendingDelete"
          class="__submit-button mip-button--size-md --icon-aside"
          mat-raised-button
          mipBgColor="primary"
          routerLink="/device-users/{{ uuid }}"
          queryParamsHandling="merge"
        >
          <mip-icon icon="chevron-left"></mip-icon>
          <span mipTranslate="login.confirm-account-deletion.action.see-other-accounts">See other accounts</span>
        </a>
      </mip-card-footer>
    </mip-card>
  </mip-modal>
</form>

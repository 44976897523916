import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { PostModel } from '@libs/gc-common/lib/api/models/post';
import { PostsService } from '@libs/gc-common/lib/api/services/posts/posts.service';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { DownloadAppService } from '@libs/gc-common/lib/features/download-app/download-app.service';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';

@Component({
  selector: 'mip-top-ten-carousel',
  templateUrl: './top-ten-carousel.component.html',
  styleUrls: ['./top-ten-carousel.component.scss']
})
export class TopTenCarouselComponent implements OnInit {

  @Input() isLoading = false;
  @Input() posts: Array<PostModel> = [];

  @Input() showFirstFlag = false;

  @Input() hashtagPrefix: string = null;
  @Input() hashtagName: string = null;
  @Input() titleRouterLink: string | Array<string> = null;

  @Input() description: string = null;

  @Input() postBasePath = '/hashtag';

  @Input() showCreatePostSlide = false;
  @Input() showSeeMorePostsSlide = false;

  index = 0;

  isMobile = true;
  isDesktop = false;

  config = {
    // slidesPerView: 'auto',
    autoHeight: true,
    navigation: {
      prevEl: '.__swiper-button-prev',
      nextEl: '.__swiper-button-next',
      disabledClass: '--disabled'
    }
  };

  constructor(
    private mobileDetect: MobileDetectService,
    private postsService: PostsService,
    private routerService: RouterService,
    private downloadAppService: DownloadAppService
  ) {
    this.isMobile = this.mobileDetect.isMobile();
    this.isDesktop = !this.mobileDetect.isMobile();
  }

  async ngOnInit() {

    try {

      if (!this.hashtagName) {
        throw new Error(`The 'hashtagName' must be provided`);
      }

      if (!this.posts.length) {
        this.isLoading = true;

        this.posts = await this.postsService.getHashtagTop10Posts(this.hashtagName);
        console.log('top-ten-carousel.component->ngOnInit(): this.posts', this.posts);

        this.isLoading = false;
      }
    }
    catch (e) {
      throw e;
    }

  }

  async openCreatePost() {
    if (await utilsFactory.isWebView()) {
      this.routerService.navigateTo(`/hashtag/${this.hashtagName}`);
    }
    else {
      this.downloadAppService.openModal();
    }
  }

}

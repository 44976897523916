<!-- MODAL -->
<mip-modal class="content" [size]="isDesktop ? 'md' : 'full'" maxWidth="400px" [class.--is-desktop]="isDesktop" [fullHeight]="false">
  <!-- MODAL -->
  <mip-card>
    <mip-toolbar class="__header" [noDropShadow]="true" [noBackground]="true">
      <span></span>

      <button mat-icon-button (click)="closeModal()">
        <mip-icon icon="times"></mip-icon>
      </button>
    </mip-toolbar>

    <!-- section MODAL: header -->
    <mip-card-header class="__card-header" [dropShadow]="true">
      <header mip-alert>
        <mip-icon icon="warning" mipTextColor="warning" size="10x" [solid]="true"></mip-icon>
        <div>
          <h2 mipTranslate="login.device-users.header.title">Warning</h2>
          <p mipTranslate="login.device-users.header.message">This device has exceeded the maximum quota of 5 accounts allowed.</p>
        </div>
      </header>

      <mip-divider mipColor="gray"></mip-divider>

      <p mipTranslate="login.device-users.header.message2">You need to delete the exceeded accounts or unlink them from this device to gain access again.</p>

      <mip-alert class="__24h-alert">
        <mat-icon class="gc-icon--size-3x">emergency</mat-icon>
        <div>
          <small mipTranslate="login.device-users.header.message3"> This process can take up to 48 hours </small>
        </div>
      </mip-alert>
    </mip-card-header>

    <!-- section MODAL: loading -->
    <mip-card-content class="__card-content --loading" *ngIf="isLoadingUsers">
      <mip-loading></mip-loading>
    </mip-card-content>

    <!-- section MODAL: content -->
    <mip-card-content class="__card-content" *ngIf="users.length">
      <mip-device-account-card
        [user]="user"
        [index]="$index + 1"
        [showActions]="true"
        *ngFor="let user of users | orderBy : ['pendingDelete', 'userPendingDelete']; let $index = index"
      ></mip-device-account-card>
    </mip-card-content>

    <mip-card-content class="__card-content --error" *ngIf="hasErrors">
      <div class="__error-icon">
        <mip-icon icon="gc-hand" size="10x"></mip-icon>
      </div>
      <div class="__error-text" mipTranslate="login.device-users.message.error">You haven't reached the user limit for this device!</div>
    </mip-card-content>

    <!-- section MODAL: footer -->
    <mip-card-footer class="__card-footer --flex-right">
      <button mat-button (click)="closeModal()" mipTranslate="login.device-users.action.close">Close</button>
    </mip-card-footer>
  </mip-card>
</mip-modal>

<router-outlet></router-outlet>

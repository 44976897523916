import {
  Pipe,
  PipeTransform
} from '@angular/core';

@Pipe({
  name: 'parseInt'
})
export class ParseIntPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    return parseInt(value);
  }

}

import { Injectable } from '@angular/core';
import { environment } from '@libs/gc-common/environments/environment';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { CacheService } from '@libs/gc-common/lib/services/cache/cache.service';
import moment from 'moment';

export interface FacebookCredentialsInterface {
  accessToken: string;
  data_access_expiration_time: number;
  expiresIn: number;
  graphDomain: string;
  signedRequest: string;
  userID: string;
}

export interface FacebookUserDataInterface {
  id: string;
  first_name: string;
  last_name: string;
  email?: string;
  birthday?: string;
  picture?: string;
}

export interface FacebookPagesDataInterface {
  access_token: string;
  id: string;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class FacebookService {

  _credentials: FacebookCredentialsInterface;
  _me: FacebookUserDataInterface;
  _myPages: FacebookPagesDataInterface[];

  constructor(private cacheService: CacheService) {
    try {

      if (utilsFactory.isBrowser) {

        this._credentials = this.cacheService.getCache('facebook-api', 'credentials');
        console.log('ffacebook.service->constructor(): this._credentials', this._credentials);

        this._me = this.cacheService.getCache('facebook-api', 'me');
        console.log('ffacebook.service->constructor(): this._me', this._me);

      }

    }
    catch (e) {
      console.log(e);
    }
  }

  getCredentials() {
    if (this._credentials) {
      return this._credentials;
    }
  }

  /**
   * Method to load the FB JS SDK
   */
  async loadFacebookScript() {
    console.log('facebook.service->loadFacebookScript()');
    try {

      window['fbAsyncInit'] = () => {
        window['FB'].init({
          appId: environment.facebookAppId,
          xfbml: true,
          version: 'v19.0'
        });
      };

      await utilsFactory.loadScript('https://connect.facebook.net/en_US/sdk.js', () => {
        console.log('facebook.service->loadFacebookScript(): persistence', 'FB' in window);
        return 'FB' in window;
      }, {
        defer: true,
        async: true,
        crossOrigin: 'anonymous'
      });

      return window['FB'];

    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to return the FB api
   */
  async FB() {
    console.log('facebook.service->FB()');
    try {
      if ('FB' in window) {
        console.log('facebook.service->FB(): from window');
        return window['FB'];
      }
      else {
        console.log('facebook.service->FB(): from loading');
        return await this.loadFacebookScript();
      }
    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to return the /me info
   */
  async getMe(): Promise<FacebookUserDataInterface> {
    console.log('facebook.service->getMe()');
    return new Promise<FacebookUserDataInterface>((resolve, reject) => {
      try {

        if (this._me) {
          resolve(this._me);
        }
        else {

          if (!this._credentials) {
            throw new Error(`You must login with Facebook first`);
          }

          this.FB().then(FB => {
            // const credentials = await this.login();
            
            FB.api('/me', {
              access_token: this._credentials ? this._credentials.accessToken : undefined,
              fields: 'first_name,last_name,picture' // removing the "user_birthday" for now because need special permission (needs to apply)
            }, user => {

              try {

                console.log('facebook.service->getMe(): user', user);

                if (user.error) {
                  throw new Error(user.error.message);
                }

                if (user.email && user.email.indexOf('@facebook') > -1) {
                  console.log('facebook.service->getMe(): Email is @facebook - Removing it');
                  user.email = null;
                }

                if (user.birthday) {
                  user.birthday = moment(user.birthday).format('YYYY-MM-DD');
                }

                if (user.picture.data) {
                  user.picture = user.picture.data.url;
                }

                this._me = user;
                this.cacheService.setCache('facebook-api', 'me', this._me);

                resolve(user);

              }
              catch (e) {
                console.error('facebook.service->getMe(): user', e);
                reject(e);
              }

            });
          });
        }
      }
      catch (e) {
        console.error('facebook.service->getMe(): catch->ERROR', e);
        reject(e);
      }
    });
  }

  /**
   * Method to make the login with facebook
   */
  async login(): Promise<FacebookCredentialsInterface> {
    console.log('facebook.service->login()');
    return new Promise<FacebookCredentialsInterface>(async (resolve, reject) => {
      try {

        if (this._credentials) {
          resolve(this._credentials);
        }
        else {

          const FB = await this.FB();

          FB.login(
            (loginResponse: {
              status: string,
              authResponse: FacebookCredentialsInterface
            }) => {
              console.log('facebook.service->login(): loginResponse', loginResponse);

              if (!loginResponse.authResponse) {
                throw new Error('The user has cancel or did not give the permission');
              }

              this._credentials = loginResponse.authResponse;
              this.cacheService.setCache('facebook-api', 'credentials', this._credentials, { days: 30 });

              if (loginResponse.status === 'connected') {
                console.log('facebook.service->login(): loginResponse', loginResponse);
                resolve(loginResponse.authResponse);
              }
              else {
                reject(null);
              }
            }, { scope: 'public_profile,pages_show_list,instagram_manage_insights,business_management,pages_read_engagement' }); // removing the "user_birthday" for now because need special permission (needs to apply)

        }
      }
      catch (e) {
        reject(e);
      }
    });
  }

  /**
   * Method to logout from facebook
   */
  async logout() {
    console.log('facebook.service->logout()');
    return new Promise(async (resolve, reject) => {
      try {

        this._credentials = null;
        this._me = null;
        this._myPages = null;

        this.cacheService.removeCache('facebook-api', 'credentials');
        this.cacheService.removeCache('facebook-api', 'me');
        this.cacheService.removeCache('facebook-api', 'my-pages');

        resolve(true);

      }
      catch (e) {
        reject(e);
      }
    });
  }

  /**
   * Method to get a list of Pages
   */
  async getPages(): Promise<FacebookPagesDataInterface[]> {
    console.log('facebook.service->getPages()');
    return new Promise<FacebookPagesDataInterface[]>(async (resolve, reject) => {
      try {

        const FB = await this.FB();

        FB.api('/me/accounts', {
          access_token: this._credentials ? this._credentials.accessToken : undefined
        }, response => {
          console.log('facebook.service->getPages(): response', response);

          this._myPages = response.data;
          console.log('facebook.service->getPages(): this._myPages', this._myPages);

          this.cacheService.setCache('facebook-api', 'my-pages', this._myPages);

          resolve(response.data);

        });

      }
      catch (e) {
        reject(e);
      }
    });
  }
}

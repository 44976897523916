import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HideOffViewportDirective } from './hide-off-viewport.directive';

@NgModule({
  declarations: [HideOffViewportDirective],
  imports: [
    CommonModule
  ],
  exports: [HideOffViewportDirective]
})
export class HideOffViewportModule {
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MipSideModalComponent } from './mip-side-modal.component';

@NgModule({
  declarations: [MipSideModalComponent],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [MipSideModalComponent]
})
export class MipSideModalModule {
}

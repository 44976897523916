import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { LoginService } from '@apps/gc-login/login.service';
import {
  FormHelper,
  MetaTagsService,
  RouterService,
  UserService,
  utilsFactory,
} from '@lib/gc-common';
import { environment } from '@libs/gc-common/environments/environment';

@Component({
  selector: 'mip-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  @Input() redirectPath = null;
  @Input() disableHeader = null;
  @Input() disableLoginBtn = null;
  @Input() disableCopyright = null;
  @Input() darkTheme = true;

  @Output() onLoginSuccess = new EventEmitter();

  loginForm = new FormHelper({
    username: new FormControl('', []),
    password: new FormControl('', []),
    rememberMe: new FormControl(true, []),
  });

  loadingLogin = false;
  errorLogin = false;

  errorAuthNotFoundUser = false;
  errorInvalidCredentials = false;
  errorSuspendedUser = false;
  errorBannedUser = false;
  errorDeletedUser = false;

  goBackReferrer = null;
  showPassword = false;
  loginBackgroundImage = null;
  validEmail = null;

  assetsPath = environment.assetsPath;
  networkUrl = environment.networkUrl;
  enableSocialLogin = environment.enableSocialLogin;

  constructor(
    private loginService: LoginService,
    private userService: UserService,
    private routerService: RouterService,
    private metaTagsService: MetaTagsService,
  ) {}

  async ngOnInit() {
    try {
      const routerObject = await this.routerService.getRouteObject();
      // console.log('login-form.component->ngOnInit(): routerObject', routerObject);

      if (routerObject.data.loginBackgroundImage) {
        this.loginBackgroundImage = routerObject.data.loginBackgroundImage.replace('{{assetsPath}}', this.assetsPath);
      }

      await this.metaTagsService.setMetaTags({
        title: `Login`,
      });

      if (routerObject.queryParams.email) {
        this.loginForm.controls['username'].setValue(routerObject.queryParams.email);
      }

      if (utilsFactory.isBrowser) {
        this.goBackReferrer = this.routerService.getGoBackReferer(environment.loginUrl);
        // console.log('login-form.component->ngOnInit(): this.goBackReferrer', this.userService.isAuthenticated(), this.goBackReferrer);

        if (this.userService.isAuthenticated()) {
          if (this.goBackReferrer) {
            // console.log('login-form.component->ngOnInit(): this.goBackReferrer.path', this.goBackReferrer.path);
            document.location = this.goBackReferrer.path;
          } else {
            // console.log('login-form.component->ngOnInit(): environment.networkUrl', environment.networkUrl);
            document.location = environment.networkUrl;
          }
        } else {
          try {
            const cacheLoginData = this.loginService.storageRegisterData;
            // console.log('login-form.component->ngOnInit(): cacheLoginData', cacheLoginData);

            if (cacheLoginData && (cacheLoginData.rememberMe || cacheLoginData.hasVerifiedEmail)) {
              // console.log('login-form.component->ngOnInit(): cacheLoginData', cacheLoginData);
              // console.log('login-form.component->ngOnInit(): this.loginForm', this.loginForm);

              this.loginForm.setValue({
                password: cacheLoginData.password,
                username: cacheLoginData.username || cacheLoginData.email,
                rememberMe: cacheLoginData.rememberMe,
              });
            }
          } catch (e) {
            console.error('login-form.component->ngOnInit(): ERROR 1', e);
          }
        }
      }
    } catch (e) {
      utilsFactory.noticeError(e);
      console.error('login-form.component->ngOnInit(): ERROR 2', e);
    }
  }

  async onSubmit() {
    const payload = this.loginForm.value;
    payload.graphDomain = 'gotchosen';

    try {
      this.loginForm.disable();
      this.loadingLogin = true;
      this.errorLogin = false;

      this.errorAuthNotFoundUser = false;
      this.errorInvalidCredentials = false;
      this.errorSuspendedUser = false;
      this.errorBannedUser = false;
      this.errorDeletedUser = false;

      // console.log('login-form.component->onSubmit(): payload', payload);

      const userModel = await this.loginService.login(payload, this.onLoginSuccess.observers.length === 0);
      // console.log('login-form.component->onSubmit(): userModel', userModel);

      // this.loadingLogin = false;

      // console.log('login-form.component->onSubmit(): this.onLoginSuccess.observers.length', this.onLoginSuccess.observers.length);

      if (this.onLoginSuccess.observers.length) {
        this.onLoginSuccess.emit(userModel);
        return;
      }

      if (userModel.isActive === false) {
        // console.log('login-form.component->onSubmit(): redirecting to [code-verifier]', `/code-verifier`);
        return await this.routerService.navigateTo(`/code-verifier`);
      } else if (this.goBackReferrer) {
        // console.log('login-form.component->onSubmit(): this.goBackReferrer', this.goBackReferrer);
        document.location = this.goBackReferrer.path;
      } else {
        // console.log('login-form.component->onSubmit(): environment.networkUrl', environment.networkUrl);
        document.location = environment.networkUrl;
      }
    } catch (error) {
      console.error('login-form.component->onSubmit(): ERROR', error);

      this.loginForm.enable();
      this.loadingLogin = false;

      if (error['code'] === 'AUTH_UNVALIDATED_EMAIL') {
        return await this.routerService.navigateTo(`/code-verifier`, {
          queryParams: {
            resend: true,
          },
        });
      } else if (error['code'] === 'AUTH_NOT_FOUND_USER') {
        this.errorAuthNotFoundUser = true;
        this.loginForm.validate('username.account-not-found');
      } else if (error['code'] === 'AUTH_INVALID_CREDENTIALS') {
        this.errorInvalidCredentials = true;
        this.loginForm.validate('username.credentials-not-valid');
        this.loginForm.validate('password.credentials-not-valid');
      } else if (error['code'] === 'AUTH_SUSPENDED_USER') {
        this.errorSuspendedUser = true;
        this.loginForm.validate('username.account-suspended');
      } else if (error['code'] === 'AUTH_BANNED_USER') {
        this.errorBannedUser = true;
        this.loginForm.validate('username.account-banned');
      } else if (error['code'] === 'AUTH_DELETED_USER') {
        this.errorDeletedUser = true;
        this.loginForm.validate('username.account-deleted');
      } else {
        try {
          this.loginForm.validate(error['message']);
        } catch (e) {
          if (error instanceof Error) {
            utilsFactory.noticeError(error);
          }
          this.errorLogin = true;
        }
      }

      throw error;
    }
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  onEmailChange() {
    try {
      const formPayload = this.loginForm.value;

      if (formPayload.username.indexOf('@') > -1) {
        if (utilsFactory.isEmailValid(formPayload.username)) {
          this.validEmail = formPayload.username;
        } else {
          this.loginForm.validate('username.invalid');
        }
      } else {
        this.validEmail = null;
      }
    } catch (e) {
      console.error('login-form.component->onEmailChange(): ERROR', e);
      throw e;
    }
  }
}

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  NgModule,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {
  BrowserModule,
  makeStateKey,
  TransferState,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
  ColorModule,
  DownloadAppModule,
  GoBackModule,
  I18nModule,
  MipAlertModule,
  MipAvatarModule,
  MipCardModule,
  MipDialogModule,
  MipDividerModule,
  MipIconModule,
  MipLoadingModule,
  MipModalModule,
  MipProfileSlugNameModule,
  MipToolbarModule,
  MipTranslateModule,
  ProfileFollowingButtonModule,
  RecaptchaModule,
} from '@lib/gc-common';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { NgPipesModule } from 'ngx-pipes';

import { BirthdayFormComponent } from './birthday-form/birthday-form.component';
import { ChangePasswordFormComponent } from './change-password/change-password-form.component';
import { CodeVerifierFormComponent } from './code-verifier/code-verifier-form.component';
import { LoginCopyrightModule } from './copyright/login-copyright.module';
// import { CopyrightComponent } from './copyright/copyright.component';
import { ConfirmAccountDeletionComponent } from './device-users/confirm-account-deletion/confirm-account-deletion.component';
import { ConfirmDeviceDeletionComponent } from './device-users/confirm-device-deletion/confirm-device-deletion.component';
import { DeviceAccountCardComponent } from './device-users/device-account-card/device-account-card.component';
import { DeviceUsersComponent } from './device-users/device-users.component';
import { EmailFormComponent } from './email-form/email-form.component';
import { ForgotPasswordFormComponent } from './forgot-password-form/forgot-password-form.component';
import { GenderFormComponent } from './gender-form/gender-form.component';
import { LoginFormModule } from './login-form/login-form.module';
// import { LoginFormComponent } from './login-form/login-form.component';
import { LoginRoutingModule } from './login-routing.module';
import { LoginSocialModule } from './login-social/login-social.module';
// import { LoginSocialComponent } from './login-social/login-social.component';
import { LoginComponent } from './login.component';
import { LoginRegisterFormModule } from './register-form/login-register-form.module';
// import { RegisterFormComponent } from './register-form/register-form.component';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';

@NgModule({
  declarations: [
    LoginComponent,
    // LoginFormComponent,
    // LoginSocialComponent,
    // CopyrightComponent,
    ConfirmAccountDeletionComponent,
    ConfirmDeviceDeletionComponent,
    DeviceAccountCardComponent,
    DeviceUsersComponent,
    ForgotPasswordFormComponent,
    ChangePasswordFormComponent,
    CodeVerifierFormComponent,
    // RegisterFormComponent,
    BirthdayFormComponent,
    GenderFormComponent,
    EmailFormComponent,
    WelcomePageComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    LoginRegisterFormModule,
    LoginCopyrightModule,
    LoginSocialModule,
    LoginFormModule,
    RouterModule,
    I18nModule,
    HttpClientModule,
    MatSnackBarModule,
    LoginRoutingModule,
    MatButtonModule,
    MatIconModule,
    MipIconModule,
    MipCardModule,
    MipModalModule,
    MatDialogModule,
    MipDialogModule,
    MipTranslateModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    GoBackModule,
    MatCheckboxModule,
    ColorModule,
    MipAlertModule,
    MipDividerModule,
    MipLoadingModule,
    MipToolbarModule,
    NgPipesModule,
    MatMenuModule,
    MipAvatarModule,
    RoundProgressModule,
    MipProfileSlugNameModule,
    MatSelectModule,
    ProfileFollowingButtonModule,
    DownloadAppModule,
    RecaptchaModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      deps: [TransferState],
      useFactory: (transferState) => async () => {
        const disableSmartbanner = makeStateKey<string>('DISABLE_SMARTBANNER');
        transferState.set(disableSmartbanner, '1');
      },
      multi: true,
    },
  ],
  bootstrap: [LoginComponent]
})
export class LoginModule {
}

import {
  Component,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { LoginService } from '@apps/gc-login/login.service';
import {
  FormHelper,
  MetaTagsService,
  RouterService,
  utilsFactory,
} from '@lib/gc-common';
import { environment } from '@libs/gc-common/environments/environment';

@Component({
  selector: 'mip-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss'],
})
export class ForgotPasswordFormComponent implements OnInit {
  forgotPasswordForm = new FormHelper({
    email: new FormControl('', []),
  });

  errorNotFound = false;
  loadingForgotPassword = false;
  errorForgotPassword = false;
  loginBackgroundImage = null;
  goBackReferrer = null;

  assetsPath = environment.assetsPath;

  constructor(
    private metaTagsService: MetaTagsService,
    private loginService: LoginService,
    private routerService: RouterService,
  ) {
    // console.allowNamespace('forgot-password-form.component');
  }

  async ngOnInit() {
    try {
      const { data, queryParams, url } = await this.routerService.getRouteObject();
      // console.log('forgot-password-form.component->ngOnInit(): { data, queryParams, url }', { data, queryParams, url });

      this.goBackReferrer = this.routerService.getGoBackReferer(url);
      // console.log('forgot-password-form.component->ngOnInit(): this.goBackReferrer', this.goBackReferrer);

      if (data.loginBackgroundImage) {
        this.loginBackgroundImage = data.loginBackgroundImage.replace('{{assetsPath}}', this.assetsPath);
      }

      if (queryParams.email) {
        this.forgotPasswordForm.controls['email'].setValue(queryParams.email);

        this.forgotPasswordForm.markAsTouched();
        this.forgotPasswordForm.markAllAsTouched();
      }

      this.metaTagsService.setMetaTags({
        title: `Forgot Password`,
      });
    } catch (e) {
      if (e instanceof Error) {
        utilsFactory.noticeError(e);
      }
    }
  }

  async onSubmit() {
    // console.log('forgot-password-form.component->onSubmit()');
    // console.log('forgot-password-form.component->onSubmit(): this.forgotPasswordForm', this.forgotPasswordForm);

    try {
      if (!this.forgotPasswordForm.valid) {
        return false;
      }

      const payload = this.forgotPasswordForm.value;

      // console.log('forgot-password-form.component->onSubmit(): payload', payload);

      if (!utilsFactory.isEmailValid(payload.email)) {
        this.forgotPasswordForm.validate('email.invalid');
        return false;
      }

      this.forgotPasswordForm.disable();
      this.loadingForgotPassword = true;
      this.errorForgotPassword = false;
      this.errorNotFound = false;

      await this.loginService.forgotPassword(payload.email);

      this.loadingForgotPassword = false;
    } catch (e) {
      console.error('forgot-password-form.component->onSubmit(): ERROR', e);

      this.forgotPasswordForm.enable();
      this.loadingForgotPassword = false;

      if (e['code'] === 404) {
        this.errorNotFound = true;
        this.forgotPasswordForm.validate('email.not-found');
      } else if (e['message'] === 'email-invalid') {
        this.forgotPasswordForm.validate('email.invalid');
      } else {
        if (e instanceof Error) {
          utilsFactory.noticeError(e);
        }

        this.errorForgotPassword = true;
      }
    }
  }
}

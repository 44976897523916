<header class="header mip-color--bg-dark" [style.background-image]="'url(' + loginBackgroundImage + ')'">
  <div class="__inner">
    <div class="--flex-row --flex-column--center">
      <mip-icon class="__logo-icon" icon="gc-hand-circle" size="11x"></mip-icon>
      <h1><strong>GotChosen</strong></h1>
    </div>
  </div>
</header>

<div class="__content" *ngIf="referralUserModel">
  <div class="__referral-user">
    <mip-avatar [userModel]="referralUserModel" [size]="100" borderColor="gray"></mip-avatar>
    <div>
      <h2>
        <a href="{{ networkUrl }}/@{{ referralUserModel.username }}" target="_blank">
          <strong *ngIf="referralUserModel.getFirstName()">
            {{ referralUserModel.getFirstName() }}
          </strong>
          <strong *ngIf="!referralUserModel.getFirstName()">
            <mip-profile-slug-name [user]="referralUserModel"></mip-profile-slug-name>
          </strong>
        </a>
        <span mipTranslate="login.welcome-page.welcomes-you">welcomes you!</span>
      </h2>

      <mip-profile-following-button [profile]="referralUserModel" [autoFollow]="true" borderFollowingColor="gray--darker"></mip-profile-following-button>
    </div>
  </div>

  <div class="__user-info">
    <a mat-button href="{{ networkUrl }}/@{{ loggedInUser.username }}" target="_blank" mipTextColor="primary" mipBdColor="primary">
      <span>Go to my <strong>Profile</strong></span>
      <mip-icon icon="arrow-right" size="2x"></mip-icon>
    </a>

    <a mat-button href="{{ networkUrl }}/trending" target="_blank" mipTextColor="secondary" mipBdColor="secondary">
      <span>Go to my <strong>Trending</strong></span>
      <mip-icon icon="arrow-right" size="2x"></mip-icon>
    </a>

    <div class="__download-app">
      <div class="__description">
        <h2 mipTranslate="login.welcome-page.message" class="--text-align--center">Download our app to activate your monetization.</h2>
      </div>

      <mip-download-app-buttons></mip-download-app-buttons>
    </div>
  </div>
</div>

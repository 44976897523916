import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';

import { CopyComponent } from './copy.component';

@NgModule({
  declarations: [
    CopyComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MipIconModule
  ],
  exports: [
    CopyComponent
  ]
})
export class CopyModule {
}

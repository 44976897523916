<div #content style="display: none">
  <ng-content></ng-content>

  <div class="content">

    <div class="content__image">
      <ng-content select="[mip-tooltip-image],[mip-tooltip-icon]"></ng-content>
    </div>

    <div class="content__text">
      <ng-content select="[mip-tooltip-text]"></ng-content>
    </div>
  </div>

</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DatePickerFormatDirective } from './date-picker-format.directive';

@NgModule({
  declarations: [
    DatePickerFormatDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DatePickerFormatDirective
  ]
})
export class DatePickerFormatModule {
}

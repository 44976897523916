import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MipTagCloudComponent } from './mip-tag-cloud.component';

@NgModule({
  declarations: [MipTagCloudComponent],
  exports: [
    MipTagCloudComponent
  ],
  imports: [
    CommonModule
  ]
})
export class MipTagCloudModule {
}

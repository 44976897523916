import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { UserModel } from '@libs/gc-common/lib/api/models/user';

@Component({
  selector: 'mip-profile-card',
  templateUrl: './mip-profile-card.component.html',
  styleUrls: ['./mip-profile-card.component.scss']
})
export class MipProfileCardComponent implements OnInit {

  @Input() user: UserModel = null;
  @Input() showFollowingBtn = true;

  @Input() showCopyIcon = false;

  constructor() {
  }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mip-side-modal',
  templateUrl: './mip-side-modal.component.html',
  styleUrls: ['./mip-side-modal.component.scss']
})
export class MipSideModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import {
  Component,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { LoginService } from '@apps/gc-login/login.service';
import {
  FormHelper,
  RouterService,
  UserModel,
  UserService,
  utilsFactory,
} from '@lib/gc-common';
import { environment } from '@libs/gc-common/environments/environment';

@Component({
  selector: 'mip-gender-form',
  templateUrl: './gender-form.component.html',
  styleUrls: ['./gender-form.component.scss'],
})
export class GenderFormComponent implements OnInit {
  registerForm = new FormHelper({
    gender: new FormControl('', []),
  });

  genders = [
    {
      label: 'Man',
      value: 'man',
    },
    {
      label: 'Woman',
      value: 'woman',
    },
    {
      label: 'Other',
      value: 'other',
    },
  ];

  loadingSettings = false;
  errorSettings = false;
  loginBackgroundImage = null;
  userModel: UserModel = null;
  goBackReferrer = null;

  assetsPath = environment.assetsPath;

  constructor(
    private loginService: LoginService,
    private routerService: RouterService,
    private userService: UserService,
  ) {}

  async ngOnInit() {
    try {
      if (this.loginService.storageRegisterUserData) {
        this.userModel = this.loginService.storageRegisterUserData;
        // console.log('gender-form.component->ngOnInit(): this.userModel', this.userModel);
      } else {
        const backPathReferrer = this.routerService.goPreviouslyRoute();
        // console.log('gender-form.component->ngOnInit(): backPathReferrer', backPathReferrer);

        const backPath = backPathReferrer ? backPathReferrer.path : '/';
        // console.log('gender-form.component->ngOnInit(): backPath', backPath);

        await this.routerService.navigateTo(backPath, {
          queryParams: {
            code: null,
            scope: null,
            authuser: null,
            prompt: null,
          },
        });
      }

      this.goBackReferrer = this.routerService.getGoBackReferer(environment.loginUrl);
      // console.log('gender-form.component->ngOnInit(): this.goBackReferrer', this.goBackReferrer);
    } catch (e) {
      console.error('gender-form.component->ngOnInit(): ERROR', e);
      if (e instanceof Error) {
        utilsFactory.noticeError(e);
      }
    }
  }

  async onSubmit() {
    try {
      if (!this.registerForm.valid) {
        return false;
      }

      const payload = {
        ...this.loginService.storageRegisterData,
        ...this.registerForm.value,
      };
      // console.log('gender-form.component->onSubmit(): payload', payload);

      const modelUserGender = this.userModel.getSettingByKey('gender');
      // console.log('gender-form.component->onSubmit(): modelUserGender', modelUserGender);

      this.registerForm.disable();
      this.loadingSettings = true;
      this.errorSettings = false;

      await this.userModel.saveSettings(
        {
          key: 'gender',
          value: payload.gender,
          visibility: 'private',
        },
        modelUserGender ? modelUserGender.id : null,
      );

      await this.userService.setAuthentication(this.userModel);

      if (this.goBackReferrer) {
        // console.log('gender-form.component->onSubmit(): this.goBackReferrer', this.goBackReferrer);
        await this.routerService.navigateTo(this.goBackReferrer.path);
      } else {
        // console.log('gender-form.component->onSubmit(): environment.networkUrl', environment.networkUrl);
        document.location = environment.networkUrl;
      }
    } catch (e) {
      console.error('gender-form.component->onSubmit(): ERROR', e);

      this.registerForm.enable();
      this.loadingSettings = false;

      if (e['message']) {
        utilsFactory.noticeError(e);
      }
    }
  }
}

<mip-card *ngIf="user">

  <!-- HEADER -->
  <mip-card-header>

    <mip-profile-slug-name
      [user]="user"
      [showAvatar]="true"
      [showName]="true"
      [enableProfileLink]="true"
      [openProfileLinkNewPage]="true"
      [showCopyIcon]="showCopyIcon"
    ></mip-profile-slug-name>

    <!-- ACTIONS -->
    <ng-content select="mip-profile-header-actions"></ng-content>

  </mip-card-header>

  <!-- CONTENT -->
  <mip-card-content>

    <!-- BIO -->
    <div class="__dio" *ngIf="user.bio">{{user.bio}}</div>

    <!-- URL -->
    <div class="__url" *ngIf="user.getSettings('url')">

      <mip-icon icon="url" size="3x"></mip-icon>

      <a [href]="user.getSettings('url')" target="_blank">
        <span>{{user.getSettings('url')}}</span>
      </a>

    </div>

    <!-- STATS -->
    <div class="__stats" *ngIf="user.userStats">
      <div class="__stats-item" *ngIf="user.userStats.reactionsReceived !== null && user.userStats.reactionsReceived >= 0">
        <strong>{{user.userStats.reactionsReceived | millify}}</strong>
        <small>reactions</small>
      </div>
      <div class="__stats-item" *ngIf="user.userStats.posts !== null && user.userStats.posts >= 0">
        <strong>{{user.userStats.posts | millify}}</strong>
        <small>posts</small>
      </div>
      <div class="__stats-item" *ngIf="user.userStats.followers !== null && user.userStats.followers >= 0">
        <strong>{{user.userStats.followers | millify}}</strong>
        <small>followers</small>
      </div>
      <div class="__stats-item" *ngIf="user.userStats.following !== null && user.userStats.following >= 0">
        <strong>{{user.userStats.following | millify}}</strong>
        <small>following</small>
      </div>
    </div>

  </mip-card-content>

  <!-- FOOTER -->
  <mip-card-footer>

    <!-- ACTIONS -->
    <ng-content select="mip-profile-footer-actions"></ng-content>

    <!-- FOLLOWING BUTTON -->
    <mip-profile-following-button
      [profile]="user"
      *ngIf="showFollowingBtn"
    ></mip-profile-following-button>

  </mip-card-footer>

</mip-card>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MipBreadcrumbComponent } from './mip-breadcrumb.component';
import { MipBreadcrumbItemComponent } from './mip-breadcrumb-item/mip-breadcrumb-item.component';

@NgModule({
  declarations: [MipBreadcrumbComponent, MipBreadcrumbItemComponent],
  imports: [
    CommonModule
  ],
  exports: [MipBreadcrumbComponent, MipBreadcrumbItemComponent]
})
export class MipBreadcrumbModule { }

<header class="header mip-color--bg-dark" [style.background-image]="'url(' + loginBackgroundImage + ')'">
  <div class="__inner">
    <div class="--flex-row">
      <mip-icon class="__logo-icon" icon="gc-hand-circle" size="11x"></mip-icon>
      <div class="--flex-column">
        <strong mipTranslate="login-form.header.message.title">Sign up for</strong>
        <h1><strong>GotChosen</strong></h1>
      </div>
    </div>
  </div>
</header>

<div class="content">
  <div class="__inner">
    <form class="__form" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
      <!-- EMAIL -->
      <mat-form-field appearance="fill" color="accent">
        <mat-label mipTranslate="register-form.fields.email.label">Email</mat-label>
        <span matPrefix><mip-icon icon="calendar" size="1x"></mip-icon></span>
        <input matInput type="email" formControlName="email" [required]="true" />

        <mat-error>
          <span *ngIf="registerForm.get('email').hasError('required')" mipTranslate="register-form.fields.email.error-message.required"> You must provide your email. </span>
          <span *ngIf="registerForm.get('email').hasError('invalid')" mipTranslate="register-form.fields.email.error-message.invalid"> The email provided is not valid. </span>
        </mat-error>
      </mat-form-field>

      <!-- SUBMIT -->
      <button class="__submit-button mip-button--size-lg mip-color--bg-primary" mat-flat-button type="submit" [disabled]="loadingRegistration">
        <mip-icon icon="refresh" [spin]="true" *ngIf="loadingRegistration"></mip-icon>
        <span mipTranslate="register-form.actions.please-wait" *ngIf="loadingRegistration">Please wait...</span>
        <span mipTranslate="register-form.actions.continue" *ngIf="!loadingRegistration">Continue</span>
      </button>

      <mip-alert class="__alert-message" theme="danger" *ngIf="errorRegistration">
        <mip-icon icon="warning" [solid]="true"></mip-icon>
        <span mipTranslate="register-form.error-message.register-error"> There was an error while trying to register the information. </span>
      </mip-alert>
    </form>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MipCardModule } from '@libs/gc-common/lib/components/mip-card/mip-card.module';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';
import { MipProfileSlugNameModule } from '@libs/gc-common/lib/components/mip-profile-slug-name/mip-profile-slug-name.module';
import { ProfileFollowingButtonModule } from '@libs/gc-common/lib/features/profile-following-button/profile-following-button.module';
import { MillifyModule } from '@libs/gc-common/lib/pipes/millify/millify.module';

import { MipProfileCardComponent } from './mip-profile-card.component';
import { MipProfileFooterActionsComponent } from './mip-profile-footer-actions/mip-profile-footer-actions.component';
import { MipProfileHeaderActionsComponent } from './mip-profile-header-actions/mip-profile-header-actions.component';

@NgModule({
  declarations: [MipProfileCardComponent, MipProfileHeaderActionsComponent, MipProfileFooterActionsComponent],
  imports: [
    CommonModule,
    MipCardModule,
    MipProfileSlugNameModule,
    ProfileFollowingButtonModule,
    MipIconModule,
    MillifyModule
  ],
  exports: [MipProfileCardComponent, MipProfileHeaderActionsComponent, MipProfileFooterActionsComponent]
})
export class MipProfileCardModule {
}

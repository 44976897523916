import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'mip-section-content',
    templateUrl: './mip-section-content.component.html',
    styleUrls: ['./mip-section-content.component.scss']
})
export class MipSectionContentComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}

import moment from 'moment';

import {
  Component,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { LoginService } from '@apps/gc-login/login.service';
import {
  FormHelper,
  RouterService,
  UserModel,
  UserService,
  utilsFactory,
} from '@lib/gc-common';
import { environment } from '@libs/gc-common/environments/environment';

@Component({
  selector: 'mip-birthday-form',
  templateUrl: './birthday-form.component.html',
  styleUrls: ['./birthday-form.component.scss'],
})
export class BirthdayFormComponent implements OnInit {
  registerForm = new FormHelper({
    birthday: new FormControl('', []),
  });

  loadingSettings = false;
  errorSettings = false;

  assetsPath = environment.assetsPath;
  loginBackgroundImage = null;
  userModel: UserModel = null;
  goBackReferrer = null;

  constructor(
    private loginService: LoginService,
    private routerService: RouterService,
    private userService: UserService,
  ) {}

  async ngOnInit() {
    try {
      const { data } = await this.routerService.getRouteObject();
      // console.log('birthday-form.component->ngOnInit(): data', data);

      if (data.loginBackgroundImage) {
        this.loginBackgroundImage = data.loginBackgroundImage.replace('{{assetsPath}}', this.assetsPath);
      }

      this.goBackReferrer = this.routerService.getGoBackReferer(environment.loginUrl);
      // console.log('birthday-form.component->ngOnInit(): this.goBackReferrer', this.goBackReferrer);

      if (this.loginService.storageRegisterUserData) {
        this.userModel = this.loginService.storageRegisterUserData;
        // console.log('birthday-form.component->ngOnInit(): this.userModel', this.userModel);
      } else {
        const backPathReferrer = this.routerService.goPreviouslyRoute();
        // console.log('birthday-form.component->ngOnInit(): backPathReferrer', backPathReferrer);

        const backPath = backPathReferrer ? backPathReferrer.path : '/';
        // console.log('birthday-form.component->ngOnInit(): backPath', backPath);

        await this.routerService.navigateTo(backPath, {
          queryParams: {
            code: null,
            scope: null,
            authuser: null,
            prompt: null,
          },
        });
      }
    } catch (e) {
      console.error('birthday-form.component->ngOnInit(): ERROR', e);

      if (e instanceof Error) {
        utilsFactory.noticeError(e);
      }
    }
  }

  async onSubmit() {
    try {
      if (!this.registerForm.valid) {
        return false;
      }

      const diffDates = moment().diff(moment(this.registerForm.controls['birthday'].value), 'years');
      // console.log('login.service->registerUser(): diffDates', diffDates);

      if (diffDates < 13) {
        throw new Error(`birthday.under-age`);
        // this.registerForm.controls.birthday.setErrors({'under-age': true});
      }

      const payload = {
        ...this.loginService.storageRegisterData,
        ...this.registerForm.value,
      };
      // console.log('birthday-form.component->onSubmit(): payload', payload);

      const modelUserBirthday = this.userModel.getSettingByKey('birthday');
      // console.log('birthday-form.component->onSubmit(): modelUserBirthday', modelUserBirthday);

      this.registerForm.disable();
      this.loadingSettings = true;
      this.errorSettings = false;

      await this.userModel.saveSettings(
        {
          key: 'birthday',
          value: payload.birthday,
          visibility: 'private',
        },
        modelUserBirthday ? modelUserBirthday.id : null,
      );

      const gender = this.userModel.getGender();
      // console.log('birthday-form.component->ngOnInit(): Gender ->>', gender);

      if (gender === null) {
        await this.routerService.navigateTo(`/gender`);
        throw new Error('gender.invalid');
      }

      await this.userService.setAuthentication(this.userModel);

      this.loadingSettings = false;
      this.errorSettings = false;

      if (this.goBackReferrer) {
        // console.log('birthday-form.component->onSubmit(): this.goBackReferrer', this.goBackReferrer);
        await this.routerService.navigateTo(this.goBackReferrer.path);
      } else {
        // console.log('birthday-form.component->onSubmit(): environment.networkUrl', environment.networkUrl);
        document.location = environment.networkUrl;
      }
    } catch (e) {
      console.error('birthday-form.component->onSubmit(): ERROR', e);

      this.registerForm.enable();
      this.loadingSettings = false;

      if (e['message'] === 'birthday.under-age') {
        this.registerForm.validate(e['message']);
      } else {
        if (e['message']) {
          utilsFactory.noticeError(e);
        }

        this.errorSettings = true;
      }
    }
  }
}

import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
} from '@angular/router';
import {
  IsAnonymousGuard,
  IsAuthenticatedGuard,
} from '@lib/gc-common';

import { BirthdayFormComponent } from './birthday-form/birthday-form.component';
import { ChangePasswordFormComponent } from './change-password/change-password-form.component';
import { CodeVerifierFormComponent } from './code-verifier/code-verifier-form.component';
import { ConfirmAccountDeletionComponent } from './device-users/confirm-account-deletion/confirm-account-deletion.component';
import { ConfirmDeviceDeletionComponent } from './device-users/confirm-device-deletion/confirm-device-deletion.component';
import { DeviceUsersComponent } from './device-users/device-users.component';
import { EmailFormComponent } from './email-form/email-form.component';
import { ForgotPasswordFormComponent } from './forgot-password-form/forgot-password-form.component';
import { GenderFormComponent } from './gender-form/gender-form.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { RegisterFormComponent } from './register-form/register-form.component';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';

const routes: Routes = [
  {
    path: '',
    data: {
      metatags: {
        title: 'pages.metatag.login.title',
      },
    },
    children: [
      {
        path: '',
        data: {
          backgroundColor: '#343434',
        },
        component: LoginFormComponent,
        canActivate: [IsAnonymousGuard],
        children: [
          {
            path: 'device-users',
            children: [
              {
                path: ':uuid',
                component: DeviceUsersComponent,
              },
              {
                path: ':uuid/account/:userId',
                component: ConfirmAccountDeletionComponent,
              },
              {
                path: ':uuid/device/:userId',
                component: ConfirmDeviceDeletionComponent,
              },
            ],
          },
        ],
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordFormComponent,
      },
      {
        path: 'change-password',
        component: ChangePasswordFormComponent,
      },
      {
        path: 'code-verifier',
        component: CodeVerifierFormComponent,
      },
      {
        path: 'register',
        component: RegisterFormComponent,
        canActivate: [IsAnonymousGuard],
      },
      {
        path: 'birthday',
        component: BirthdayFormComponent,
      },
      {
        path: 'gender',
        component: GenderFormComponent,
      },
      {
        path: 'email',
        component: EmailFormComponent,
      },
      {
        path: 'welcome',
        component: WelcomePageComponent,
        canActivate: [IsAuthenticatedGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class LoginRoutingModule {}

import {
  Pipe,
  PipeTransform
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(url: string) {
    console.log('safe-url.pipe->transform(): url', url);

    const URL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    console.log('safe-url.pipe->transform(): URL', URL);

    return URL;
  }

}


import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';

import { MobileDetectService } from './mobile-detect.service';

@Injectable({
  providedIn: 'root'
})
export class MobileDetectGuard implements CanActivate {

  constructor(
    private mobileDetectService: MobileDetectService,
    private router: Router
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {

    const inviteUrl = '/@paulo/invite';
    const urlTree: UrlTree = this.router.parseUrl(inviteUrl);
    console.log('mobile-detect.guard->canActivate(): urlTree', urlTree);

    const isMobile = this.mobileDetectService.isMobile();
    console.log('mobile-detect.guard->canActivate(): isMobile', isMobile);

    return isMobile;
  }
}

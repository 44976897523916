import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  RouterService,
  UserDeviceUUIDModel,
} from '@lib/gc-common';
import { environment } from '@libs/gc-common/environments/environment';

@Component({
  selector: 'mip-device-account-card',
  templateUrl: './device-account-card.component.html',
  styleUrls: ['./device-account-card.component.scss'],
})
export class DeviceAccountCardComponent implements OnInit, OnDestroy {
  @Input() user: UserDeviceUUIDModel = null;
  @Input() index: number = null;
  @Input() showActions = false;

  isProduction = environment.environmentName === 'production';
  uuid: string = null;

  onRouteChangeSubscription = null;

  constructor(private routerService: RouterService) {}

  async ngOnInit() {
    this.onRouteChangeSubscription = this.routerService.onRouteChange().subscribe(({ params }) => {
      // console.log('device-account-card.component->ngOnInit(): params', params);

      this.uuid = params.uuid;
      // console.log('device-account-card.component->ngOnInit(): this.uuid', this.uuid);
      // console.log('device-account-card.component->ngOnInit(): this.user', this.user);
    });
  }

  ngOnDestroy() {
    if (this.onRouteChangeSubscription) {
      this.onRouteChangeSubscription.unsubscribe();
    }
  }
}

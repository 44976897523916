import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mip-section-yt-video',
  templateUrl: './mip-section-yt-video.component.html',
  styleUrls: ['./mip-section-yt-video.component.scss']
})
export class MipSectionYtVideoComponent implements OnInit {

  @Input() source = null;

  constructor() {
  }

  ngOnInit(): void {
  }

}

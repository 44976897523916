import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';

@Injectable({
  providedIn: 'root'
})
export class ActiveRouterServiceGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private routerService: RouterService
  ) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    {url}: RouterStateSnapshot
  ) {
    console.log('active-router-service.guard->canActivate() url', url);
    this.routerService.setActiveRoute(route);
    return true;
  }

  async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log('active-router-service.guard->canActivateChild()');
    return this.canActivate(route, state);
  }

}

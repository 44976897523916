import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { MipBadgeModule } from '@libs/gc-common/lib/components/mip-badge/mip-badge.module';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';
import { ColorModule } from '@libs/gc-common/lib/directives/color/color.module';
import { GoBackModule } from '@libs/gc-common/lib/directives/go-back/go-back.module';
import { DownloadAppModule } from '@libs/gc-common/lib/features/download-app/download-app.module';

import { MainMenuBarComponent } from './main-menu-bar.component';

@NgModule({
  declarations: [
    MainMenuBarComponent
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MipIconModule,
    RouterModule,
    MipBadgeModule,
    MatBottomSheetModule,
    DownloadAppModule,
    GoBackModule,
    ColorModule
  ],
  exports: [
    MainMenuBarComponent
  ]
})
export class MainMenuBarModule {
}


import {
  Component,
  OnInit,
} from '@angular/core';
import {
  MobileDetectService,
  RouterService,
  UserDeviceUUIDModel,
  UserService,
} from '@lib/gc-common';
import { environment } from '@libs/gc-common/environments/environment';

@Component({
  selector: 'mip-device-users',
  templateUrl: './device-users.component.html',
  styleUrls: ['./device-users.component.scss'],
})
export class DeviceUsersComponent implements OnInit {
  loginBackgroundImage = null;
  assetsPath = environment.assetsPath;
  isLoadingUsers = true;
  hasErrors = false;
  isDesktop = false;
  isMobile = false;
  uuid = null;
  users: UserDeviceUUIDModel[] = []; // THIS IS NOT AN "UserModel" LIST!!!

  constructor(
    private routerService: RouterService,
    private mobileDetect: MobileDetectService,
    private userService: UserService,
  ) {
    this.isDesktop = !this.mobileDetect.isMobile();
    this.isMobile = this.mobileDetect.isMobile();

    // console.log('device-users.component->constructor(): this.isDesktop', this.isDesktop);
  }

  async ngOnInit() {
    try {
      const { params } = await this.routerService.getRouteObject();
      // console.log('device-users.component->ngOnInit(): {params}', { params });

      this.uuid = params.uuid;
      // console.log('device-users.component->ngOnInit(): this.uuid', this.uuid);

      this.isLoadingUsers = true;

      this.users = await this.userService.getUsersByDevice(this.uuid);
      // console.log('device-users.component->ngOnInit(): this.users', this.users);

      this.isLoadingUsers = false;
    } catch (e) {
      this.isLoadingUsers = false;
      this.hasErrors = true;
      throw e;
    }
  }

  closeModal() {
    const currentPathName = `/device-users/${this.uuid}`;
    // console.log('device-users.component->closeModal(): currentPathName', currentPathName);

    const goBackReferrer = this.routerService.getGoBackReferer(currentPathName);
    // console.log('device-users.component->closeModal(): goBackReferrer', goBackReferrer);

    if (goBackReferrer) {
      this.routerService.goBackReferer(currentPathName);
    } else {
      this.routerService.navigateTo(environment.loginUrl);
    }
  }
}

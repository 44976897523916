import {
  Component,
  OnInit,
} from '@angular/core';
import {
  MobileDetectService,
  RouterService,
} from '@lib/gc-common';
import { environment } from '@libs/gc-common/environments/environment';

@Component({
  selector: 'mip-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  modalSize = 'full';
  closeRedirectPath = null;
  darkCloseButton = false;
  isDesktop = false;
  isMobile = false;

  constructor(private routerService: RouterService, private mobileDetect: MobileDetectService) {
    this.isDesktop = !this.mobileDetect.isMobile();
    this.isMobile = this.mobileDetect.isMobile();

    console.info('login.component->constructor(): environment', environment);
  }

  async ngOnInit() {
    const routeData = await this.routerService.getRouteDataParam();
    // console.info('login.component->ngOnInit(): routeData', routeData);

    this.darkCloseButton = !!routeData.darkTheme;
    // console.log('login.component->ngOnInit(): this.darkCloseButton', this.darkCloseButton);

    this.modalSize = routeData.modalSize || 'full';
    // console.log('login.component->ngOnInit(): this.modalSize', this.modalSize);

    const goBackReferer = this.routerService.getGoBackReferer(environment.loginUrl);
    // console.log('login.component->ngOnInit(): goBackReferer', goBackReferer);

    this.closeRedirectPath = goBackReferer ? goBackReferer.path : environment.networkUrl;
    // console.log('login.component->ngOnInit(): this.closeRedirectPath', this.closeRedirectPath);
  }
}

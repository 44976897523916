import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MipTranslateModule } from '@lib/gc-common';

import { CopyrightComponent } from './copyright.component';

@NgModule({
  declarations: [CopyrightComponent],
  exports: [CopyrightComponent],
  imports: [
    CommonModule,
    MipTranslateModule
  ]
})
export class LoginCopyrightModule {
}

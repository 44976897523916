import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import tippy, { followCursor } from 'tippy.js';

interface TippyInterceptor {
  content?: string;
  allowHTML?: boolean;
  interactive?: boolean;
  followCursor?: boolean | 'horizontal' | 'vertical' | 'initial';
  trigger?: 'click' | 'focus' | 'mouseenter';
  arrow?: boolean | 'round' | 'large' | 'small' | 'wide' | 'narrow';
  placement?: 'top' | 'top-start' | 'top-end' | 'right' | 'right-start' | 'right-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'left' | 'left-start' | 'left-end';
  animation?: string;
  theme?: 'light' | 'dark' | 'material' | 'translucent';
  plugins?: Array<any>;
  maxWidth?: string;

  onShow?: (self) => void;
  onHidden?: (self) => void;
  onDestroy?: (self) => void;
}

@Component({
  selector: 'mip-tooltip',
  templateUrl: './mip-tooltip.component.html',
  styleUrls: ['./mip-tooltip.component.scss']
})
export class MipTooltipComponent implements OnInit, OnChanges {

  @ViewChild('content') content: ElementRef;

  @Input() isOpen: boolean = false;

  @Input() interactive: boolean = null;
  @Input() followCursor: boolean | 'horizontal' | 'vertical' | 'initial' = null;
  @Input() trigger: 'click' | 'focus' | 'mouseenter' = null;
  @Input() arrow: boolean | 'round' | 'large' | 'small' | 'wide' | 'narrow' = null;
  @Input() placement: 'top' | 'top-start' | 'top-end' | 'right' | 'right-start' | 'right-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'left' | 'left-start' | 'left-end' = null;
  @Input() animation: string = null;
  @Input() plugins: Array<any> = null;
  @Input() lightTheme = false;
  @Input() maxWidth = null;

  _trigger = null;
  _tippy = null;

  constructor(
    private host: ElementRef
  ) {

  }

  ngAfterViewInit() {

  }

  async ngOnInit() {
    try {
      try {
        await utilsFactory.waitToBeTrue('mip-tooltip.component', () => !!this._trigger, 100);
      }
      catch (e) {
        // throw new Error(`<mip-tooltip/> must have a trigger component! Ex.: <button [mipTooltip]="helpReach">...</button> <mip-tooltip #helpReach>...</mip-tooltip>`);
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(`mip-tooltip.component->ngOnChanges(): this.content`, this.content);

    if ('isOpen' in changes) {
      if (this.isOpen && this._trigger) {
        this._startTippy();
      }
      else if (this._tippy) {
        this._tippy.destroy();
      }
    }
  }

  async _startTippy(trigger?, props?: TippyInterceptor) {
    try {
      console.log(`mip-tooltip.component->_startTippy(): this.content`, this.content);

      this._trigger = trigger;
      console.log(`mip-tooltip.component->_startTippy(): trigger`, trigger);

      await utilsFactory.waitToBeTrue('', () => !!this.content);

      const settings: TippyInterceptor = props || {};

      settings.maxWidth = this.maxWidth || 'auto';

      const content = this.content.nativeElement;
      console.log(`mip-tooltip.component->_startTippy(): content`, content);

      settings.content = content.innerHTML;
      console.log(`mip-tooltip.component->_startTippy(): settings.content`, settings.content);

      settings.allowHTML = true;

      if (this.interactive) {
        settings.interactive = this.interactive || true;
      }

      if (this.followCursor) {
        settings.followCursor = this.followCursor;
        settings.plugins.push(followCursor);
      }

      if (this.trigger) {
        settings.trigger = this.trigger;
      }

      if (this.arrow) {
        settings.arrow = this.arrow;
      }

      if (this.placement) {
        settings.placement = this.placement;
      }

      if (this.animation) {
        settings.animation = this.animation || 'shift-away';
      }

      if (this.lightTheme) {
        settings.theme = this.lightTheme ? 'light' : null;
      }

      console.log(`mip-tooltip.component->_startTippy(): settings`, settings);

      let refreshContentInterval = null;

      settings.onShow = (self) => {
        console.log(`mip-tooltip.component->_startTippy(): onShow(): self`, self);

        refreshContentInterval = setInterval(() => {
          this._tippy.setContent(content.innerHTML);
          console.log(`mip-tooltip.component->_startTippy(): onShow(): refreshContentInterval`);
        }, 300);
      };

      settings.onHidden = (self) => {
        console.log(`mip-tooltip.component->_startTippy(): onHidden(): self`, self);
        clearInterval(refreshContentInterval);

      };

      settings.onDestroy = (self) => {
        console.log(`mip-tooltip.component->_startTippy(): onDestroy(): self`, self);
        clearInterval(refreshContentInterval);
      };

      this._tippy = tippy(trigger, settings);

      return this._tippy;

    }
    catch (e) {
      console.error(`mip-tooltip.component->_startTippy(): ERROR`, e);
    }
  }

  show() {
    this._tippy.show();
  }

  hide() {
    this._tippy.hide();
  }

}

import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { DownloadAppModalComponent } from './download-app-modal.component';
import { DownloadAppModule } from './download-app.module';

@Injectable({
  providedIn: DownloadAppModule
})
export class DownloadAppService {

  constructor(
    private bottomSheet: MatBottomSheet
  ) {
  }

  openModal() {
    this.bottomSheet.open(DownloadAppModalComponent);
  }

}

import {
  Component,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { LoginService } from '@apps/gc-login/login.service';
import {
  FormHelper,
  MetaTagsService,
  RouterService,
  UserService,
  utilsFactory,
} from '@lib/gc-common';
import { environment } from '@libs/gc-common/environments/environment';

@Component({
  selector: 'mip-code-verifier-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss'],
})
export class ChangePasswordFormComponent implements OnInit {
  assetsPath = environment.assetsPath;
  networkUrl = environment.networkUrl;

  loginBackgroundImage = null;
  urlCode = null;
  isUrlCodeValid = null;
  codeLoading = false;
  requestNewCode = false;
  newCodeLoading = false;
  savePasswordSuccess = false;
  savingNewPassword = false;
  errorChangeNewPassword = false;
  showPassword = false;
  errorMessage = null;
  goBackReferrer = null;

  changePasswordForm = new FormHelper({
    code: new FormControl('', []),
    email: new FormControl('', []),
    newPassword: new FormControl('', []),
  });

  errorSuspendedUser = false;
  errorBannedUser = false;
  errorDeletedUser = false;

  constructor(
    private metaTagsService: MetaTagsService,
    private userService: UserService,
    private loginService: LoginService,
    private routerService: RouterService,
  ) {}

  async ngOnInit() {
    try {
      const routerObject = await this.routerService.getRouteObject();

      if (routerObject.data.loginBackgroundImage) {
        this.loginBackgroundImage = routerObject.data.loginBackgroundImage.replace('{{assetsPath}}', this.assetsPath);
      }

      this.goBackReferrer = this.routerService.getGoBackReferer(environment.loginUrl);
      // console.log('change-password-form.component->ngOnInit(): this.goBackReferrer', this.goBackReferrer);

      if (routerObject.queryParams.code) {
        this.urlCode = routerObject.queryParams.code;

        try {
          const parsedCode = JSON.parse(utilsFactory.atob(routerObject.queryParams.code));
          // console.log('change-password-form.component->ngOnInit(): parsedCode', parsedCode);

          // check email and if is valid
          if (!parsedCode.email) {
            throw new Error(`'email' must be provided within the 'code' parameter`);
          } else if (!utilsFactory.isEmailValid(parsedCode.email)) {
            throw new Error(`'email' provided within the 'code' parameter is not valid`);
          }

          if (!parsedCode.code) {
            throw new Error(`Validation 'email' must be provided within the 'code' parameter`);
          }

          this.isUrlCodeValid = true;
          this.codeLoading = true;

          this.changePasswordForm.controls['code'].setValue(parsedCode.code);
          this.changePasswordForm.controls['email'].setValue(parsedCode.email);
          // console.log('change-password-form.component->ngOnInit(): this.changePasswordForm.value', this.changePasswordForm.value);
        } catch (e) {
          console.error('change-password-form.component->ngOnInit(): ERROR', e);

          if (e instanceof Error) {
            utilsFactory.noticeError(e);
          }

          this.isUrlCodeValid = false;
        }
      } else {
        this.urlCode = false;

        const storageRegisterUserData = this.loginService.storageRegisterUserData;
        // console.log('change-password-form.component->ngOnInit(): storageRegisterUserData', storageRegisterUserData);

        const storageRegisterData = this.loginService.storageRegisterData;
        // console.log('change-password-form.component->ngOnInit(): storageRegisterData', storageRegisterData);

        if (storageRegisterUserData && storageRegisterUserData.email) {
          this.changePasswordForm.controls['email'].setValue(storageRegisterUserData.email);
          // console.log('change-password-form.component->ngOnInit(): 1 this.changePasswordForm.controls.email.value', this.changePasswordForm.controls.email.value);
        } else if (storageRegisterData && storageRegisterData.email) {
          this.changePasswordForm.controls['email'].setValue(storageRegisterData.email);
          // console.log('change-password-form.component->ngOnInit(): 2 this.changePasswordForm.controls.email.value', this.changePasswordForm.controls.email.value);
        } else if (
          storageRegisterData &&
          storageRegisterData.username &&
          storageRegisterData.username.indexOf('@') > -1
        ) {
          this.changePasswordForm.controls['email'].setValue(storageRegisterData.username);
          // console.log('change-password-form.component->ngOnInit(): 3 this.changePasswordForm.controls.email.value', this.changePasswordForm.controls.email.value);
        }

        if (!this.changePasswordForm.controls['email'].value) {
          await this.routerService.navigateTo(`/forgot-password`);
        } else {
          /*setTimeout(() => {
					 this.showGoLogin = true;
					 }, 10000);*/
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        utilsFactory.noticeError(e);
      }
    }

    await this.metaTagsService.setMetaTags({
      title: `Change Password`,
    });
  }

  async onSubmit() {
    try {
      this.changePasswordForm.markAsTouched();
      this.changePasswordForm.markAllAsTouched();

      if (!this.changePasswordForm.valid) {
        // console.log('change-password-form.component->onSubmit(): this.changePasswordForm.valid', this.changePasswordForm.valid);
        return false;
      }

      const payload = this.changePasswordForm.value;
      // console.log('change-password-form.component->onSubmit(): payload', payload);

      // console.log('change-password-form.component->onSubmit(): !payload.email', !payload.email);
      // console.log('change-password-form.component->onSubmit(): !utilsFactory.isEmailValid(payload.email)', !utilsFactory.isEmailValid(payload.email));

      if (!payload.newPassword) {
        throw new Error('newPassword.required');
      }

      if (payload.newPassword.length < 8) {
        throw new Error('newPassword.length');
      }

      if (!environment.passwordPattern.test(payload.newPassword)) {
        throw new Error('newPassword.invalid-pattern');
      }

      this.errorMessage = null;

      this.changePasswordForm.disable();
      this.savingNewPassword = true;

      const userModel = await this.userService.changePassword(payload);

      this.savingNewPassword = false;
      this.savePasswordSuccess = true;

      // console.log('change-password-form.component->onSubmit(): this.userLoginData', this.userLoginData);

      this.savingNewPassword = true;

      try {
        // console.log('change-password-form.component->onSubmit(): payload', payload);

        await this.loginService.login({
          username: payload.email,
          password: payload.newPassword,
          graphDomain: 'gotchosen',
        });

        const routeData = await this.routerService.getRouteDataParam();

        setTimeout(async () => {
          if (this.goBackReferrer) {
            // console.log('change-password-form.component->onSubmit(): redirecting to', this.goBackReferrer.path);
            await this.routerService.navigateTo(this.goBackReferrer.path);
          } else {
            // console.log('change-password-form.component->onSubmit(): environment.networkUrl', `${environment.networkUrl}/@${userModel.username}`);
            // await this.routerService.navigateTo(`/@${userModel.username}`, { queryParams: { code: null } });
            document.location = `${environment.networkUrl}/@${userModel.username}}`;
          }
        }, 3000);
      } catch (e) {
        console.error('change-password-form.component->onSubmit(): ERROR', e);

        if (e['code'] === 'AUTH_UNVALIDATED_EMAIL') {
          return await this.routerService.navigateTo(`/code-verifier`, {
            queryParams: {
              resend: true,
            },
          });
        } else if (e['code'] === 'AUTH_SUSPENDED_USER') {
          this.errorSuspendedUser = true;
        } else if (e['code'] === 'AUTH_BANNED_USER') {
          this.errorBannedUser = true;
        } else if (e['code'] === 'AUTH_DELETED_USER') {
          this.errorDeletedUser = true;
        } else {
          await this.routerService.navigateTo(`/`);
        }
      }
    } catch (error) {
      console.error('change-password-form.component->onSubmit(): ERROR', error);

      this.changePasswordForm.enable();
      this.savingNewPassword = false;

      if (error['code'] === 422) {
        this.errorChangeNewPassword = true;
      } else if (error['form'] && error['form'].errors) {
        this.changePasswordForm.validate(error['form'].errors.children);
      } else {
        this.changePasswordForm.validate(error['message']);
      }
    }
  }

  async getNewCode() {
    try {
      if (this.requestNewCode || this.newCodeLoading) {
        return false;
      }

      this.newCodeLoading = true;

      const payload = this.changePasswordForm.value;
      const userEmail = payload.email.value;

      // console.log('change-password-form.component->getNewCode(): userEmail', userEmail);
      await this.userService.forgotPassword(userEmail);

      this.newCodeLoading = false;
      this.requestNewCode = true;

      setTimeout(() => {
        this.requestNewCode = false;
      }, 60000);
    } catch (e) {
      this.newCodeLoading = false;
      this.requestNewCode = false;

      throw e;
    }
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
}

import {
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'mip-badge,[mip-badge]',
  templateUrl: './mip-badge.component.html',
  styleUrls: ['./mip-badge.component.scss']
})
export class MipBadgeComponent implements OnInit {

  @Input() bgColor: string;
  @Input() bdColor: string;
  @Input() bdSize = '2px';
  @Input() bgMipColor: string;
  @Input() textMipColor: string;
  @Input() label: string;
  @Input() uppercase = true;
  @Input() textColor: string;
  @Input() rightSquare: false;
  @Input() padding: string;

  @Input() icon: string;
  @Input() iconSize: string;
  @Input() iconBold = false;
  @Input() iconSolid = false;

  @Input() fontSize = '10px';
  @Input() borderRadius = '20px';

  constructor() {
  }

  ngOnInit(): void {
  }

}

import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'mip-expansive-button',
  templateUrl: './mip-expansive-button.component.html',
  styleUrls: ['./mip-expansive-button.component.scss']
})
export class MipExpansiveButtonComponent {

  @Input() width = 35;
  @Output() onClick = new EventEmitter();

}

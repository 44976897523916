import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  transform(date: Date, shorter = false, suffix = true): string {

    if (shorter) {
      suffix = false;
    }

    return moment(date).fromNow(!suffix);

  }

}

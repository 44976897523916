<header class="header mip-color--bg-dark" [style.background-image]="loginBackgroundImage" *ngIf="disableHeader !== true">
  <a class="__go-back" mat-button [routerLink]="['/']" queryParamsHandling="merge"> Login </a>

  <div class="__inner">
    <div class="--flex-column --flex-column--center">
      <strong class="__text" mipTranslate="register-form.header.title">Sign up for</strong>
      <img class="__logo" src="{{ assetsPath }}/images/gc-logo-white.png" />

      <small style="margin-top: 20px" *ngIf="deviceUUID">deviceUUID: {{ deviceUUID }}</small>
    </div>
  </div>
</header>

<div class="content">
  <div class="__inner">
    <div class="__form-container">
      <!-- REGISTER FORM -->
      <form class="__form" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <!-- EMAIL -->
        <mat-form-field appearance="fill" color="accent">
          <mat-label mipTranslate="register-form.fields.email.label">Email</mat-label>
          <span matPrefix><mip-icon icon="mail" size="1x"></mip-icon></span>
          <input matInput type="email" tabindex="1" formControlName="email" autocomplete="email" (change)="onEmailChange()" (paste)="onEmailChange()" (blur)="onEmailChange()" />
          <mat-error>
            <span *ngIf="registerForm.get('email').hasError('required')" mipTranslate="register-form.fields.email.error-message.required"> You must provide your email. </span>
            <span *ngIf="registerForm.get('email').hasError('invalid-email')" mipTranslate="register-form.fields.email.error-message.invalid">
              The email provided is not valid.
            </span>
            <span *ngIf="registerForm.get('email').hasError('reserved-name')" mipTranslate="register-form.fields.email.error-message.reserved-name">
              Cannot contains reserved words of GotChosen and its products
            </span>
            <span *ngIf="registerForm.get('email').hasError('account-already-exists')" mipTranslate="register-form.fields.email.error-message.reserved-name">
              This email is already associated with an account.
            </span>
          </mat-error>
        </mat-form-field>

        <!-- PASSWORD -->
        <mat-form-field class="--has-hint" appearance="fill" color="accent">
          <mat-label mipTranslate="register-form.fields.password.label">Password</mat-label>
          <span matPrefix><mip-icon icon="lock" size="1x"></mip-icon></span>
          <input matInput tabindex="2" [type]="showPassword ? 'text' : 'password'" formControlName="password" autocomplete="off" />
          <a mat-button matSuffix [class.mip-color--text-primary]="showPassword" (click)="showPassword = !showPassword">
            <mip-icon icon="eye" [solid]="true" size="1x"></mip-icon>
          </a>
          <mat-hint mipTranslate="register-form.fields.password.hint-message"> Your password must have letters and numbers and be at least 8 characters length </mat-hint>

          <mat-error>
            <span *ngIf="registerForm.get('password').hasError('required')" mipTranslate="register-form.fields.password.error-message.required">
              You must provide your password
            </span>
            <span *ngIf="registerForm.get('password').hasError('length')" mipTranslate="register-form.fields.password.error-message.length">
              Your password must have at least 8 characters length
            </span>
            <span *ngIf="registerForm.get('password').hasError('invalid-pattern')" mipTranslate="register-form.fields.password.error-message.invalid-pattern">
              Your password must have letters and numbers
            </span>
          </mat-error>
        </mat-form-field>

        <!-- REMEMBER ME -->
        <div class="__checkbox">
          <mat-checkbox formControlName="rememberMe" color="accent">
            <span mipTranslate="login-form.fields.remember-me.label"> Remember me </span>
          </mat-checkbox>
        </div>

        <!-- RECAPTCHA -->
        <mip-recaptcha></mip-recaptcha>

        <!-- SUBMIT -->
        <button
          class="__submit-button g-recaptcha"
          mat-flat-button
          type="button"
          tabindex="5"
          mipBgColor="primary"
          [disabled]="loadingRegistration || noReferralCode || registerForm.disabled"
          (click)="onSubmit()"
        >
          <mip-icon icon="refresh" [spin]="true" *ngIf="loadingRegistration"></mip-icon>
          <span mipTranslate="register-form.actions.registering" *ngIf="loadingRegistration">Registering...</span>
          <span mipTranslate="register-form.actions.register" *ngIf="!loadingRegistration">Register</span>
        </button>

        <mip-alert class="__alert-message" theme="danger" *ngIf="recaptchaBadScoreError">
          <mip-icon icon="warning" [solid]="true" size="7x"></mip-icon>
          <span mipTranslate="register-form.error-message.suspicious-behavior-error">
            <strong>Suspicious behavior detected!</strong> Please try again later or send us an email to
            <a target="_blank" href="mailto:contact@gotchosen.com">contact@gotchosen.com</a>
          </span>
        </mip-alert>

        <mip-alert class="__alert-message" theme="danger" *ngIf="recaptchaErrorMessage">
          <mip-icon icon="warning" [solid]="true" size="7x"></mip-icon>
          <span>{{ recaptchaErrorMessage }}</span>
        </mip-alert>

        <mip-alert class="__alert-message" theme="danger" *ngIf="errorRegistration">
          <mip-icon icon="warning" [solid]="true"></mip-icon>
          <span mipTranslate="register-form.error-message.register-error"> There was an error while trying to register your information. </span>
        </mip-alert>

        <mip-alert class="__alert-message" theme="warning" *ngIf="errorAccountAlreadyExists">
          <mip-icon icon="warning"></mip-icon>
          <span mipTranslate="register-form.error-message.account-already-exists">
            The email provided is already registered. Try to <a routerLink="/" queryParamsHandling="merge">Login</a> instead.
          </span>
        </mip-alert>

        <mip-alert class="__alert-message" theme="danger" *ngIf="errorMessage">
          <mip-icon icon="warning"></mip-icon>
          <span>{{ errorMessage }}</span>
        </mip-alert>
      </form>

      <mip-alert class="__alert-message" theme="warning" *ngIf="noReferralCode">
        <mip-icon icon="warning" [solid]="true"></mip-icon>
        <span mipTranslate="register-form.error-message.no-referral-code"> Referral code must be provided! </span>
      </mip-alert>

      <mip-divider>
        <span mipTranslate="divider.label-or">OR</span>
      </mip-divider>

      <!-- LOGIN SOCIAL -->
      <mip-login-social *ngIf="enableSocialLogin"></mip-login-social>
    </div>

    <!-- LOGIN-ACCOUNT-ACTION -->
    <div class="__bottom-actions" *ngIf="disableLoginBtn !== true">
      <a mat-button class="__action mip-button--size-md" [routerLink]="['/']" mipTranslate="register-form.actions.have-account" mipTextColor="primary" queryParamsHandling="merge">
        Already have an account?
      </a>
    </div>
  </div>

  <!-- COPYRIGHT -->
  <mip-copyright *ngIf="disableCopyright !== true"></mip-copyright>
</div>

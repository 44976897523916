import { Injectable } from '@angular/core';
import moment from 'moment';
import { environment } from '@libs/gc-common/environments/environment';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  static instance: CacheService;

  _environmentName = environment.environmentName;
  // _appVersion = environment.appVersion;

  _resourcePrefixName = `mip-resource-${this._environmentName}`;
  _resourcePrefix = `${this._resourcePrefixName}`;

  _dataPrefixName = `mip-data-${this._environmentName}`;
  _dataPrefix = `${this._dataPrefixName}`;

  constructor() {
    CacheService.instance = this;
    this.sanitizeCacheStorage('localStorage');
    this.sanitizeCacheStorage('sessionStorage');
  }

  /**
   * Since we implemented version, we need to make sure we dont have
   * anything cached that is different from the version we are using
   */
  sanitizeCacheStorage(storageType = 'localStorage') {
    try {
      if (utilsFactory.isBrowser) {
        const storage = window[storageType];
        // console.log('cache.service->sanitizeCacheStorage(): storage', storage);

        for (const cacheKey of Object.keys(storage)) {

          if (cacheKey.indexOf(this._resourcePrefixName) === 0 || cacheKey.indexOf(this._dataPrefixName) === 0) {
            // console.log('cache.service->sanitizeCacheStorage(): item', cacheKey);

            // Check if the cache key is in different cache version, if so, remove it
            if (cacheKey.indexOf(this._resourcePrefix) !== 0 && cacheKey.indexOf(this._dataPrefix) !== 0) {
              // console.log('cache.service->sanitizeCacheStorage(): item', cacheKey);
              window[storageType].removeItem(cacheKey);
            }

            // If cache type is "data", check if it has expired. If so, remove it.
            else if (cacheKey.indexOf(this._dataPrefixName) === 0) {

              const cacheData = JSON.parse(storage[cacheKey]);
              // console.log('cache.service->sanitizeCacheStorage(): cacheData', cacheKey, cacheData);

              const hasDateExpired = utilsFactory.hasDateExpired(cacheData.expiration_date);
              // console.log('cache.service->sanitizeCacheStorage(): hasDateExpired', cacheKey, hasDateExpired);

              if (hasDateExpired) {
                this.removeCache(cacheData.resource, cacheData.key, storageType === 'sessionStorage');
              }

            }
          }
        }
      }
    }
    catch (e) {
      console.error('cache.service->sanitizeCacheStorage(): ERROR', e);
    }
  }

  getResourceKeyName(resource: string) {
    try {
      if (resource.indexOf(this._resourcePrefix) > -1) {
        return resource;
      }
      else {
        return [this._resourcePrefix, resource].join('-');
      }
    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to register a ney key into some resource
   */
  createResource(resource: string) {

    // console.log('cache.service->setResource(): resource', resource);

    try {

      if (utilsFactory.isBrowser) {

        const resourceKey = this.getResourceKeyName(resource); // [this._resourcePrefix, resource].join('-');
        const allResourceKey = [this._resourcePrefix, 'all'].join('-');
        // console.log('cache.service->registerResourceKey(): resourceKey', resourceKey);

        const resourceCacheData = window.localStorage.getItem(resourceKey);
        const allResourceCacheData = window.localStorage.getItem(allResourceKey);
        const allResourceData = allResourceCacheData ? JSON.parse(allResourceCacheData) : {};

        if (!resourceCacheData) {
          window.localStorage.setItem(resourceKey, JSON.stringify(resourceCacheData || {}));
        }

        allResourceData[resourceKey] = true;
        window.localStorage.setItem(allResourceKey, JSON.stringify(allResourceData));

        return resourceKey;

      }

      return null;

    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to register a ney key into some resource
   */
  removeAllResources() {

    // console.log('cache.service->removeAllResources()');

    try {

      if (utilsFactory.isBrowser) {

        const resourcesKeys = this.getAllResource();

        // console.log('cache.service->removeAllResources(): resourcesKeys', resourcesKeys);

        // tslint:disable-next-line:forin
        for (const resourceKey in resourcesKeys) {
          this.removeResourceKeys(resourceKey);
        }

        const allResourceKey = [this._resourcePrefix, 'all'].join('-');
        window.localStorage.removeItem(allResourceKey);

      }

    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to register a ney key into some resource
   */
  getAllResource() {

    // console.log('cache.service->getAllResource()');

    try {
      if (utilsFactory.isBrowser) {

        const allResourceKey = [this._resourcePrefix, 'all'].join('-');
        // console.log('cache.service->getAllResource(): resourceKey', allResourceKey);

        const cacheData = window.localStorage.getItem(allResourceKey);
        return cacheData ? JSON.parse(cacheData) : {};

      }
    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to remove a resource
   */
  removeResourceKeys(resource: string) {

    // console.log('cache.service->removeResource(): resource', resource);

    try {

      if (utilsFactory.isBrowser) {

        const resourceKeys = this.getResourceKeys(resource);
        // console.log('cache.service->removeResource(): resourceKeys.length', Object.keys(resourceKeys).length);

        // tslint:disable-next-line:forin
        for (const key in resourceKeys) {
          window.localStorage.removeItem(key);
        }

        const resourceKey = this.getResourceKeyName(resource); // [this._resourcePrefix, resource].join('-');
        // console.log('cache.service->removeResource(): resourceKey', resourceKey);
        window.localStorage.removeItem(resourceKey);

        const allResourcesKeys = this.getAllResource();
        // console.log('cache.service->removeResource(): allResourcesKeys', allResourcesKeys);
        delete allResourcesKeys[resourceKey];
        window.localStorage.setItem([this._resourcePrefix, 'all'].join('-'), JSON.stringify(allResourcesKeys));

      }
    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to remove a key from a resource
   */
  removeResourceKey(resource: string, key: string) {

    // console.log('cache.service->removeResourceKey(): resource', resource, key);

    try {
      if (utilsFactory.isBrowser) {

        const resourceKey = this.createResource(resource);
        // console.log('cache.service->removeResourceKey(): resourceKey', resourceKey);

        const cacheData = window.localStorage.getItem(resourceKey);
        // console.log('cache.service->removeResourceKey(): cacheData', cacheData);

        const resourceData = cacheData ? JSON.parse(cacheData) : {};
        // console.log('cache.service->removeResourceKey(): resourceData', resourceData);

        delete resourceData[key];

        window.localStorage.setItem(resourceKey, JSON.stringify(resourceData));

      }
    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to register a ney key into some resource
   */
  setResourceKey(resource: string, key: string, sessionStorage = false) {

    // console.log('cache.service->registerResourceKey(): resource', resource, key);

    try {
      if (utilsFactory.isBrowser) {

        const resourceKey = this.createResource(resource);
        // console.log('cache.service->registerResourceKey(): resourceKey', resourceKey);

        const cacheData = window.localStorage.getItem(resourceKey);
        const resourceData = { ...(cacheData ? JSON.parse(cacheData) : {}), sessionStorage };
        // console.log('cache.service->registerResourceKey(): resourceData', resourceData);

        resourceData[key] = moment().add(1, 'day').toISOString(); // new Date().toJSON();

        window.localStorage.setItem(resourceKey, JSON.stringify(resourceData));

      }
    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to register a ney key into some resource
   */
  getResourceKeys(resource: string): object {

    // console.log('cache.service->getResourceKeys(): resource', resource);

    try {

      if (utilsFactory.isBrowser) {

        const resourceKey = this.createResource(resource);
        // console.log('cache.service->getResourceKeys(): resourceKey', resourceKey);

        const cacheData = window.localStorage.getItem(resourceKey);
        return cacheData ? JSON.parse(cacheData) : {};

      }

      return {};

    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to clear the data removing all underscore keys=values
   */
  normalizeData(data) {
    try {

      let normalizedData/* = Array.isArray(data) ? [] : {}*/;

      if (Array.isArray(data)) {
        normalizedData = [];
      }
      else {
        normalizedData = {
          _cache_created_at: Date.now()
        };
      }

      const recursive = (currentData, currentContainer) => {
        // console.log('cache.service->normalizeData(): currentData', currentData);

        // tslint:disable-next-line:forin
        for (const i in currentData) {
          // console.log('cache.service->normalizeData(): i', i);

          /*if (Array.isArray(data)) {
           for (const item of data) {
           item['_cache_created_at'] = Date.now();
           }
           }
           else if (typeof data === 'object') {
           data['_cache_created_at'] = Date.now();
           }*/

          if ((i === '_pagination' || i[0] !== '_') && typeof currentData[i] !== 'function' && currentData[i] !== null) {
            // console.log('cache.service->normalizeData(): IF i', i);

            if (Array.isArray(currentData[i])) {
              currentContainer[i] = [];
              recursive(currentData[i], currentContainer[i]);
            }
            else if (currentData[i] && typeof currentData[i] === 'object') {
              currentContainer[i] = {};
              currentContainer[i]['_cache_created_at'] = Date.now();
              recursive(currentData[i], currentContainer[i]);
            }
            else {
              currentContainer[i] = currentData[i];
            }
          }
        }
      };

      recursive(data, normalizedData);

      // console.log('cache.service->normalizeData(): data', data);
      // console.log('cache.service->normalizeData(): normalizedData', normalizedData);
      // console.log('cache.service->normalizeData(): -----------------------------');

      return normalizedData;
    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to clear the data removing all underscore keys=values
   */
  normalizeKey(key) {
    try {
      // console.log('cache.service->normalizeKey(): key', key);

      const newKey = utilsFactory.btoa(key);
      let final = '';
      let apiUrl = null;

      for (const i in environment.apis) {
        if (key.indexOf(environment.apis[i]) > -1) {
          apiUrl = environment.apis[i];
          break;
        }
      }

      if (apiUrl) {
        final = newKey.slice(apiUrl.length);
      }
      else {
        final = newKey;
      }

      // console.log('cache.service->normalizeKey(): apiUrl', apiUrl, key, final);

      return final;

    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to get a data from localStorage
   */
  getCache(resource: string, key: string, sessionStorage = false): any {

    // console.log('cache.service->getCache(): resource', resource, key);

    try {

      if (utilsFactory.isBrowser) {

        if (!key) {
          throw new Error('The key must be provided.');
        }

        if (typeof key !== 'string') {
          throw new Error('The key must be a string.');
        }

        const cacheKey = [this._dataPrefix, resource, this.normalizeKey(key)].join('-'); // [this._dataPrefix, resource, btoa(key)].join('-');
        // console.log('cache.service->getCache(): cacheKey', cacheKey);

        let cacheData;

        if (sessionStorage) {
          // console.log('cache.service->getCache(): sessionStorage', sessionStorage);
          cacheData = window.sessionStorage.getItem(cacheKey);
        }
        else {
          cacheData = window.localStorage.getItem(cacheKey);
        }
        // console.log('cache.service->getCache(): cacheData', cacheData);

        const cacheParsedData = JSON.parse(cacheData);
        // console.log('cache.service->getCache(): cacheParsedData', cacheParsedData);

        // console.log('cache.service->getCache(): hasDateExpired', utilsFactory.hasDateExpired(cacheParsedData.expiration_date));

        if (cacheParsedData) {
          if (utilsFactory.hasDateExpired(cacheParsedData.expiration_date) === false) {
            // console.log('cache.service->getCache(): hasDateExpired', utilsFactory.hasDateExpired(cacheParsedData.expiration_date));
            return cacheParsedData.data;
          }
          else {
            this.removeCache(resource, key, sessionStorage);
            throw new Error(`Cache for ${cacheKey} has expired`);
          }
        }
        else {
          throw new Error(`There is no cache for ${cacheKey}`);
        }

      }
      else {
        throw new Error('Cache not available for SSR');
      }

    }
    catch (e) {
      // console.error(e);
      return null;
    }
  }

  /**
   * Method to get a data from sessionStorage
   * Alias for getCache()
   */
  getSession(resource: string, key: string): any {

    // console.log('cache.service->getSession(): resource', resource, key);

    try {
      const args = [...arguments, true];
      // @ts-ignore
      return this.getCache.apply(this, args);
    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to put a data to localStorage
   */
  setCache(resource: string, key: string, data: string | object | Array<object>, expiration?: { minutes?: number, hours?: number, days?: number }, sessionStorage = false) {

    // console.log('cache.service->setCache(): resource', {resource, key, data, expiration, sessionStorage});
    // console.log('cache.service->setCache() ------------------------------------------');

    try {

      if (utilsFactory.isBrowser) {

        if (!resource) {
          throw new Error(`The 'resource' must be provided.`);
        }

        if (typeof resource !== 'string') {
          throw new Error(`The 'resource' must be a string.`);
        }

        if (!key) {
          throw new Error(`The 'key' must be provided.`);
        }

        if (typeof key !== 'string') {
          throw new Error(`The 'key' must be a string.`);
        }

        if (!data) {
          throw new Error(`The 'data' must be provided.`);
        }

        if (!data) {
          throw new Error(`The 'data' must be an STRING, OBJECT or an ARRAY.`);
        }

        let createdAt = moment().add(5, 'minutes').toJSON();

        if (typeof expiration === 'object') {
          createdAt = moment().add(expiration).toJSON();
        }

        // console.log('cache.service->setCache(): createdAt', createdAt);

        const cacheData = {
          expiration_date: createdAt,
          data: typeof data === 'string' ? data : this.normalizeData(data),
          key,
          resource
        };
        // console.log('cache.service->setCache(): cacheData', cacheData);

        const cacheKey = [this._dataPrefix, resource, this.normalizeKey(key)].join('-'); // [this._dataPrefix, resource, btoa(key)].join('-');
        // console.log('cache.service->setCache(): cacheKey', cacheKey);

        this.setResourceKey(resource, cacheKey);

        try {
          if (sessionStorage) {
            // console.log('cache.service->setCache(): sessionStorage', sessionStorage);
            window.sessionStorage.setItem(cacheKey, JSON.stringify(cacheData));
          }
          else {
            window.localStorage.setItem(cacheKey, JSON.stringify(cacheData));
          }
        }
        catch (e) {
          // console.log('cache.service->setCache(): ERROR', e.message);
          this.clearCache(resource);
        }

      }

    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to put a data to localStorage
   * Alias for setCache()
   */
  setSession(resource: string, key: string, data: string | object | Array<object>, expiration?: { minutes?: number, hours?: number, days?: number }) {

    // console.log('cache.service->setSession(): resource', resource, key, data);

    try {

      const args = [
        resource,
        key,
        data,
        expiration,
        true
      ];

      // console.log('cache.service->setSession(): args', args);
      // @ts-ignore
      this.setCache.apply(this, args);
    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to remove a cache from localStorage
   */
  removeCache(resource: string, key: string, sessionStorage = false) {
    // console.log('cache.service->removeCache(): resource', { resource, key, sessionStorage });

    try {

      if (utilsFactory.isBrowser) {

        if (!key) {
          throw new Error('The key must be provided.');
        }

        if (typeof key !== 'string') {
          throw new Error('The key must be a string.');
        }

        const cacheKey = [this._dataPrefix, resource, this.normalizeKey(key)].join('-');
        // console.log('cache.service->removeCache(): cacheKey', cacheKey);

        this.removeResourceKey(resource, cacheKey);

        if (sessionStorage) {
          // console.log('cache.service->removeCache(): sessionStorage', sessionStorage);
          window.sessionStorage.removeItem(cacheKey);
        }
        else {
          window.localStorage.removeItem(cacheKey);
        }

      }
    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to remove a cache from localStorage
   * Alias for removeCache()
   */
  removeSession(resource: string, key: string) {

    // console.log('cache.service->removeSession(): resource', resource, key);

    try {
      const args = [...arguments, true];
      // @ts-ignore
      this.removeCache.apply(this, args);
    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to remove all section cache
   */
  clearCache(resource?: string) {

    // console.log('cache.service->clearCache(): resource', resource);

    try {
      if (utilsFactory.isBrowser) {

        if (!resource) {
          throw new Error(`You must provide the resource label or 'all' (to clear the cache)`);
        }

        if (resource === 'all') {
          this.removeAllResources();
        }
        else {
          this.removeResourceKeys(resource);
        }

      }
    }
    catch (e) {
      throw e;
    }
  }

}

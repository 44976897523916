import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';
import { MipLoadingModule } from '@libs/gc-common/lib/components/mip-loading/mip-loading.module';
import { ColorModule } from '@libs/gc-common/lib/directives/color/color.module';
import { MipTranslateModule } from '@libs/gc-common/lib/directives/translate/mip-translate.module';
import { PostViewModule } from '@libs/gc-common/lib/features/post-view/post-view.module';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'swiper/angular';

import { SlidePostsPreviewDetailsComponent } from './slide-posts-preview-details.component';

@NgModule({
  declarations: [SlidePostsPreviewDetailsComponent],
  imports: [
    CommonModule,
    SwiperModule,
    MipTranslateModule,
    PostViewModule,
    MatButtonModule,
    ColorModule,
    MipIconModule,
    MipLoadingModule,
    TranslateModule,
    RouterModule
  ],
  exports: [SlidePostsPreviewDetailsComponent]
})
export class SlidePostsPreviewDetailsModule {
}

import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';

import TagCloud from './tag-cloud.lib';

@Component({
  selector: 'mip-tag-cloud',
  templateUrl: './mip-tag-cloud.component.html',
  styleUrls: ['./mip-tag-cloud.component.scss']
})
export class MipTagCloudComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @ViewChild('tagCloud') tagCloudEl: ElementRef;

  @Input() itemsCount = 0;
  @Input() items?: Array<{ text?: string, class?: string }> = [];

  @Input() config: {
    speed?: string,
    shuffleList?: boolean
  } = {};

  lastItemsCount = 0;

  tagCloudConfig = {
    items: [],
    speed: 'slow',
    shuffleList: true
  };

  tagCloudElement = null;
  tagCloudInstance = null;

  tagCloudElementInterval = null;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {

    console.log('mip-tag-cloud.component->ngAfterViewInit(): this.items.length', this.items.length);

    this.tagCloudElement = this.tagCloudEl.nativeElement;

    if (this.config) {
      this.tagCloudConfig = { ...this.tagCloudConfig, ...this.config };
    }

    console.log('mip-tag-cloud.component->constructor(): this.config', this.config);
    console.log('mip-tag-cloud.component->constructor(): this.tagCloudConfig', this.tagCloudConfig);

  }

  ngOnChanges(changes) {

    console.log('mip-tag-cloud.component->ngOnChanges(): this.items', this.items);

    if (utilsFactory.isBrowser /*&& this.lastItemsCount !== this.itemsCount*/) {

      this.lastItemsCount = this.itemsCount;

      if (this.tagCloudElementInterval) {
        clearInterval(this.tagCloudElementInterval);
      }

      this.tagCloudElementInterval = setInterval(() => {

        if (this.tagCloudElement) {
          console.log('mip-tag-cloud.component->ngOnChanges(): this.tagCloudInstance', this.tagCloudInstance);
          clearInterval(this.tagCloudElementInterval);

          const items = this.tagCloudConfig.shuffleList ? this.shuffleList(this.items) : this.items;

          if (this.tagCloudInstance) {
            console.log('mip-tag-cloud.component->ngOnChanges(): this.tagCloudInstance: UPDATING', this.tagCloudInstance);
            this.tagCloudInstance.updateContent(items);
          }
          else {
            console.log('mip-tag-cloud.component->ngOnChanges(): this.tagCloudInstance: CREATING');
            this.tagCloudInstance = new TagCloud(this.tagCloudElement, items, {
              radius: Math.floor(this.tagCloudElement.offsetWidth / 2),
              speed: this.tagCloudConfig.speed
            });
          }

        }

      }, 10);
    }
  }

  ngOnDestroy() {
    if (this.tagCloudInstance) {
      this.tagCloudInstance.destroy();
    }
  }

  shuffleList(array) {

    let currentIndex = array.length;
    let randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]
      ];
    }

    return array;

  }

  changeSpeed(speed) {
    if (this.tagCloudElement) {
      this.tagCloudInstance.changeSpeed(speed);
    }
  }

  pause() {
    this.tagCloudInstance.pause();
  }

}

<section
  class="__section"
  [class.--arrow-top]="arrowTop"
>

  <div class="__arrow --top" *ngIf="arrowTop" [class]="'--' + arrowTopPosition">
    <div class="__arrow-item" [mipBgColor]="bgColor"></div>
  </div>

  <div
    class="__inner"
    [mipBgColor]="bgColor"
    [mipTextColor]="textColor"
    [class]="['__image-' + imagePosition, '__video-' + videoPosition]"
    [style.background-image]="'url(' + bgImage + ')'"
    [style.background-position]="'center ' + bgImagePosition"
    [style.background-size]="bgImageSize"
  >

    <ng-content
      select="mip-section-content"
    ></ng-content>

    <ng-content
      select="mip-section-image"
    ></ng-content>

    <ng-content
      select="mip-section-yt-video"
    ></ng-content>

  </div>

  <div class="__arrow" [class]="'--' + arrowPosition" [class.--hide]="hideArrow">
    <div class="__arrow-item" [mipBgColor]="bgColor"></div>
  </div>

</section>
